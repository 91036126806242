import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PrimaryPicks_ds_primary_picks_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouse_id?: number, location_id?: number, material_id?: number, is_primary_pick?: boolean[], fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LocationId?: number, MaterialId?: number, OverAllocate?: boolean, PackagingId?: number, Location?: { Id?: number, IsPrimaryPick?: boolean, Name?: string, ScannableLookup?: string, Warehouse?: { Id?: number, Name?: string } }, Material?: { Id?: number, LookupCode?: string, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/PrimaryPicks/datasources/ds_primary_picks_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouse_id?: number, location_id?: number, material_id?: number, is_primary_pick?: boolean[], fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LocationId?: number, MaterialId?: number, OverAllocate?: boolean, PackagingId?: number, Location?: { Id?: number, IsPrimaryPick?: boolean, Name?: string, ScannableLookup?: string, Warehouse?: { Id?: number, Name?: string } }, Material?: { Id?: number, LookupCode?: string, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/PrimaryPicks/datasources/ds_primary_picks_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouse_id?: number, location_id?: number, material_id?: number, is_primary_pick?: boolean[], fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LocationId?: number, MaterialId?: number, OverAllocate?: boolean, PackagingId?: number, Location?: { Id?: number, IsPrimaryPick?: boolean, Name?: string, ScannableLookup?: string, Warehouse?: { Id?: number, Name?: string } }, Material?: { Id?: number, LookupCode?: string, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PrimaryPicks/datasources/ds_primary_picks_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouse_id?: number, location_id?: number, material_id?: number, is_primary_pick?: boolean[], fullTextSearch?: string    }) {
      return false;
    }
}

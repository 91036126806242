<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
              </div>

        <div class="widgets">
          <div *ngIf="!widgets.purchase.hidden && !widgets.purchase.removed" class="widget-container">
            <Dynamics365BusinessCentral-inbound_order_today
            #$widgets_purchase 
            ($refreshEvent)="refresh(false, false, '$widgets_purchase')"
            >
            </Dynamics365BusinessCentral-inbound_order_today>
          </div>
          <div *ngIf="!widgets.sells.hidden && !widgets.sells.removed" class="widget-container">
            <Dynamics365BusinessCentral-outbound_orders_today
            #$widgets_sells 
            ($refreshEvent)="refresh(false, false, '$widgets_sells')"
            >
            </Dynamics365BusinessCentral-outbound_orders_today>
          </div>
          <div *ngIf="!widgets.material.hidden && !widgets.material.removed" class="widget-container">
            <Dynamics365BusinessCentral-materials_today
            #$widgets_material 
            ($refreshEvent)="refresh(false, false, '$widgets_material')"
            >
            </Dynamics365BusinessCentral-materials_today>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.orders.hasTabToShow" class="tab" data-cy="tab-orders">
              <h2 [className]="tabs.orders.active? 'active': ''" (click)="tabs.orders.activate()">{{tabs.orders.title}}</h2>
            </div>
            <div *ngIf="tabs.materials.hasTabToShow" class="tab" data-cy="tab-materials">
              <h2 [className]="tabs.materials.active? 'active': ''" (click)="tabs.materials.activate()">{{tabs.materials.title}}</h2>
            </div>
          </div>
        
              <Dynamics365BusinessCentral-order_po_queue_grid *ngIf="tabs.orders.active"
              #$tabs_orders
              ($refreshEvent)="refresh(false, false, '$tabs_orders')">
              </Dynamics365BusinessCentral-order_po_queue_grid>
              <Dynamics365BusinessCentral-material_grid *ngIf="tabs.materials.active"
              #$tabs_materials
              ($refreshEvent)="refresh(false, false, '$tabs_materials')">
              </Dynamics365BusinessCentral-material_grid>
        </div>
      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>
import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';


@Injectable({ providedIn: 'root' })
export class InventoryTransformations_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public InventoryTransformations: InventoryTransformations_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
}


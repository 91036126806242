import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { PrimaryPicks_ds_materials_ddService } from './PrimaryPicks.datasource.index';
import { PrimaryPicks_ds_primary_picks_gridService } from './PrimaryPicks.datasource.index';

@Injectable({ providedIn: 'root' })
export class PrimaryPicks_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public PrimaryPicks: PrimaryPicks_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_materials_dd: PrimaryPicks_ds_materials_ddService;
  public get ds_materials_dd(): PrimaryPicks_ds_materials_ddService {
    if(!this._ds_materials_dd) {
      this._ds_materials_dd = this.injector.get(PrimaryPicks_ds_materials_ddService);
    }
    return this._ds_materials_dd;
  }
  private _ds_primary_picks_grid: PrimaryPicks_ds_primary_picks_gridService;
  public get ds_primary_picks_grid(): PrimaryPicks_ds_primary_picks_gridService {
    if(!this._ds_primary_picks_grid) {
      this._ds_primary_picks_grid = this.injector.get(PrimaryPicks_ds_primary_picks_gridService);
    }
    return this._ds_primary_picks_grid;
  }
}


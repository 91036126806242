import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_inventory_tasks_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lot_id?: number, material_ids?: number[], licenseplate_id?: number, location_id?: number, project_id?: number, owner_id?: number, warehouse_ids?: number[], full_text_search?: string, material_id?: number, serial_number_id?: number, ids?: number[], status_ids?: number[], operation_code_ids?: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { Id?: number, LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_inventory_tasks_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { lot_id?: number, material_ids?: number[], licenseplate_id?: number, location_id?: number, project_id?: number, owner_id?: number, warehouse_ids?: number[], full_text_search?: string, material_id?: number, serial_number_id?: number, ids?: number[], status_ids?: number[], operation_code_ids?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { Id?: number, LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_inventory_tasks_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { lot_id?: number, material_ids?: number[], licenseplate_id?: number, location_id?: number, project_id?: number, owner_id?: number, warehouse_ids?: number[], full_text_search?: string, material_id?: number, serial_number_id?: number, ids?: number[], status_ids?: number[], operation_code_ids?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, ModifiedSysUser?: string, Notes?: string, SerialNumberId?: number, ActualPackagedPack?: { ShortName?: string }, ExpectedPackagedPack?: { ShortName?: string }, Lot?: { Id?: number, LookupCode?: string, Material?: { ProjectId?: number, Project?: { Id?: number, LookupCode?: string } } }, Material?: { Id?: number, LookupCode?: string, ProjectId?: number, Project?: { Id?: number, LookupCode?: string } }, Project?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, Status?: { Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualSourceLocation?: { Id?: number, Name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedTargetLicensePlate?: { Id?: number, LookupCode?: string }, ExpectedTargetLocation?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string }, SerialNumber?: { Id?: number, LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_inventory_tasks_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      lot_id?: number, material_ids?: number[], licenseplate_id?: number, location_id?: number, project_id?: number, owner_id?: number, warehouse_ids?: number[], full_text_search?: string, material_id?: number, serial_number_id?: number, ids?: number[], status_ids?: number[], operation_code_ids?: number[]    }) {
      return false;
    }
}

import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manifesting_ShellService } from './Manifesting.shell.service';
import { Manifesting_OperationService } from './Manifesting.operation.service';
import { Manifesting_DatasourceService } from './Manifesting.datasource.index';
import { Manifesting_FlowService } from './Manifesting.flow.index';
import { Manifesting_ReportService } from './Manifesting.report.index';
import { Manifesting_LocalizationService } from './Manifesting.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manifesting.frontend.types'
import { $frontendTypes as $types} from './Manifesting.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { PrintNode_printers_dd_singleComponent } from './PrintNode.printers_dd_single.component'
import { Manifesting_label_size_dd_singleComponent } from './Manifesting.label_size_dd_single.component'

type EntityType = { 
    Id?: number, ContainerMaterial?: string, ContainerTypeId?: number, Height?: number, Length?: number, LookupCode?: string, NmfcNumber?: string, NmfcSubNumber?: string, ShipmentId?: number, ShippingCost?: number, TrackingNumber?: string, Weight?: number, WeightUomId?: number, Width?: number, ContainerType?: { Id?: number, Description?: string }, Shipment?: { Id?: number, AccountId?: number, CarrierId?: number, CarrierServiceTypeId?: number, ContactId?: number, ExpectedWarehouseId?: number, LookupCode?: string, ExpectedWarehouse?: { Name?: string }, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, Status?: { Name?: string }, OrderLookups?: { OrderId?: number, Order?: { BillingAddresId?: number, IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourcePlatform?: string, LookupCode?: string, OwnerReference?: string, ProjectId?: number, VendorReference?: string, Project?: { LookupCode?: string, OwnerId?: number, Owner?: { LookupCode?: string } }, Addresses?: { Id?: number, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] } }[] }, warehouseAddress?: { WarehouseId?: number, ContactId?: number, Warehouse?: { Id?: number, Name?: string, Notes?: string }, Contact?: { FirstName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, ReferenceCode?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[], carrierAccountNumber?: { OwnerId?: number, CarrierId?: number, AccountNumber?: string }[], orderAccountAddress?: { Id?: number, AddressId?: number, FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } }[]}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => PrintNode_printers_dd_singleComponent),
    forwardRef(() => Manifesting_label_size_dd_singleComponent),
  ],
  selector: 'Manifesting-easypost_manifesting_shipping_container_editor',
  templateUrl: './Manifesting.easypost_manifesting_shipping_container_editor.component.html'
})
export class Manifesting_easypost_manifesting_shipping_container_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { shipping_container_id: number, shipping_container_type_name?: string, shipping_container_weight?: number, printer_id?: number, printerIdPackingList?: number, target_location_id: number } = { shipping_container_id: null, shipping_container_type_name: null, shipping_container_weight: null, printer_id: null, printerIdPackingList: null, target_location_id: null };
  //#region Inputs
  @Input('shipping_container_id') set $inParams_shipping_container_id(v: number) {
    this.inParams.shipping_container_id = v;
  }
  get $inParams_shipping_container_id(): number {
    return this.inParams.shipping_container_id;
  }
  @Input('shipping_container_type_name') set $inParams_shipping_container_type_name(v: string) {
    this.inParams.shipping_container_type_name = v;
  }
  get $inParams_shipping_container_type_name(): string {
    return this.inParams.shipping_container_type_name;
  }
  @Input('shipping_container_weight') set $inParams_shipping_container_weight(v: number) {
    this.inParams.shipping_container_weight = v;
  }
  get $inParams_shipping_container_weight(): number {
    return this.inParams.shipping_container_weight;
  }
  @Input('printer_id') set $inParams_printer_id(v: number) {
    this.inParams.printer_id = v;
  }
  get $inParams_printer_id(): number {
    return this.inParams.printer_id;
  }
  @Input('printerIdPackingList') set $inParams_printerIdPackingList(v: number) {
    this.inParams.printerIdPackingList = v;
  }
  get $inParams_printerIdPackingList(): number {
    return this.inParams.printerIdPackingList;
  }
  @Input('target_location_id') set $inParams_target_location_id(v: number) {
    this.inParams.target_location_id = v;
  }
  get $inParams_target_location_id(): number {
    return this.inParams.target_location_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { out_shippingLabel?: string, manifested_flag: boolean } = { out_shippingLabel: null, manifested_flag: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shippingcontainerid?: number, lookupcode?: string, length?: number, height?: number, width?: number, shipmentId?: number, shipmentlookupcode?: string, carrier?: string, carrierservice?: string, carrierid?: number, labelSize?: number, contentsExplanation?: string, weight?: number, container_type?: string, printerId?: number } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    shippingcontainercarrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shippingcontainercarrierservice: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shippingcontainer_dimensions: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shippingcontainerweight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    container_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shippingcontainer_shipping_address: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    printer_packinglist: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    label_size: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shippingcontainer_tracking: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      manifest_ready_shippingcontainer: new ToolModel(new ButtonModel('manifest_ready_shippingcontainer', new ButtonStyles(null, null), false, false, 'Manifest', 'ms-Icon ms-Icon--AutomateFlow', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      manifeststatus: new ToolModel(new ButtonModel('manifeststatus', new ButtonStyles(null, null), false, false, 'Manifest status', '', null)
    , false)
  };

  fields = {
    shippingcontainercarrier: new FieldModel(new TextBoxModel(this.formGroup.controls['shippingcontainercarrier'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Carrier Name', false, false),
    shippingcontainercarrierservice: new FieldModel(new TextBoxModel(this.formGroup.controls['shippingcontainercarrierservice'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Carrier Service', false, false),
    shippingcontainer_dimensions: new FieldModel(new TextBoxModel(this.formGroup.controls['shippingcontainer_dimensions'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Dimensions', false, false),
    shippingcontainerweight: new FieldModel(new NumberBoxModel(this.formGroup.controls['shippingcontainerweight'] as DatexFormControl, null, true, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Weight', false, false),
    container_type: new FieldModel(new TextBoxModel(this.formGroup.controls['container_type'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Container type', false, false),
    shippingcontainer_shipping_address: new FieldModel(new TextBoxModel(this.formGroup.controls['shippingcontainer_shipping_address'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'To', false, false),
    printer_packinglist: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['printer_packinglist'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Printer for packing', false, false),
    label_size: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['label_size'] as DatexFormControl, 
  null, null,
  true, 
  '', null)
, new ControlContainerStyles(null, null), 'Label size', false, false),
    shippingcontainer_tracking: new FieldModel(new TextBoxModel(this.formGroup.controls['shippingcontainer_tracking'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Tracking Number', false, false),
  };

  fieldsets = {
  shippingcontainerinfo: new FieldsetModel('Shipping Container Info', false, false, true, false),
  address_info: new FieldsetModel('Address Information', false, false, true, false),
  printing: new FieldsetModel('Printing', false, false, true, false),
  results: new FieldsetModel('Results', false, false, true, false),
};


  //#region fields inParams
  get $fields_printer_packinglist_selector_inParams_letterOnly(): boolean {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = true;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    shippingcontainercarrier: this.fields.shippingcontainercarrier.control.valueChanges
    ,
    shippingcontainercarrierservice: this.fields.shippingcontainercarrierservice.control.valueChanges
    ,
    shippingcontainer_dimensions: this.fields.shippingcontainer_dimensions.control.valueChanges
    ,
    shippingcontainerweight: this.fields.shippingcontainerweight.control.valueChanges
    ,
    container_type: this.fields.container_type.control.valueChanges
    ,
    shippingcontainer_shipping_address: this.fields.shippingcontainer_shipping_address.control.valueChanges
    ,
    printer_packinglist: this.fields.printer_packinglist.control.valueChanges
    ,
    label_size: this.fields.label_size.control.valueChanges
    ,
    shippingcontainer_tracking: this.fields.shippingcontainer_tracking.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Manifesting_ShellService,
    private datasources: Manifesting_DatasourceService,
    private flows: Manifesting_FlowService,
    private reports: Manifesting_ReportService,
    private localization: Manifesting_LocalizationService,
    private operations: Manifesting_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shipping_container_id)) {
        this.$missingRequiredInParams.push('shipping_container_id');
      }
      if(isNil(this.inParams.target_location_id)) {
        this.$missingRequiredInParams.push('target_location_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Easypost shipping container';
    

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      shipping_container_id:  $editor.inParams.shipping_container_id 
    };

    const data = await this.datasources.Manifesting.ds_manifesting_shipping_container_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    
    
    
    
    (this.fields.shippingcontainer_shipping_address.control as TextBoxModel).reset(($utils.isAllDefined($editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.Id,        $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.Line1))        ?        $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.Line1 + ', ' +        $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.City + ', ' +        $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.State + ' ' +        $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.PostalCode + ' ' +        $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.Country        :        (            ($utils.isAllDefined($editor.entity.orderAccountAddress[0]?.Id,                $editor.entity.orderAccountAddress[0]?.Address?.Line1))                ?                $editor.entity.orderAccountAddress[0]?.Address?.Line1 + ', ' +                $editor.entity.orderAccountAddress[0]?.Address?.City + ', ' +                $editor.entity.orderAccountAddress[0]?.Address?.State + ' ' +                $editor.entity.orderAccountAddress[0]?.Address?.PostalCode + ' ' +                $editor.entity.orderAccountAddress[0]?.Address?.Country                :                (                    '-'                )        ));
    

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .shippingcontainercarrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_data_loaded();
      });
    this.$formGroupFieldValidationObservables
      .shippingcontainercarrierservice
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_data_loaded();
      });
    this.$formGroupFieldValidationObservables
      .shippingcontainer_dimensions
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_data_loaded();
      });
    this.$formGroupFieldValidationObservables
      .shippingcontainerweight
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_data_loaded();
      });
    this.$formGroupFieldValidationObservables
      .container_type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_data_loaded();
      });
    this.$formGroupFieldValidationObservables
      .shippingcontainer_shipping_address
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_data_loaded();
      });
    this.$formGroupFieldValidationObservables
      .printer_packinglist
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
    this.$formGroupFieldValidationObservables
      .label_size
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_data_loaded();
      });
    this.$formGroupFieldValidationObservables
      .shippingcontainer_tracking
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_data_loaded();
      });
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: Manifesting_easypost_manifesting_shipping_container_editorComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
    this.logger.log('Manifesting', 'easypost_manifesting_shipping_container_editor.on_data_loaded');
  
  $editor.title = `Manifesting shipping container`
  
  if (!$utils.isDefinedTrimmed($editor.entity?.TrackingNumber)) {
      $editor.toolbar.manifeststatus.control.label ='Starting to Manifest Shipping Container...';
      
      await $editor.on_manifest_ready_shipping_container_button_clicked();
      
  }
  else {
      $editor.toolbar.manifest_ready_shippingcontainer.control.label = 'Re-manifest';
      $editor.toolbar.manifest_ready_shippingcontainer.control.readOnly = false;
  }
  
  }
  on_filter_selection(event = null) {
    return this.on_filter_selectionInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_filter_selectionInternal(
    $editor: Manifesting_easypost_manifesting_shipping_container_editorComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
    this.logger.log('Manifesting', 'easypost_manifesting_shipping_container_editor.on_filter_selection');
  if ($utils.isDefined($editor.fields.label_size.control.value)) {
      $editor.vars.labelSize = $editor.fields.label_size.control.value;
  }
  else {
      $editor.vars.labelSize = null;
  }
  
  // if ($utils.isDefined($editor.fields.contents_explanation.control.value)) {
  //     $editor.vars.contentsExplanation = $editor.fields.contents_explanation.control.value;
  // }
  // else {
  //     $editor.vars.contentsExplanation = null;
  // }
  
  
  // if ($utils.isDefined($editor.fields.shippingcontainerlength.control.value)) {
  //     $editor.vars.length = $editor.fields.shippingcontainerlength.control.value;
  // }
  // else {
  //     $editor.vars.length = null;
  // }
  
  // if ($utils.isDefined($editor.fields.shippingcontainerwidth.control.value)) {
  //     $editor.vars.width = $editor.fields.shippingcontainerwidth.control.value;
  // }
  // else {
  //     $editor.vars.width = null;
  // }
  
  
  // if ($utils.isDefined($editor.fields.shippingcontainerheight.control.value)) {
  //     $editor.vars.height = $editor.fields.shippingcontainerheight.control.value;
  // }
  // else {
  //     $editor.vars.height = null;
  // }
  
  
  if ($utils.isDefined($editor.fields.shippingcontainerweight.control.value)) {
      $editor.vars.weight = $editor.fields.shippingcontainerweight.control.value;
  }
  else {
      $editor.vars.weight = null;
  }
  
  
  }
  on_manifest_ready_shipping_container_button_clicked(event = null) {
    return this.on_manifest_ready_shipping_container_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manifest_ready_shipping_container_button_clickedInternal(
    $editor: Manifesting_easypost_manifesting_shipping_container_editorComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
    this.logger.log('Manifesting', 'easypost_manifesting_shipping_container_editor.on_manifest_ready_shipping_container_button_clicked');
  
   if($utils.isDefinedTrimmed($editor.entity?.Shipment?.Carrier?.Name))
   {
      $editor.fields.shippingcontainercarrier.control.value = $editor.entity?.Shipment?.Carrier?.Name;
      
   }else{
      $editor.fields.shippingcontainercarrier.control.value ='';
   }
  
   //
  
  if($utils.isDefinedTrimmed($editor.entity?.Shipment?.CarrierServiceType?.Name))
   {
     $editor.fields.shippingcontainercarrierservice.control.value = $editor.entity?.Shipment?.CarrierServiceType?.Name;
      
   }else{
      $editor.fields.shippingcontainercarrierservice.control.value ='';
   }
  
  //
  
  if (!$utils.isDefinedTrimmed($editor.inParams?.shipping_container_type_name)) {
      $editor.vars.container_type = $editor.inParams?.shipping_container_type_name;
  }
  else {
      $editor.vars.container_type = $utils.isDefinedTrimmed($editor.entity?.ContainerType?.Description) ? $editor.entity?.ContainerType?.Description : 'ContType1';
  }
  $editor.fields.container_type.control.value = $editor.vars.container_type;
  
  
  //shippingcontainerweight
  if ($utils.isDefined($editor.inParams?.shipping_container_weight)) {
      $editor.fields.shippingcontainerweight.control.value = $editor.inParams?.shipping_container_weight;
      $editor.vars.weight = $editor.inParams?.shipping_container_weight;
  }
  else {
      $editor.fields.shippingcontainerweight.control.value = 1.0;
      $editor.vars.weight = 1.0;
  }
  
  
  $editor.fields.shippingcontainer_dimensions.control.value = $editor.vars.length.toString + ' x ' + $editor.vars.width.toString + ' x ' + $editor.vars.height.toString;
  //$editor.fields.shippingcontainer_dimensions.control.value =  '1 x 2 x 3' ;
  
  $editor.vars.contentsExplanation = (await $flows.Manifesting.get_label_contents_explanation({ shipping_container_id: $editor.vars.shippingcontainerid }))?.label_contents_explanation;
  
  if ($utils.isDefined($editor.entity.Shipment)) {
  
      // DHL default to 4x7
      if ($editor.entity.Shipment.Carrier?.Name === 'DHL') {
          $editor.fields.label_size.control.value = 2
      }
      // Else default to 4x6
      else {
          $editor.fields.label_size.control.value = 1
      }
  };
  
  if ($utils.isDefined($editor.fields.label_size.control.value)) {
      $editor.vars.labelSize = $editor.fields.label_size.control.value;
  }
  else {
      $editor.vars.labelSize = null;
  };
  
  
  //To Address
  $editor.fields.shippingcontainer_shipping_address.control.value = (
      ($utils.isAllDefined($editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.Id,
          $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.Line1))
          ?
          $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.Line1 + ', ' +
          $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.City + ', ' +
          $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.State + ' ' +
          $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.PostalCode + ' ' +
          $editor.entity.Shipment.OrderLookups[0]?.Order?.Addresses[0]?.Country
          :
          (
              ($utils.isAllDefined($editor.entity.orderAccountAddress[0]?.Id,
                  $editor.entity.orderAccountAddress[0]?.Address?.Line1))
                  ?
                  $editor.entity.orderAccountAddress[0]?.Address?.Line1 + ', ' +
                  $editor.entity.orderAccountAddress[0]?.Address?.City + ', ' +
                  $editor.entity.orderAccountAddress[0]?.Address?.State + ' ' +
                  $editor.entity.orderAccountAddress[0]?.Address?.PostalCode + ' ' +
                  $editor.entity.orderAccountAddress[0]?.Address?.Country
                  :
                  (
                      '-ToAddr'
                  )
          )
  );
  
  $editor.fields.printer_packinglist.control.value = $editor.inParams.printerIdPackingList ;
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  var printerId =0;
  if (!$utils.isDefined($editor.inParams.printer_id)){
      
      const location_attachedPrinter = (await $flows.PrintNode.get_printers_by_location_flow({location_id: $editor.inParams.target_location_id})).printers?.label_printer.id ;
      if (!$utils.isDefined(location_attachedPrinter)){
          printerId = location_attachedPrinter;
      }
  }
  else{
      printerId = $editor.inParams.printer_id;
  }
  
  
  var utilizeShipmentContainer = true;
  var containerCount = 1;
  
  var lengthInput = $editor.entity.Length?.toString();
  var widthInput =  $editor.entity.Width?.toString();
  var heightInput = $editor.entity.Height?.toString();
  var weightInput =  $editor.entity.Weight?.toString();
  var printerIdPackinglist = $editor.fields.printer_packinglist.control.value;
  // To do: change to passing string from dropdown
  var labelSize = $editor.vars.labelSize === 1 ? '4x6' : $editor.vars.labelSize === 2 ? '4x7' : '';
  var labelFormat = 'PDF'
  var contentsExplanation = $editor.vars.contentsExplanation?.toString();
  var shippingContainer_candidate =  $editor.entity.LookupCode;
  
  const errors = [];
  
  //
  console.log(shippingContainer_candidate + '-'+$editor.entity.ShipmentId + '-'+$editor.entity.Id);
  //
  
  if ($utils.isAllDefined(lengthInput, widthInput, heightInput, weightInput ) === false || labelSize==='') {
      $editor.outParams.manifested_flag = false;
      $editor.toolbar.manifeststatus.control.label = 'Pre-Manifesting errors: no Dimensions or LabelSize provided'
      const title = 'Manifesting errors';
      const errorMessage = `Shipping Container ${$editor.entity.LookupCode} cannot be manifested`;
      const errorList = errors;
      await $shell.Manifesting.openErrorDialog(title, errorMessage, errorList);
      return;
      
  }else{
      //continue
      const processSuccess = [];
      const processFailures = [];
      const errorMsgList = [];
      const errorMsgListDetails = [];
  
      var manifestCount = 0;
      try {
  
          var orderLookup = $editor.entity.Shipment.OrderLookups[0]?.Order?.LookupCode;
          manifestCount = manifestCount + 1;
      //    $grid.topToolbar.manifestcount.control.label = `Manifesting order ${orderLookup}  ${manifestCount} of ${totalManifestCount} `
          $editor.outParams.manifested_flag = false;
          $editor.toolbar.manifeststatus.control.label = 'Starting to Manifest';
  
          // Gather Shipment Data
          const shipmentId = $editor.entity.ShipmentId;
          const shippingContainerId = $editor.entity.Id;
  
          var printCustomOne = ''
          var projectLookupcode = $editor.entity.Shipment.OrderLookups[0]?.Order?.Project.LookupCode.substring(0, 3);
          // Gather Print Custom One Data
          if ($utils.isDefined($editor.entity.Shipment.OrderLookups[0]?.Order?.OwnerReference)) {
              printCustomOne = projectLookupcode + '-' + $editor.entity.Shipment.OrderLookups[0]?.Order?.OwnerReference;
          }
          else {
              printCustomOne = projectLookupcode;
  
          }
  
  
          const shipment = (await $datasources.Manifesting.ds_get_shipment_details_for_manifesting_by_shipmentId.get({ shipmentId: shipmentId })).result[0];
          if ($utils.isDefined(shipment)) {
              //var carrierId =  216; //shipment?.CarrierId;  //203
              var carrierId =  shipment?.CarrierId;  
              //var carrierServiceName = 'EconomySelect'; //shipment?.CarrierServiceType?.Name;
              var carrierServiceName =  $utils.isDefined(shipment.CarrierServiceType?.Name) ? shipment.CarrierServiceType?.Name : 'Ground';
              var warehouseId = shipment.ExpectedWarehouseId;
              var orderId = shipment.OrderLookups[0]?.OrderId;
              //var ownerId = 52059; 
              var ownerId = shipment.OrderLookups[0]?.Order?.Project?.OwnerId;
  
              var shipToAddress = (await $flows.Manifesting.get_ship_to_address_flow({ orderId: orderId, shipmentId: shipment.Id })).address;
  
              var buyerAddress = (await $flows.Manifesting.get_buyer_address({ orderId: orderId, shipmentId: shipment.Id })).address;
          }
  
          var owner = $editor.entity.Shipment.OrderLookups[0]?.Order?.Project?.Owner?.LookupCode;
  
          if ($utils.isAllDefined(ownerId, carrierId)) {
              const carrierAccount = (await $datasources.Manifesting.ds_get_ownerscarrierslookup_by_ownerId_carrierId.get({ ownerId: ownerId, carrierId: carrierId })).result;
              if ($utils.isDefined(carrierAccount)) {
                  var accountNumber = carrierAccount[0].AccountNumber;
                  var referenceNumber =  $utils.isDefined(carrierAccount[0]?.ReferenceNumber) ? carrierAccount[0]?.ReferenceNumber : '';
              }
          }
  
                 // var accountNumber = 'ca_7bf55396459b4c62b3d52e77faa4179c' ;//carrierAccount[0].AccountNumber;
                  //var referenceNumber = ''; //carrierAccount[0].ReferenceNumber;
          const shipFromAddress = (await $flows.Manifesting.get_ship_from_address_flow({ orderId: orderId, shipmentId: shipment.Id })).address;
  
          let saturdayDelivery = (await $flows.EasyPost.get_saturday_delivery_flag({
                      orderId: $editor.entity.Shipment.OrderLookups[0]?.OrderId
                  }))?.saturdayDeliveryFlag;
  
          let returnLabel = (await $flows.EasyPost.get_return_label_easypost({ orderId: $editor.entity.Shipment.OrderLookups[0]?.OrderId }))?.returnLabel_flag;
  
          var parcel: any[] = [];
          if (utilizeShipmentContainer === true) {
  
              //const shippingcontainers = (await $datasources.Manifesting.ds_get_shippingcontainers_by_shipmentId.get({ shipmentId: shipmentId })).result;
              //if ($utils.isDefined(shippingcontainers)) {
  
                  var containerLength = $utils.isDefined(lengthInput) ? lengthInput?.toString() : '1';
                  var containerWidth = $utils.isDefined(widthInput) ? widthInput?.toString() : '1';
                  var containerHeight = $utils.isDefined(heightInput ) ? heightInput?.toString() : '1';
                  var containerWeight = $utils.isDefined(weightInput) ? weightInput?.toString() : '1';
  
                  parcel = [{
                      "length": containerLength,
                      "width": containerWidth,
                      "height": containerHeight,
                      "weight": containerWeight
                  }]
              //}
          }
          else {
  
              for (let i = 0; i < containerCount; i++) {
                  parcel.push(
                      {
                          "length": lengthInput,
                          "width": widthInput,
                          "height": heightInput,
                          "weight": weightInput
                      }
                  )
  
              }
  
          }
          // Define custom items
          let customsItemsContent = await $flows.EasyPost.get_items_content({
  
                      shipmentId: shipmentId
  
                  });
                  const customsItems = customsItemsContent.content;
  
                  // Get Third Party Address
                  let thirdPartyAddress = (await $flows.Manifesting.get_third_party_address({
                      orderId: $editor.entity.Shipment.OrderLookups[0]?.OrderId,
                      shipmentId: shipmentId
                  }))?.address;
  
  
                  console.log(parcel);
  
                  $editor.outParams.manifested_flag = false;
                  $editor.toolbar.manifeststatus.control.label = 'Calling EasiPost API...';
  
                  // Change third-party address property names to EasyPost names
                  if ($utils.isDefined(thirdPartyAddress)) {
                      delete Object.assign(thirdPartyAddress, { street1: thirdPartyAddress.line1 }).line1;
                      delete Object.assign(thirdPartyAddress, { street2: thirdPartyAddress.line2 }).line2;
                      delete Object.assign(thirdPartyAddress, { zip: thirdPartyAddress.postalCode }).postalCode;
                  }
  
                  // EasyPost Manifest                    
                  let taxIdentifiers = [];
                  if ($utils.isDefined(thirdPartyAddress?.vat)) {
                      taxIdentifiers.push({
                          "entity": "SENDER",
                          "tax_id": thirdPartyAddress.vat,
                          "tax_id_type": "VAT",
                          "issuing_country": thirdPartyAddress.country
                      });
                  }
  
                  if ($utils.isDefined(thirdPartyAddress?.eori)) {
                      taxIdentifiers.push({
                          "entity": "SENDER",
                          "tax_id": thirdPartyAddress.eori,
                          "tax_id_type": "EORI",
                          "issuing_country": thirdPartyAddress.country
                      });
                  }
  
                  const result = await $flows.EasyPost.one_call_buy_shipment_request_flow({
                      carrierAccount: accountNumber,
                      referenceNumber: referenceNumber,
                      service: carrierServiceName,
                      parcels: parcel,
                      fromAddress: {
                          "name": shipFromAddress.name,
                          "street1": shipFromAddress.line1,
                          "street2": shipFromAddress.line2,
                          "city": shipFromAddress.city,
                          "state": shipFromAddress.state,
                          "zip": shipFromAddress.postalCode,
                          "country": shipFromAddress.country,
                          "phone": shipFromAddress.phone,
                          "email": shipFromAddress.email,
                          "federal_tax_id": shipFromAddress.vat
                      },
                      toAddress: {
                          "name": shipToAddress.name,
                          "street1": shipToAddress.line1,
                          "street2": shipToAddress.line2,
                          "city": shipToAddress.city,
                          "state": shipToAddress.state,
                          "zip": shipToAddress.postalCode,
                          "country": shipToAddress.country,
                          "phone": shipToAddress.phone,
                          "email": shipToAddress.email,
                          "federal_tax_id": shipToAddress.vat
                      },
                      buyerAddress: {
                          "name": buyerAddress.name,
                          "street1": buyerAddress.line1,
                          "street2": buyerAddress.line2,
                          "city": buyerAddress.city,
                          "state": buyerAddress.state,
                          "zip": buyerAddress.postalCode,
                          "country": buyerAddress.country,
                          "phone": buyerAddress.phone,
                          "email": buyerAddress.email,
                          "federal_tax_id": buyerAddress.vat
                      },
                      thirdPartyAddress: thirdPartyAddress,
                      labelSize: labelSize,
                      labelFormat: labelFormat,
                      printCustomOne: printCustomOne,
                      contentsExplanation: contentsExplanation,
                      customsItems: customsItems,
                      incoterm: shipment.Incoterms,
                      taxIdentifiers: taxIdentifiers,
                      saturdayDelivery: saturdayDelivery,
                      returnLabel: returnLabel
                  });
  
  
                  // Update tracking data on shipment
                  const tracking = result.easyPostTrackingCode;
                  //result.easyPostShipmentId
                  const selectedRateAmt = result.easyPostSelectedRateDeliveryDate;
  
                  // Get postage label data
                  if ($utils.isDefined(result.easyPostPostageLabelUrl)) {
                      var labelUrl = result.easyPostPostageLabelUrl;
                  }
  
                  // Get commerical invoice data
                  if ($utils.isDefined(result.easyPostCommercialInvoiceUrl)) {
                      var invoiceUrl = result.easyPostCommercialInvoiceUrl;
                  }
  
                  let payloadShipment: any = {};
                  let payloadShippingContainer: any = {};
  
  
                  if ($utils.isDefined(tracking)) {
                      //payloadShipment.TrackingIdentifier = tracking.toString();
                      //Update just Shipping container
                      payloadShippingContainer.TrackingNumber = tracking.toString();
                  }
                  // Only save the first label. We should store all labels in a better place...ToDo
                  if ($utils.isDefined(labelUrl)) {
                      payloadShippingContainer.NmfcNumber = labelUrl[0].toString();
                  }
                  if ($utils.isDefined(invoiceUrl)) {
                      payloadShippingContainer.NmfcSubNumber = invoiceUrl.toString();
                  }
                  if ($utils.isDefined(selectedRateAmt)){
                      payloadShippingContainer.ShippingCost = Number(selectedRateAmt);
                  }
  
                  $editor.outParams.manifested_flag = true;
                  $editor.toolbar.manifeststatus.control.label = 'Manifested successfully, updating Tracking Number...';
                 // await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payloadShipment });
                  await $flows.Utilities.crud_update_flow({ entitySet: 'ShippingContainers', id: shippingContainerId, entity: payloadShippingContainer });
  
                  //update Tracking Number on the GUI Editor
                  $editor.fields.shippingcontainer_tracking.control.value = payloadShippingContainer.TrackingNumber;
  
                  console.log(payloadShippingContainer.TrackingNumber);
                  //
  
                  // Printing
  
                  var labels: string[] = labelUrl;
  
                  //Return label as string
                  if ($utils.isAllDefined(labels, printerId)) {
                      $editor.outParams.out_shippingLabel = labelUrl[0].toString();
                  }
                  // PrintNode Printing
                  if ($utils.isAllDefined(labels, printerId)) {
  
                      //$grid.topToolbar.manifestcount.control.label = `Printing label using PrintNode for ${orderLookup}  ${manifestCount} of ${totalManifestCount} `
                      
                      $editor.toolbar.manifeststatus.control.label = 'Printing label using PrintNode...';
  
                      for (let label of labels) {
  
                          const flowPrintParams = {
                              content: label.valueOf(),
                              printerId: printerId
                          };
  
                          const print = await $flows.PrintNode.print_request_flow(flowPrintParams);
                          const printJobId = print.printJobId;
  
                          let payloadOrder: any = {};
                          if ($utils.isDefined(printJobId)) {
                              payloadOrder.IntegrationHubSourcePlatform = printJobId.toString();
                          }
                          await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: orderId, entity: payloadOrder });
  
                      }
  
                  }
  
                  // // Packing List
                  if ($utils.isDefined(printerIdPackinglist)) {
                      // Get Packing List content
                      let packinglistContent = await $flows.Manifesting.get_packinglist_content({
                          fileType: "pdf",
                          dataType: "base64",
                          parameters: {
                              orderId: $editor.entity.Shipment.OrderLookups[0]?.OrderId
                          }
                      });
  
                      if (!$utils.isDefined(packinglistContent?.error)) {
                          // Print 
                         // $grid.topToolbar.manifestcount.control.label = `Printing packing list using PrintNode for ${orderLookup}  ${manifestCount} of ${totalManifestCount} `
                          $editor.toolbar.manifeststatus.control.label = 'Printing packing list using PrintNode...';
                          const flowPrintParams = {
                              contentType: "pdf_base64",
                              content: packinglistContent.contentString,
                              printerId: printerIdPackinglist
                          }
  
                          const print = await $flows.PrintNode.print_request_flow(flowPrintParams);
                      }
                      else {
                          console.log(`Failed to print packing list: ${packinglistContent.error}`);
                      }
                  }
  
  
                  processSuccess.push(shippingContainer_candidate);
  
              } catch (error) {
                  
                  processFailures.push(shippingContainer_candidate);
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Shipping Container ${shippingContainer_candidate} - ${errorMessage}`;
                  errorMsgList.push(errorDescription);
                  errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
  
                  //
                  $editor.outParams.manifested_flag = false;
                  $editor.toolbar.manifeststatus.control.label = 'Manifest failed: '+ errorDetail;
                  
                  console.log(shippingContainer_candidate + '--catch error' + errorDetail);
                  //
              }
  }
  
  
  }
  on_label_clicked(event = null) {
    return this.on_label_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_label_clickedInternal(
    $editor: Manifesting_easypost_manifesting_shipping_container_editorComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
    this.logger.log('Manifesting', 'easypost_manifesting_shipping_container_editor.on_label_clicked');
  
  var labelUrl;
  
  const shipping_container_aux = (await $datasources.Manifesting.ds_manifesting_shipping_container_editor.get({shipping_container_id: $editor.inParams.shipping_container_id})).result;
  
  //ds_get_shipment_by_shipmentId.get({ shipmentId: $hub.inParams.shipmentIds[0] })).result
  if (shipping_container_aux?.NmfcNumber ===''){
      labelUrl ='';
  }
  else{
      labelUrl = shipping_container_aux?.NmfcNumber;
  }
  
  if ($utils.isDefined(labelUrl)){
  
      $shell.Manifesting.openshipping_label_contentDialog({url: labelUrl},'flyout')
  }
  }
  //#endregion private flows
  
}

import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { DockAppointments_ds_areas_ddService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointment_activity_listService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointment_editorService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointment_itemsService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointment_statuses_ddService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointment_type_by_typeIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointment_types_ddService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointment_types_gridService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointments_calendarService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointments_dashboardService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointments_dashboard_gridService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointments_gridService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_appointments_selection_gridService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_door_editorService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_doors_by_warehouseIds_listService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dock_doors_ddService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_dockdoors_calendarService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_find_dockappointmentitemsService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_convert_dates_to_warehouse_timezonesService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_current_date_from_utcService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_current_dock_appointments_by_lookupcodeService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_appointmentIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_loadContainerIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_loadContainerId_appointmentIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_orderIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_orderId_appointmentIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_orderId_top1Service } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_orderIdsService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_shipmentIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_shipmentId_appointmentIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_by_shipmentIdsService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_history_by_appointmentIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointment_type_by_nameService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_appointments_by_idsService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_door_by_locationIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_dock_doors_by_warehouse_idService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_locationcontainer_by_locationIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_locations_by_name_and_warehouseIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_order_by_dockAppointmentIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_order_by_orderIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_project_by_projectIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_upcoming_dock_appointments_by_dock_doorService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_warehouse_by_warehouseIdService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_warehouse_parent_zoneService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_get_warehouses_by_warehouseIdsService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_order_total_pickedService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_order_total_receivedService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_orders_ddService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_reasoncodes_ddService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_total_appt_checked_in_by_date_widgetService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_total_appt_not_checked_in_by_date_widgetService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_unassigned_orders_ddService } from './DockAppointments.datasource.index';
import { DockAppointments_ds_unscheduled_dock_appointments_calendarService } from './DockAppointments.datasource.index';

@Injectable({ providedIn: 'root' })
export class DockAppointments_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public DockAppointments: DockAppointments_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_areas_dd: DockAppointments_ds_areas_ddService;
  public get ds_areas_dd(): DockAppointments_ds_areas_ddService {
    if(!this._ds_areas_dd) {
      this._ds_areas_dd = this.injector.get(DockAppointments_ds_areas_ddService);
    }
    return this._ds_areas_dd;
  }
  private _ds_dock_appointment_activity_list: DockAppointments_ds_dock_appointment_activity_listService;
  public get ds_dock_appointment_activity_list(): DockAppointments_ds_dock_appointment_activity_listService {
    if(!this._ds_dock_appointment_activity_list) {
      this._ds_dock_appointment_activity_list = this.injector.get(DockAppointments_ds_dock_appointment_activity_listService);
    }
    return this._ds_dock_appointment_activity_list;
  }
  private _ds_dock_appointment_editor: DockAppointments_ds_dock_appointment_editorService;
  public get ds_dock_appointment_editor(): DockAppointments_ds_dock_appointment_editorService {
    if(!this._ds_dock_appointment_editor) {
      this._ds_dock_appointment_editor = this.injector.get(DockAppointments_ds_dock_appointment_editorService);
    }
    return this._ds_dock_appointment_editor;
  }
  private _ds_dock_appointment_items: DockAppointments_ds_dock_appointment_itemsService;
  public get ds_dock_appointment_items(): DockAppointments_ds_dock_appointment_itemsService {
    if(!this._ds_dock_appointment_items) {
      this._ds_dock_appointment_items = this.injector.get(DockAppointments_ds_dock_appointment_itemsService);
    }
    return this._ds_dock_appointment_items;
  }
  private _ds_dock_appointment_statuses_dd: DockAppointments_ds_dock_appointment_statuses_ddService;
  public get ds_dock_appointment_statuses_dd(): DockAppointments_ds_dock_appointment_statuses_ddService {
    if(!this._ds_dock_appointment_statuses_dd) {
      this._ds_dock_appointment_statuses_dd = this.injector.get(DockAppointments_ds_dock_appointment_statuses_ddService);
    }
    return this._ds_dock_appointment_statuses_dd;
  }
  private _ds_dock_appointment_type_by_typeId: DockAppointments_ds_dock_appointment_type_by_typeIdService;
  public get ds_dock_appointment_type_by_typeId(): DockAppointments_ds_dock_appointment_type_by_typeIdService {
    if(!this._ds_dock_appointment_type_by_typeId) {
      this._ds_dock_appointment_type_by_typeId = this.injector.get(DockAppointments_ds_dock_appointment_type_by_typeIdService);
    }
    return this._ds_dock_appointment_type_by_typeId;
  }
  private _ds_dock_appointment_types_dd: DockAppointments_ds_dock_appointment_types_ddService;
  public get ds_dock_appointment_types_dd(): DockAppointments_ds_dock_appointment_types_ddService {
    if(!this._ds_dock_appointment_types_dd) {
      this._ds_dock_appointment_types_dd = this.injector.get(DockAppointments_ds_dock_appointment_types_ddService);
    }
    return this._ds_dock_appointment_types_dd;
  }
  private _ds_dock_appointment_types_grid: DockAppointments_ds_dock_appointment_types_gridService;
  public get ds_dock_appointment_types_grid(): DockAppointments_ds_dock_appointment_types_gridService {
    if(!this._ds_dock_appointment_types_grid) {
      this._ds_dock_appointment_types_grid = this.injector.get(DockAppointments_ds_dock_appointment_types_gridService);
    }
    return this._ds_dock_appointment_types_grid;
  }
  private _ds_dock_appointments_calendar: DockAppointments_ds_dock_appointments_calendarService;
  public get ds_dock_appointments_calendar(): DockAppointments_ds_dock_appointments_calendarService {
    if(!this._ds_dock_appointments_calendar) {
      this._ds_dock_appointments_calendar = this.injector.get(DockAppointments_ds_dock_appointments_calendarService);
    }
    return this._ds_dock_appointments_calendar;
  }
  private _ds_dock_appointments_dashboard: DockAppointments_ds_dock_appointments_dashboardService;
  public get ds_dock_appointments_dashboard(): DockAppointments_ds_dock_appointments_dashboardService {
    if(!this._ds_dock_appointments_dashboard) {
      this._ds_dock_appointments_dashboard = this.injector.get(DockAppointments_ds_dock_appointments_dashboardService);
    }
    return this._ds_dock_appointments_dashboard;
  }
  private _ds_dock_appointments_dashboard_grid: DockAppointments_ds_dock_appointments_dashboard_gridService;
  public get ds_dock_appointments_dashboard_grid(): DockAppointments_ds_dock_appointments_dashboard_gridService {
    if(!this._ds_dock_appointments_dashboard_grid) {
      this._ds_dock_appointments_dashboard_grid = this.injector.get(DockAppointments_ds_dock_appointments_dashboard_gridService);
    }
    return this._ds_dock_appointments_dashboard_grid;
  }
  private _ds_dock_appointments_grid: DockAppointments_ds_dock_appointments_gridService;
  public get ds_dock_appointments_grid(): DockAppointments_ds_dock_appointments_gridService {
    if(!this._ds_dock_appointments_grid) {
      this._ds_dock_appointments_grid = this.injector.get(DockAppointments_ds_dock_appointments_gridService);
    }
    return this._ds_dock_appointments_grid;
  }
  private _ds_dock_appointments_selection_grid: DockAppointments_ds_dock_appointments_selection_gridService;
  public get ds_dock_appointments_selection_grid(): DockAppointments_ds_dock_appointments_selection_gridService {
    if(!this._ds_dock_appointments_selection_grid) {
      this._ds_dock_appointments_selection_grid = this.injector.get(DockAppointments_ds_dock_appointments_selection_gridService);
    }
    return this._ds_dock_appointments_selection_grid;
  }
  private _ds_dock_door_editor: DockAppointments_ds_dock_door_editorService;
  public get ds_dock_door_editor(): DockAppointments_ds_dock_door_editorService {
    if(!this._ds_dock_door_editor) {
      this._ds_dock_door_editor = this.injector.get(DockAppointments_ds_dock_door_editorService);
    }
    return this._ds_dock_door_editor;
  }
  private _ds_dock_doors_by_warehouseIds_list: DockAppointments_ds_dock_doors_by_warehouseIds_listService;
  public get ds_dock_doors_by_warehouseIds_list(): DockAppointments_ds_dock_doors_by_warehouseIds_listService {
    if(!this._ds_dock_doors_by_warehouseIds_list) {
      this._ds_dock_doors_by_warehouseIds_list = this.injector.get(DockAppointments_ds_dock_doors_by_warehouseIds_listService);
    }
    return this._ds_dock_doors_by_warehouseIds_list;
  }
  private _ds_dock_doors_dd: DockAppointments_ds_dock_doors_ddService;
  public get ds_dock_doors_dd(): DockAppointments_ds_dock_doors_ddService {
    if(!this._ds_dock_doors_dd) {
      this._ds_dock_doors_dd = this.injector.get(DockAppointments_ds_dock_doors_ddService);
    }
    return this._ds_dock_doors_dd;
  }
  private _ds_dockdoors_calendar: DockAppointments_ds_dockdoors_calendarService;
  public get ds_dockdoors_calendar(): DockAppointments_ds_dockdoors_calendarService {
    if(!this._ds_dockdoors_calendar) {
      this._ds_dockdoors_calendar = this.injector.get(DockAppointments_ds_dockdoors_calendarService);
    }
    return this._ds_dockdoors_calendar;
  }
  private _ds_find_dockappointmentitems: DockAppointments_ds_find_dockappointmentitemsService;
  public get ds_find_dockappointmentitems(): DockAppointments_ds_find_dockappointmentitemsService {
    if(!this._ds_find_dockappointmentitems) {
      this._ds_find_dockappointmentitems = this.injector.get(DockAppointments_ds_find_dockappointmentitemsService);
    }
    return this._ds_find_dockappointmentitems;
  }
  private _ds_get_convert_dates_to_warehouse_timezones: DockAppointments_ds_get_convert_dates_to_warehouse_timezonesService;
  public get ds_get_convert_dates_to_warehouse_timezones(): DockAppointments_ds_get_convert_dates_to_warehouse_timezonesService {
    if(!this._ds_get_convert_dates_to_warehouse_timezones) {
      this._ds_get_convert_dates_to_warehouse_timezones = this.injector.get(DockAppointments_ds_get_convert_dates_to_warehouse_timezonesService);
    }
    return this._ds_get_convert_dates_to_warehouse_timezones;
  }
  private _ds_get_current_date_from_utc: DockAppointments_ds_get_current_date_from_utcService;
  public get ds_get_current_date_from_utc(): DockAppointments_ds_get_current_date_from_utcService {
    if(!this._ds_get_current_date_from_utc) {
      this._ds_get_current_date_from_utc = this.injector.get(DockAppointments_ds_get_current_date_from_utcService);
    }
    return this._ds_get_current_date_from_utc;
  }
  private _ds_get_current_dock_appointments_by_lookupcode: DockAppointments_ds_get_current_dock_appointments_by_lookupcodeService;
  public get ds_get_current_dock_appointments_by_lookupcode(): DockAppointments_ds_get_current_dock_appointments_by_lookupcodeService {
    if(!this._ds_get_current_dock_appointments_by_lookupcode) {
      this._ds_get_current_dock_appointments_by_lookupcode = this.injector.get(DockAppointments_ds_get_current_dock_appointments_by_lookupcodeService);
    }
    return this._ds_get_current_dock_appointments_by_lookupcode;
  }
  private _ds_get_dock_appointment_by_appointmentId: DockAppointments_ds_get_dock_appointment_by_appointmentIdService;
  public get ds_get_dock_appointment_by_appointmentId(): DockAppointments_ds_get_dock_appointment_by_appointmentIdService {
    if(!this._ds_get_dock_appointment_by_appointmentId) {
      this._ds_get_dock_appointment_by_appointmentId = this.injector.get(DockAppointments_ds_get_dock_appointment_by_appointmentIdService);
    }
    return this._ds_get_dock_appointment_by_appointmentId;
  }
  private _ds_get_dock_appointment_by_loadContainerId: DockAppointments_ds_get_dock_appointment_by_loadContainerIdService;
  public get ds_get_dock_appointment_by_loadContainerId(): DockAppointments_ds_get_dock_appointment_by_loadContainerIdService {
    if(!this._ds_get_dock_appointment_by_loadContainerId) {
      this._ds_get_dock_appointment_by_loadContainerId = this.injector.get(DockAppointments_ds_get_dock_appointment_by_loadContainerIdService);
    }
    return this._ds_get_dock_appointment_by_loadContainerId;
  }
  private _ds_get_dock_appointment_by_loadContainerId_appointmentId: DockAppointments_ds_get_dock_appointment_by_loadContainerId_appointmentIdService;
  public get ds_get_dock_appointment_by_loadContainerId_appointmentId(): DockAppointments_ds_get_dock_appointment_by_loadContainerId_appointmentIdService {
    if(!this._ds_get_dock_appointment_by_loadContainerId_appointmentId) {
      this._ds_get_dock_appointment_by_loadContainerId_appointmentId = this.injector.get(DockAppointments_ds_get_dock_appointment_by_loadContainerId_appointmentIdService);
    }
    return this._ds_get_dock_appointment_by_loadContainerId_appointmentId;
  }
  private _ds_get_dock_appointment_by_orderId: DockAppointments_ds_get_dock_appointment_by_orderIdService;
  public get ds_get_dock_appointment_by_orderId(): DockAppointments_ds_get_dock_appointment_by_orderIdService {
    if(!this._ds_get_dock_appointment_by_orderId) {
      this._ds_get_dock_appointment_by_orderId = this.injector.get(DockAppointments_ds_get_dock_appointment_by_orderIdService);
    }
    return this._ds_get_dock_appointment_by_orderId;
  }
  private _ds_get_dock_appointment_by_orderId_appointmentId: DockAppointments_ds_get_dock_appointment_by_orderId_appointmentIdService;
  public get ds_get_dock_appointment_by_orderId_appointmentId(): DockAppointments_ds_get_dock_appointment_by_orderId_appointmentIdService {
    if(!this._ds_get_dock_appointment_by_orderId_appointmentId) {
      this._ds_get_dock_appointment_by_orderId_appointmentId = this.injector.get(DockAppointments_ds_get_dock_appointment_by_orderId_appointmentIdService);
    }
    return this._ds_get_dock_appointment_by_orderId_appointmentId;
  }
  private _ds_get_dock_appointment_by_orderId_top1: DockAppointments_ds_get_dock_appointment_by_orderId_top1Service;
  public get ds_get_dock_appointment_by_orderId_top1(): DockAppointments_ds_get_dock_appointment_by_orderId_top1Service {
    if(!this._ds_get_dock_appointment_by_orderId_top1) {
      this._ds_get_dock_appointment_by_orderId_top1 = this.injector.get(DockAppointments_ds_get_dock_appointment_by_orderId_top1Service);
    }
    return this._ds_get_dock_appointment_by_orderId_top1;
  }
  private _ds_get_dock_appointment_by_orderIds: DockAppointments_ds_get_dock_appointment_by_orderIdsService;
  public get ds_get_dock_appointment_by_orderIds(): DockAppointments_ds_get_dock_appointment_by_orderIdsService {
    if(!this._ds_get_dock_appointment_by_orderIds) {
      this._ds_get_dock_appointment_by_orderIds = this.injector.get(DockAppointments_ds_get_dock_appointment_by_orderIdsService);
    }
    return this._ds_get_dock_appointment_by_orderIds;
  }
  private _ds_get_dock_appointment_by_shipmentId: DockAppointments_ds_get_dock_appointment_by_shipmentIdService;
  public get ds_get_dock_appointment_by_shipmentId(): DockAppointments_ds_get_dock_appointment_by_shipmentIdService {
    if(!this._ds_get_dock_appointment_by_shipmentId) {
      this._ds_get_dock_appointment_by_shipmentId = this.injector.get(DockAppointments_ds_get_dock_appointment_by_shipmentIdService);
    }
    return this._ds_get_dock_appointment_by_shipmentId;
  }
  private _ds_get_dock_appointment_by_shipmentId_appointmentId: DockAppointments_ds_get_dock_appointment_by_shipmentId_appointmentIdService;
  public get ds_get_dock_appointment_by_shipmentId_appointmentId(): DockAppointments_ds_get_dock_appointment_by_shipmentId_appointmentIdService {
    if(!this._ds_get_dock_appointment_by_shipmentId_appointmentId) {
      this._ds_get_dock_appointment_by_shipmentId_appointmentId = this.injector.get(DockAppointments_ds_get_dock_appointment_by_shipmentId_appointmentIdService);
    }
    return this._ds_get_dock_appointment_by_shipmentId_appointmentId;
  }
  private _ds_get_dock_appointment_by_shipmentIds: DockAppointments_ds_get_dock_appointment_by_shipmentIdsService;
  public get ds_get_dock_appointment_by_shipmentIds(): DockAppointments_ds_get_dock_appointment_by_shipmentIdsService {
    if(!this._ds_get_dock_appointment_by_shipmentIds) {
      this._ds_get_dock_appointment_by_shipmentIds = this.injector.get(DockAppointments_ds_get_dock_appointment_by_shipmentIdsService);
    }
    return this._ds_get_dock_appointment_by_shipmentIds;
  }
  private _ds_get_dock_appointment_history_by_appointmentId: DockAppointments_ds_get_dock_appointment_history_by_appointmentIdService;
  public get ds_get_dock_appointment_history_by_appointmentId(): DockAppointments_ds_get_dock_appointment_history_by_appointmentIdService {
    if(!this._ds_get_dock_appointment_history_by_appointmentId) {
      this._ds_get_dock_appointment_history_by_appointmentId = this.injector.get(DockAppointments_ds_get_dock_appointment_history_by_appointmentIdService);
    }
    return this._ds_get_dock_appointment_history_by_appointmentId;
  }
  private _ds_get_dock_appointment_type_by_name: DockAppointments_ds_get_dock_appointment_type_by_nameService;
  public get ds_get_dock_appointment_type_by_name(): DockAppointments_ds_get_dock_appointment_type_by_nameService {
    if(!this._ds_get_dock_appointment_type_by_name) {
      this._ds_get_dock_appointment_type_by_name = this.injector.get(DockAppointments_ds_get_dock_appointment_type_by_nameService);
    }
    return this._ds_get_dock_appointment_type_by_name;
  }
  private _ds_get_dock_appointments_by_ids: DockAppointments_ds_get_dock_appointments_by_idsService;
  public get ds_get_dock_appointments_by_ids(): DockAppointments_ds_get_dock_appointments_by_idsService {
    if(!this._ds_get_dock_appointments_by_ids) {
      this._ds_get_dock_appointments_by_ids = this.injector.get(DockAppointments_ds_get_dock_appointments_by_idsService);
    }
    return this._ds_get_dock_appointments_by_ids;
  }
  private _ds_get_dock_door_by_locationId: DockAppointments_ds_get_dock_door_by_locationIdService;
  public get ds_get_dock_door_by_locationId(): DockAppointments_ds_get_dock_door_by_locationIdService {
    if(!this._ds_get_dock_door_by_locationId) {
      this._ds_get_dock_door_by_locationId = this.injector.get(DockAppointments_ds_get_dock_door_by_locationIdService);
    }
    return this._ds_get_dock_door_by_locationId;
  }
  private _ds_get_dock_doors_by_warehouse_id: DockAppointments_ds_get_dock_doors_by_warehouse_idService;
  public get ds_get_dock_doors_by_warehouse_id(): DockAppointments_ds_get_dock_doors_by_warehouse_idService {
    if(!this._ds_get_dock_doors_by_warehouse_id) {
      this._ds_get_dock_doors_by_warehouse_id = this.injector.get(DockAppointments_ds_get_dock_doors_by_warehouse_idService);
    }
    return this._ds_get_dock_doors_by_warehouse_id;
  }
  private _ds_get_locationcontainer_by_locationId: DockAppointments_ds_get_locationcontainer_by_locationIdService;
  public get ds_get_locationcontainer_by_locationId(): DockAppointments_ds_get_locationcontainer_by_locationIdService {
    if(!this._ds_get_locationcontainer_by_locationId) {
      this._ds_get_locationcontainer_by_locationId = this.injector.get(DockAppointments_ds_get_locationcontainer_by_locationIdService);
    }
    return this._ds_get_locationcontainer_by_locationId;
  }
  private _ds_get_locations_by_name_and_warehouseId: DockAppointments_ds_get_locations_by_name_and_warehouseIdService;
  public get ds_get_locations_by_name_and_warehouseId(): DockAppointments_ds_get_locations_by_name_and_warehouseIdService {
    if(!this._ds_get_locations_by_name_and_warehouseId) {
      this._ds_get_locations_by_name_and_warehouseId = this.injector.get(DockAppointments_ds_get_locations_by_name_and_warehouseIdService);
    }
    return this._ds_get_locations_by_name_and_warehouseId;
  }
  private _ds_get_order_by_dockAppointmentId: DockAppointments_ds_get_order_by_dockAppointmentIdService;
  public get ds_get_order_by_dockAppointmentId(): DockAppointments_ds_get_order_by_dockAppointmentIdService {
    if(!this._ds_get_order_by_dockAppointmentId) {
      this._ds_get_order_by_dockAppointmentId = this.injector.get(DockAppointments_ds_get_order_by_dockAppointmentIdService);
    }
    return this._ds_get_order_by_dockAppointmentId;
  }
  private _ds_get_order_by_orderId: DockAppointments_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): DockAppointments_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(DockAppointments_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_project_by_projectId: DockAppointments_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): DockAppointments_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(DockAppointments_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId: DockAppointments_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService;
  public get ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId(): DockAppointments_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService {
    if(!this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId) {
      this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId = this.injector.get(DockAppointments_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService);
    }
    return this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId;
  }
  private _ds_get_upcoming_dock_appointments_by_dock_door: DockAppointments_ds_get_upcoming_dock_appointments_by_dock_doorService;
  public get ds_get_upcoming_dock_appointments_by_dock_door(): DockAppointments_ds_get_upcoming_dock_appointments_by_dock_doorService {
    if(!this._ds_get_upcoming_dock_appointments_by_dock_door) {
      this._ds_get_upcoming_dock_appointments_by_dock_door = this.injector.get(DockAppointments_ds_get_upcoming_dock_appointments_by_dock_doorService);
    }
    return this._ds_get_upcoming_dock_appointments_by_dock_door;
  }
  private _ds_get_warehouse_by_warehouseId: DockAppointments_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): DockAppointments_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(DockAppointments_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_get_warehouse_parent_zone: DockAppointments_ds_get_warehouse_parent_zoneService;
  public get ds_get_warehouse_parent_zone(): DockAppointments_ds_get_warehouse_parent_zoneService {
    if(!this._ds_get_warehouse_parent_zone) {
      this._ds_get_warehouse_parent_zone = this.injector.get(DockAppointments_ds_get_warehouse_parent_zoneService);
    }
    return this._ds_get_warehouse_parent_zone;
  }
  private _ds_get_warehouses_by_warehouseIds: DockAppointments_ds_get_warehouses_by_warehouseIdsService;
  public get ds_get_warehouses_by_warehouseIds(): DockAppointments_ds_get_warehouses_by_warehouseIdsService {
    if(!this._ds_get_warehouses_by_warehouseIds) {
      this._ds_get_warehouses_by_warehouseIds = this.injector.get(DockAppointments_ds_get_warehouses_by_warehouseIdsService);
    }
    return this._ds_get_warehouses_by_warehouseIds;
  }
  private _ds_order_total_picked: DockAppointments_ds_order_total_pickedService;
  public get ds_order_total_picked(): DockAppointments_ds_order_total_pickedService {
    if(!this._ds_order_total_picked) {
      this._ds_order_total_picked = this.injector.get(DockAppointments_ds_order_total_pickedService);
    }
    return this._ds_order_total_picked;
  }
  private _ds_order_total_received: DockAppointments_ds_order_total_receivedService;
  public get ds_order_total_received(): DockAppointments_ds_order_total_receivedService {
    if(!this._ds_order_total_received) {
      this._ds_order_total_received = this.injector.get(DockAppointments_ds_order_total_receivedService);
    }
    return this._ds_order_total_received;
  }
  private _ds_orders_dd: DockAppointments_ds_orders_ddService;
  public get ds_orders_dd(): DockAppointments_ds_orders_ddService {
    if(!this._ds_orders_dd) {
      this._ds_orders_dd = this.injector.get(DockAppointments_ds_orders_ddService);
    }
    return this._ds_orders_dd;
  }
  private _ds_reasoncodes_dd: DockAppointments_ds_reasoncodes_ddService;
  public get ds_reasoncodes_dd(): DockAppointments_ds_reasoncodes_ddService {
    if(!this._ds_reasoncodes_dd) {
      this._ds_reasoncodes_dd = this.injector.get(DockAppointments_ds_reasoncodes_ddService);
    }
    return this._ds_reasoncodes_dd;
  }
  private _ds_total_appt_checked_in_by_date_widget: DockAppointments_ds_total_appt_checked_in_by_date_widgetService;
  public get ds_total_appt_checked_in_by_date_widget(): DockAppointments_ds_total_appt_checked_in_by_date_widgetService {
    if(!this._ds_total_appt_checked_in_by_date_widget) {
      this._ds_total_appt_checked_in_by_date_widget = this.injector.get(DockAppointments_ds_total_appt_checked_in_by_date_widgetService);
    }
    return this._ds_total_appt_checked_in_by_date_widget;
  }
  private _ds_total_appt_not_checked_in_by_date_widget: DockAppointments_ds_total_appt_not_checked_in_by_date_widgetService;
  public get ds_total_appt_not_checked_in_by_date_widget(): DockAppointments_ds_total_appt_not_checked_in_by_date_widgetService {
    if(!this._ds_total_appt_not_checked_in_by_date_widget) {
      this._ds_total_appt_not_checked_in_by_date_widget = this.injector.get(DockAppointments_ds_total_appt_not_checked_in_by_date_widgetService);
    }
    return this._ds_total_appt_not_checked_in_by_date_widget;
  }
  private _ds_unassigned_orders_dd: DockAppointments_ds_unassigned_orders_ddService;
  public get ds_unassigned_orders_dd(): DockAppointments_ds_unassigned_orders_ddService {
    if(!this._ds_unassigned_orders_dd) {
      this._ds_unassigned_orders_dd = this.injector.get(DockAppointments_ds_unassigned_orders_ddService);
    }
    return this._ds_unassigned_orders_dd;
  }
  private _ds_unscheduled_dock_appointments_calendar: DockAppointments_ds_unscheduled_dock_appointments_calendarService;
  public get ds_unscheduled_dock_appointments_calendar(): DockAppointments_ds_unscheduled_dock_appointments_calendarService {
    if(!this._ds_unscheduled_dock_appointments_calendar) {
      this._ds_unscheduled_dock_appointments_calendar = this.injector.get(DockAppointments_ds_unscheduled_dock_appointments_calendarService);
    }
    return this._ds_unscheduled_dock_appointments_calendar;
  }
}


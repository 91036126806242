import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Dynamics365BusinessCentral_order_po_queue_gridComponent } from './Dynamics365BusinessCentral.order_po_queue_grid.component';
import { Dynamics365BusinessCentral_material_gridComponent } from './Dynamics365BusinessCentral.material_grid.component';
import { Dynamics365BusinessCentral_inbound_order_todayComponent } from './Dynamics365BusinessCentral.inbound_order_today.component';
import { Dynamics365BusinessCentral_outbound_orders_todayComponent } from './Dynamics365BusinessCentral.outbound_orders_today.component';
import { Dynamics365BusinessCentral_materials_todayComponent } from './Dynamics365BusinessCentral.materials_today.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Dynamics365BusinessCentral_order_po_queue_gridComponent),
    forwardRef(() => Dynamics365BusinessCentral_material_gridComponent),
    forwardRef(() => Dynamics365BusinessCentral_inbound_order_todayComponent),
    forwardRef(() => Dynamics365BusinessCentral_outbound_orders_todayComponent),
    forwardRef(() => Dynamics365BusinessCentral_materials_todayComponent),
  ],
  selector: 'Dynamics365BusinessCentral-bc_hub',
  templateUrl: './Dynamics365BusinessCentral.bc_hub.component.html'
})
export class Dynamics365BusinessCentral_bc_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      settings: new ToolModel(new SplitButtonModel<{ config: ButtonModel, Initial_setup: ButtonModel, logs: ButtonModel, messages: ButtonModel }>(
        'settings',
        new ButtonStyles(null, null),
        false,
        ' ',
        'ms-Icon ms-Icon--Settings',
        null,
        [
          new ButtonModel('config', new ButtonStyles(null, null), false, false, 'Configuration', ''),
          new ButtonModel('Initial_setup', new ButtonStyles(null, null), false, false, 'Initial setup ', ''),
          new ButtonModel('logs', new ButtonStyles(null, null), false, false, 'Logs', ''),
          new ButtonModel('messages', new ButtonStyles(null, null), false, false, 'Messages', '')
        ])
    , false)
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        false,
        ),
      materials: new TabItemModel(
        this.rootTabGroup, 
        'Materials', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_orders', { read: Dynamics365BusinessCentral_order_po_queue_gridComponent }) $tabs_orders: Dynamics365BusinessCentral_order_po_queue_gridComponent;
      @ViewChild('$tabs_materials', { read: Dynamics365BusinessCentral_material_gridComponent }) $tabs_materials: Dynamics365BusinessCentral_material_gridComponent;
    //#endregion tabs children
    widgets = {
      inbound_orders: new WidgetModel(false),
      outbound_orders: new WidgetModel(false),
      materials_today: new WidgetModel(false),
    };
  
    //#region widgets inParams
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_inbound_orders', { read:  Dynamics365BusinessCentral_inbound_order_todayComponent }) $widgets_inbound_orders: Dynamics365BusinessCentral_inbound_order_todayComponent;
      @ViewChild('$widgets_outbound_orders', { read:  Dynamics365BusinessCentral_outbound_orders_todayComponent }) $widgets_outbound_orders: Dynamics365BusinessCentral_outbound_orders_todayComponent;
      @ViewChild('$widgets_materials_today', { read:  Dynamics365BusinessCentral_materials_todayComponent }) $widgets_materials_today: Dynamics365BusinessCentral_materials_todayComponent;
    //#endregion widgets children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `BC hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return 'Business Central Integration Hub';
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: Dynamics365BusinessCentral_ShellService,
  private datasources: Dynamics365BusinessCentral_DatasourceService,
  private flows: Dynamics365BusinessCentral_FlowService,
  private reports: Dynamics365BusinessCentral_ReportService,
  private localization: Dynamics365BusinessCentral_LocalizationService,
  private operations: Dynamics365BusinessCentral_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.orders,
      this.tabs.materials,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'BC';
    
    const $hub = this;
    const $utils = this.utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_inbound_orders') {
      if (!isNil(this.$widgets_inbound_orders) && !this.widgets.inbound_orders.hidden) {
        this.$widgets_inbound_orders.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_outbound_orders') {
      if (!isNil(this.$widgets_outbound_orders) && !this.widgets.outbound_orders.hidden) {
        this.$widgets_outbound_orders.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_materials_today') {
      if (!isNil(this.$widgets_materials_today) && !this.widgets.materials_today.hidden) {
        this.$widgets_materials_today.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_orders') {
      if (!isNil(this.$tabs_orders) && !this.tabs.orders.hidden) {
        this.$tabs_orders.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_materials') {
      if (!isNil(this.$tabs_materials) && !this.tabs.materials.hidden) {
        this.$tabs_materials.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_config_click(event = null) {
    return this.on_config_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_config_clickInternal(
    $hub: Dynamics365BusinessCentral_bc_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'bc_hub.on_config_click');
  let custrr=$settings.Dynamics365BusinessCentral.customer
  await $shell.Utilities.openconfigurations_gridDialog({ application_name: 'Dynamics365BusinessCentral',filter_values:{string_1:custrr}}, 'flyout', EModalSize.Xlarge);
  $hub.refresh();
  }
  on_initial_click(event = null) {
    return this.on_initial_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initial_clickInternal(
    $hub: Dynamics365BusinessCentral_bc_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'bc_hub.on_initial_click');
  await $shell.Dynamics365BusinessCentral.openinitial_setup_formDialog()
   $hub.refresh();
  }
  on_log_click(event = null) {
    return this.on_log_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_log_clickInternal(
    $hub: Dynamics365BusinessCentral_bc_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'bc_hub.on_log_click');
  await $shell.Utilities.openlogs_gridDialog({ application_name: 'Dynamics365BusinessCentral'}, 'flyout', EModalSize.Xlarge);
   $hub.refresh();
  }
  on_message_click(event = null) {
    return this.on_message_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_message_clickInternal(
    $hub: Dynamics365BusinessCentral_bc_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'bc_hub.on_message_click');
  await $shell.Utilities.openmessages_gridDialog({ application_name: 'Dynamics365BusinessCentral'}, 'flyout', EModalSize.Xlarge);
   $hub.refresh();
  }
  //#endregion private flows
}

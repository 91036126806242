import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { app_custom_invoice_creation_formComponent } from './app.custom_invoice_creation_form.component';
import { app_custom_print_order_labels_formComponent } from './app.custom_print_order_labels_form.component';
import { app_custom_purchase_order_creation_formComponent } from './app.custom_purchase_order_creation_form.component';
import { app_custom_sales_order_creation_formComponent } from './app.custom_sales_order_creation_form.component';
import { app_footprint_wms_homeComponent } from './app.footprint_wms_home.component';
import { app_custom_invoice_editorComponent } from './app.custom_invoice_editor.component';
import { app_custom_sales_order_editorComponent } from './app.custom_sales_order_editor.component';
import { app_custom_billing_records_gridComponent } from './app.custom_billing_records_grid.component';
import { app_custom_excel_order_import_gridComponent } from './app.custom_excel_order_import_grid.component';
import { app_custom_excel_order_import_new_gridComponent } from './app.custom_excel_order_import_new_grid.component';
import { app_custom_freight_billing_details_gridComponent } from './app.custom_freight_billing_details_grid.component';
import { app_custom_inventory_by_location_selection_gridComponent } from './app.custom_inventory_by_location_selection_grid.component';
import { app_custom_inventory_licenseplate_gridComponent } from './app.custom_inventory_licenseplate_grid.component';
import { app_custom_inventory_location_gridComponent } from './app.custom_inventory_location_grid.component';
import { app_custom_inventory_lot_gridComponent } from './app.custom_inventory_lot_grid.component';
import { app_custom_inventory_material_gridComponent } from './app.custom_inventory_material_grid.component';
import { app_custom_invoice_gridComponent } from './app.custom_invoice_grid.component';
import { app_custom_invoice_lines_gridComponent } from './app.custom_invoice_lines_grid.component';
import { app_custom_materials_udf_gridComponent } from './app.custom_materials_udf_grid.component';
import { app_custom_messages_fail_gridComponent } from './app.custom_messages_fail_grid.component';
import { app_custom_messages_success_gridComponent } from './app.custom_messages_success_grid.component';
import { app_custom_purchase_order_lines_gridComponent } from './app.custom_purchase_order_lines_grid.component';
import { app_custom_submitted_survey_inputs_gridComponent } from './app.custom_submitted_survey_inputs_grid.component';
import { app_custom_transload_orders_gridComponent } from './app.custom_transload_orders_grid.component';
import { app_custom_wave_creation_gridComponent } from './app.custom_wave_creation_grid.component';
import { app_original_excel_order_import_gridComponent } from './app.original_excel_order_import_grid.component';
import { app_custom_inventory_hubComponent } from './app.custom_inventory_hub.component';
import { app_custom_waves_hubComponent } from './app.custom_waves_hub.component';
import { app_custom_invoice_report_sales_invoiceComponent } from './app.custom_invoice_report_sales_invoice.component';
import { app_custom_account_contact_selector_singleComponent } from './app.custom_account_contact_selector_single.component';
import { app_custom_carriers_dd_singleComponent } from './app.custom_carriers_dd_single.component';
import { app_custom_lotcoo_dd_singleComponent } from './app.custom_lotcoo_dd_single.component';
import { app_custom_order_class_dd_singleComponent } from './app.custom_order_class_dd_single.component';
import { app_custom_projects_dd_singleComponent } from './app.custom_projects_dd_single.component';
import { app_custom_udf_allergen_dd_singleComponent } from './app.custom_udf_allergen_dd_single.component';
import { app_custom_account_contact_selector_multiComponent } from './app.custom_account_contact_selector_multi.component';
import { app_custom_carriers_dd_multiComponent } from './app.custom_carriers_dd_multi.component';
import { app_custom_lotcoo_dd_multiComponent } from './app.custom_lotcoo_dd_multi.component';
import { app_custom_order_class_dd_multiComponent } from './app.custom_order_class_dd_multi.component';
import { app_custom_projects_dd_multiComponent } from './app.custom_projects_dd_multi.component';
import { app_custom_udf_allergen_dd_multiComponent } from './app.custom_udf_allergen_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Carriers_ShellService } from './Carriers.shell.service';
import { PalletTransactions_ShellService } from './PalletTransactions.shell.service';
import { Surveys_ShellService } from './Surveys.shell.service';
import { Lots_ShellService } from './Lots.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Discussions_ShellService } from './Discussions.shell.service';
import { ExcelOrderImport_ShellService } from './ExcelOrderImport.shell.service';
import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { Waves_ShellService } from './Waves.shell.service';
import { ExcelInventoryImport_ShellService } from './ExcelInventoryImport.shell.service';
import { PrintNode_ShellService } from './PrintNode.shell.service';
import { Ultraship_ShellService } from './Ultraship.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Reports_ShellService } from './Reports.shell.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Addresses_ShellService } from './Addresses.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { TransloadOrders_ShellService } from './TransloadOrders.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public Carriers: Carriers_ShellService,
    public PalletTransactions: PalletTransactions_ShellService,
    public Surveys: Surveys_ShellService,
    public Lots: Lots_ShellService,
    public Owners: Owners_ShellService,
    public Discussions: Discussions_ShellService,
    public ExcelOrderImport: ExcelOrderImport_ShellService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public Waves: Waves_ShellService,
    public ExcelInventoryImport: ExcelInventoryImport_ShellService,
    public PrintNode: PrintNode_ShellService,
    public Ultraship: Ultraship_ShellService,
    public Notifications: Notifications_ShellService,
    public Reports: Reports_ShellService,
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_ShellService,
    public Addresses: Addresses_ShellService,
    public Materials: Materials_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public Inventory: Inventory_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public TransloadOrders: TransloadOrders_ShellService,
    public Locations: Locations_ShellService,
    public DockAppointments: DockAppointments_ShellService,
    public Invoices: Invoices_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  public opencustom_invoice_creation_form(inParams:{ projectId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_invoice_creation_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'New invoice',
        referenceName: 'custom_invoice_creation_form',
        component: app_custom_invoice_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_invoice_creation_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_invoice_creation_formComponent,
      'New invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_print_order_labels_form(inParams:{ orderId: number, context?: string, shipmentId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_print_order_labels_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom print order labels form',
        referenceName: 'custom_print_order_labels_form',
        component: app_custom_print_order_labels_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_print_order_labels_formDialog(
    inParams:{ orderId: number, context?: string, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_print_order_labels_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_print_order_labels_formComponent,
      'Custom print order labels form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_purchase_order_creation_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'New purchase order',
        referenceName: 'custom_purchase_order_creation_form',
        component: app_custom_purchase_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_purchase_order_creation_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_purchase_order_creation_formComponent,
      'New purchase order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_creation_form(inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_sales_order_creation_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'New sales order',
        referenceName: 'custom_sales_order_creation_form',
        component: app_custom_sales_order_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_sales_order_creation_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_creation_formComponent,
      'New sales order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openfootprint_wms_home(replaceCurrentView?: boolean) {
    this.logger.log('app', 'footprint_wms_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'footprint_wms_home',
        component: app_footprint_wms_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openfootprint_wms_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'footprint_wms_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_footprint_wms_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opencustom_invoice_editor(inParams:{ invoiceId: number, invoice_lines?: { open_to_line?: number } }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_invoice_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Edit Invoice',
        referenceName: 'custom_invoice_editor',
        component: app_custom_invoice_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_editorDialog(
    inParams:{ invoiceId: number, invoice_lines?: { open_to_line?: number } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_invoice_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_invoice_editorComponent,
      'Edit Invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_sales_order_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Edit Sales Order',
        referenceName: 'custom_sales_order_editor',
        component: app_custom_sales_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_sales_order_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_editorComponent,
      'Edit Sales Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_billing_records_grid(inParams:{ ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], includeCancelled?: boolean, includeInvoiced?: boolean, shipmentIds?: number[], workOrderId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_billing_records_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_Billing records',
        referenceName: 'custom_billing_records_grid',
        component: app_custom_billing_records_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_billing_records_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], includeCancelled?: boolean, includeInvoiced?: boolean, shipmentIds?: number[], workOrderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_billing_records_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_billing_records_gridComponent,
      'custom_Billing records',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_excel_order_import_grid(replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_excel_order_import_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Order Import',
        referenceName: 'custom_excel_order_import_grid',
        component: app_custom_excel_order_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_excel_order_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_excel_order_import_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_excel_order_import_gridComponent,
      'Custom Order Import',
      mode,
      dialogSize
    )
  }
  public opencustom_excel_order_import_new_grid(replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_excel_order_import_new_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Order Import',
        referenceName: 'custom_excel_order_import_new_grid',
        component: app_custom_excel_order_import_new_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_excel_order_import_new_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_excel_order_import_new_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_excel_order_import_new_gridComponent,
      'Custom Order Import',
      mode,
      dialogSize
    )
  }
  public opencustom_freight_billing_details_grid(inParams:{ lookupcode?: string }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_freight_billing_details_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'freight_billing_details_grid',
        referenceName: 'custom_freight_billing_details_grid',
        component: app_custom_freight_billing_details_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_freight_billing_details_gridDialog(
    inParams:{ lookupcode?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_freight_billing_details_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_freight_billing_details_gridComponent,
      'freight_billing_details_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_by_location_selection_grid(inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_inventory_by_location_selection_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory by location',
        referenceName: 'custom_inventory_by_location_selection_grid',
        component: app_custom_inventory_by_location_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_by_location_selection_gridDialog(
    inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, locationId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    this.logger.log('app', 'custom_inventory_by_location_selection_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_by_location_selection_gridComponent,
      'Inventory by location',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_licenseplate_grid(inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory by LP',
        referenceName: 'custom_inventory_licenseplate_grid',
        component: app_custom_inventory_licenseplate_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_licenseplate_gridDialog(
    inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_licenseplate_gridComponent,
      'Inventory by LP',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_location_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number, zonePath?: string, vendorReference?: string, ownerReference?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory',
        referenceName: 'custom_inventory_location_grid',
        component: app_custom_inventory_location_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_location_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number, zonePath?: string, vendorReference?: string, ownerReference?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_location_gridComponent,
      'Inventory',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_lot_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory by lot',
        referenceName: 'custom_inventory_lot_grid',
        component: app_custom_inventory_lot_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_lot_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_lot_gridComponent,
      'Inventory by lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_material_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_inventory_material_grid',
        referenceName: 'custom_inventory_material_grid',
        component: app_custom_inventory_material_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_material_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_material_gridComponent,
      'custom_inventory_material_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_invoice_grid(inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_invoice_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Invoices',
        referenceName: 'custom_invoice_grid',
        component: app_custom_invoice_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_invoice_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_invoice_gridComponent,
      'Invoices',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_invoice_lines_grid(inParams:{ invoiceId: number, open_to_line?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Invoice lines',
        referenceName: 'custom_invoice_lines_grid',
        component: app_custom_invoice_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_lines_gridDialog(
    inParams:{ invoiceId: number, open_to_line?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_invoice_lines_gridComponent,
      'Invoice lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_materials_udf_grid(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_materials_udf_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Materials Advanced',
        referenceName: 'custom_materials_udf_grid',
        component: app_custom_materials_udf_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_materials_udf_gridDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_materials_udf_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_materials_udf_gridComponent,
      'Materials Advanced',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_messages_fail_grid(inParams:{ application_name?: string, transaction_id?: string, customerr?: string }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_messages_fail_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Post fail',
        referenceName: 'custom_messages_fail_grid',
        component: app_custom_messages_fail_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_messages_fail_gridDialog(
    inParams:{ application_name?: string, transaction_id?: string, customerr?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ InvoiceId?: number }> {
    this.logger.log('app', 'custom_messages_fail_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_messages_fail_gridComponent,
      'Post fail',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_messages_success_grid(inParams:{ transaction_id?: string, application_name?: string, customerr?: string }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_messages_success_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Post success',
        referenceName: 'custom_messages_success_grid',
        component: app_custom_messages_success_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_messages_success_gridDialog(
    inParams:{ transaction_id?: string, application_name?: string, customerr?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ InvoiceId?: number }> {
    this.logger.log('app', 'custom_messages_success_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_messages_success_gridComponent,
      'Post success',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_purchase_order_lines_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Order Lines',
        referenceName: 'custom_purchase_order_lines_grid',
        component: app_custom_purchase_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_purchase_order_lines_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_purchase_order_lines_gridComponent,
      'Order Lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_submitted_survey_inputs_grid(inParams:{ surveyId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_submitted_survey_inputs_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Survey inputs',
        referenceName: 'custom_submitted_survey_inputs_grid',
        component: app_custom_submitted_survey_inputs_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_submitted_survey_inputs_gridDialog(
    inParams:{ surveyId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_submitted_survey_inputs_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_submitted_survey_inputs_gridComponent,
      'Survey inputs',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_transload_orders_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_transload_orders_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Transload orders',
        referenceName: 'custom_transload_orders_grid',
        component: app_custom_transload_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_transload_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_transload_orders_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_transload_orders_gridComponent,
      'Transload orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_wave_creation_grid(inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], waveId?: number, showCreate?: boolean, carrierId?: number, carrierServiceId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Create wave',
        referenceName: 'custom_wave_creation_grid',
        component: app_custom_wave_creation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_wave_creation_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], waveId?: number, showCreate?: boolean, carrierId?: number, carrierServiceId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_wave_creation_gridComponent,
      'Create wave',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoriginal_excel_order_import_grid(replaceCurrentView?: boolean) {
    this.logger.log('app', 'original_excel_order_import_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Order Import',
        referenceName: 'original_excel_order_import_grid',
        component: app_original_excel_order_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openoriginal_excel_order_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'original_excel_order_import_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_original_excel_order_import_gridComponent,
      'Order Import',
      mode,
      dialogSize
    )
  }
  public opencustom_inventory_hub(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_inventory_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_inventory_hub',
        referenceName: 'custom_inventory_hub',
        component: app_custom_inventory_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_hubDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_inventory_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_hubComponent,
      'custom_inventory_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_waves_hub(replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_waves_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Waves hub',
        referenceName: 'custom_waves_hub',
        component: app_custom_waves_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_waves_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_waves_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_waves_hubComponent,
      'Waves hub',
      mode,
      dialogSize
    )
  }
  public opencustom_invoice_report_sales_invoice(inParams:{ Id: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_invoice_report_sales_invoice');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'custom_invoice_report_sales_invoice',
        referenceName: 'custom_invoice_report_sales_invoice',
        component: app_custom_invoice_report_sales_invoiceComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_report_sales_invoiceDialog(
    inParams:{ Id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_invoice_report_sales_invoice');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      app_custom_invoice_report_sales_invoiceComponent,
      'custom_invoice_report_sales_invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'custom_invoice_creation_form') {
      this.logger.log('app', 'custom_invoice_creation_form');
      const title = 'New invoice';
      const component = app_custom_invoice_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number } = { projectId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_print_order_labels_form') {
      this.logger.log('app', 'custom_print_order_labels_form');
      const title = 'Custom print order labels form';
      const component = app_custom_print_order_labels_formComponent;
      const inParams:{ orderId: number, context?: string, shipmentId?: number } = { orderId: null, context: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('context'))) {
        const paramValueString = params.get('context');
        // TODO: date
        inParams.context = paramValueString;
              }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_purchase_order_creation_form') {
      this.logger.log('app', 'custom_purchase_order_creation_form');
      const title = 'New purchase order';
      const component = app_custom_purchase_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_creation_form') {
      this.logger.log('app', 'custom_sales_order_creation_form');
      const title = 'New sales order';
      const component = app_custom_sales_order_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number } = { projectId: null, ownerId: null, warehouseId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'footprint_wms_home') {
      this.logger.log('app', 'footprint_wms_home');
      const title = 'Home';
      const component = app_footprint_wms_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_invoice_editor') {
      this.logger.log('app', 'custom_invoice_editor');
      const title = 'Edit Invoice';
      const component = app_custom_invoice_editorComponent;
      const inParams:{ invoiceId: number, invoice_lines?: { open_to_line?: number } } = { invoiceId: null, invoice_lines: { open_to_line: null } };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('invoice_lines'))) {
        const paramValueString = params.get('invoice_lines');
        inParams.invoice_lines = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_editor') {
      this.logger.log('app', 'custom_sales_order_editor');
      const title = 'Edit Sales Order';
      const component = app_custom_sales_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_billing_records_grid') {
      this.logger.log('app', 'custom_billing_records_grid');
      const title = 'custom_Billing records';
      const component = app_custom_billing_records_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, materialIds?: number[], warehouseIds?: number[], orderIds?: number[], includeCancelled?: boolean, includeInvoiced?: boolean, shipmentIds?: number[], workOrderId?: number } = { ownerId: null, projectId: null, materialIds: [], warehouseIds: [], orderIds: [], includeCancelled: null, includeInvoiced: null, shipmentIds: [], workOrderId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderIds'))) {
        const paramValueString = params.get('orderIds');
        inParams.orderIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('includeCancelled'))) {
        const paramValueString = params.get('includeCancelled');
        inParams.includeCancelled = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('includeInvoiced'))) {
        const paramValueString = params.get('includeInvoiced');
        inParams.includeInvoiced = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('workOrderId'))) {
        const paramValueString = params.get('workOrderId');
        inParams.workOrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_excel_order_import_grid') {
      this.logger.log('app', 'custom_excel_order_import_grid');
      const title = 'Custom Order Import';
      const component = app_custom_excel_order_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_excel_order_import_new_grid') {
      this.logger.log('app', 'custom_excel_order_import_new_grid');
      const title = 'Custom Order Import';
      const component = app_custom_excel_order_import_new_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_freight_billing_details_grid') {
      this.logger.log('app', 'custom_freight_billing_details_grid');
      const title = 'freight_billing_details_grid';
      const component = app_custom_freight_billing_details_gridComponent;
      const inParams:{ lookupcode?: string } = { lookupcode: null };
      if (!isNil(params.get('lookupcode'))) {
        const paramValueString = params.get('lookupcode');
        // TODO: date
        inParams.lookupcode = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_by_location_selection_grid') {
      this.logger.log('app', 'custom_inventory_by_location_selection_grid');
      const title = 'Inventory by location';
      const component = app_custom_inventory_by_location_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number } = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_licenseplate_grid') {
      const title = 'Inventory by LP';
      const component = app_custom_inventory_licenseplate_gridComponent;
      const inParams:{ licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string } = { licenseplateId: null, ownerId: null, projectId: null, warehouseId: null, materialIds: [], vendorReference: null, ownerReference: null };
      if (!isNil(params.get('licenseplateId'))) {
        const paramValueString = params.get('licenseplateId');
        inParams.licenseplateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('vendorReference'))) {
        const paramValueString = params.get('vendorReference');
        // TODO: date
        inParams.vendorReference = paramValueString;
              }
      if (!isNil(params.get('ownerReference'))) {
        const paramValueString = params.get('ownerReference');
        // TODO: date
        inParams.ownerReference = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_location_grid') {
      const title = 'Inventory';
      const component = app_custom_inventory_location_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number, zonePath?: string, vendorReference?: string, ownerReference?: string } = { ownerId: null, projectId: null, warehouseId: null, locationId: null, materialIds: [], lotId: null, zonePath: null, vendorReference: null, ownerReference: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('zonePath'))) {
        const paramValueString = params.get('zonePath');
        // TODO: date
        inParams.zonePath = paramValueString;
              }
      if (!isNil(params.get('vendorReference'))) {
        const paramValueString = params.get('vendorReference');
        // TODO: date
        inParams.vendorReference = paramValueString;
              }
      if (!isNil(params.get('ownerReference'))) {
        const paramValueString = params.get('ownerReference');
        // TODO: date
        inParams.ownerReference = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_lot_grid') {
      const title = 'Inventory by lot';
      const component = app_custom_inventory_lot_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string } = { ownerId: null, projectId: null, warehouseId: null, materialIds: [], vendorReference: null, ownerReference: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('vendorReference'))) {
        const paramValueString = params.get('vendorReference');
        // TODO: date
        inParams.vendorReference = paramValueString;
              }
      if (!isNil(params.get('ownerReference'))) {
        const paramValueString = params.get('ownerReference');
        // TODO: date
        inParams.ownerReference = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_material_grid') {
      const title = 'custom_inventory_material_grid';
      const component = app_custom_inventory_material_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string } = { ownerId: null, projectId: null, warehouseId: null, materialIds: [], vendorReference: null, ownerReference: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('vendorReference'))) {
        const paramValueString = params.get('vendorReference');
        // TODO: date
        inParams.vendorReference = paramValueString;
              }
      if (!isNil(params.get('ownerReference'))) {
        const paramValueString = params.get('ownerReference');
        // TODO: date
        inParams.ownerReference = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_invoice_grid') {
      this.logger.log('app', 'custom_invoice_grid');
      const title = 'Invoices';
      const component = app_custom_invoice_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] } = { ownerId: null, projectId: null, statusIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_invoice_lines_grid') {
      const title = 'Invoice lines';
      const component = app_custom_invoice_lines_gridComponent;
      const inParams:{ invoiceId: number, open_to_line?: number } = { invoiceId: null, open_to_line: null };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('open_to_line'))) {
        const paramValueString = params.get('open_to_line');
        inParams.open_to_line = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_materials_udf_grid') {
      this.logger.log('app', 'custom_materials_udf_grid');
      const title = 'Materials Advanced';
      const component = app_custom_materials_udf_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_messages_fail_grid') {
      this.logger.log('app', 'custom_messages_fail_grid');
      const title = 'Post fail';
      const component = app_custom_messages_fail_gridComponent;
      const inParams:{ application_name?: string, transaction_id?: string, customerr?: string } = { application_name: null, transaction_id: null, customerr: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('customerr'))) {
        const paramValueString = params.get('customerr');
        // TODO: date
        inParams.customerr = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_messages_success_grid') {
      this.logger.log('app', 'custom_messages_success_grid');
      const title = 'Post success';
      const component = app_custom_messages_success_gridComponent;
      const inParams:{ transaction_id?: string, application_name?: string, customerr?: string } = { transaction_id: null, application_name: null, customerr: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('customerr'))) {
        const paramValueString = params.get('customerr');
        // TODO: date
        inParams.customerr = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_purchase_order_lines_grid') {
      this.logger.log('app', 'custom_purchase_order_lines_grid');
      const title = 'Order Lines';
      const component = app_custom_purchase_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_submitted_survey_inputs_grid') {
      this.logger.log('app', 'custom_submitted_survey_inputs_grid');
      const title = 'Survey inputs';
      const component = app_custom_submitted_survey_inputs_gridComponent;
      const inParams:{ surveyId: number } = { surveyId: null };
      if (!isNil(params.get('surveyId'))) {
        const paramValueString = params.get('surveyId');
        inParams.surveyId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_transload_orders_grid') {
      this.logger.log('app', 'custom_transload_orders_grid');
      const title = 'Transload orders';
      const component = app_custom_transload_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], materialIds?: number[] } = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], materialIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_wave_creation_grid') {
      const title = 'Create wave';
      const component = app_custom_wave_creation_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], waveId?: number, showCreate?: boolean, carrierId?: number, carrierServiceId?: number } = { ownerId: null, projectId: null, warehouseIds: [], waveId: null, showCreate: null, carrierId: null, carrierServiceId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('showCreate'))) {
        const paramValueString = params.get('showCreate');
        inParams.showCreate = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierServiceId'))) {
        const paramValueString = params.get('carrierServiceId');
        inParams.carrierServiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'original_excel_order_import_grid') {
      this.logger.log('app', 'original_excel_order_import_grid');
      const title = 'Order Import';
      const component = app_original_excel_order_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_hub') {
      this.logger.log('app', 'custom_inventory_hub');
      const title = 'custom_inventory_hub';
      const component = app_custom_inventory_hubComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number } = { ownerId: null, projectId: null, warehouseId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_waves_hub') {
      this.logger.log('app', 'custom_waves_hub');
      const title = 'Waves hub';
      const component = app_custom_waves_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_invoice_report_sales_invoice') {
      this.logger.log('app', 'custom_invoice_report_sales_invoice');
      const title = 'custom_invoice_report_sales_invoice';
      const component = app_custom_invoice_report_sales_invoiceComponent;
      const inParams:{ Id: number } = { Id: null };
      if (!isNil(params.get('Id'))) {
        const paramValueString = params.get('Id');
        inParams.Id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_account_contact_selector_single') {
      const title = 'custom_account_contact_selector';
      const component = app_custom_account_contact_selector_singleComponent;
      const inParams:{ owner_id?: number, account_id?: number, fullTextSearch?: string } = { owner_id: null, account_id: null, fullTextSearch: null };
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('account_id'))) {
        const paramValueString = params.get('account_id');
        inParams.account_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('fullTextSearch'))) {
        const paramValueString = params.get('fullTextSearch');
        // TODO: date
        inParams.fullTextSearch = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_carriers_dd_single') {
      const title = 'custom_carriers_dd';
      const component = app_custom_carriers_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_lotcoo_dd_single') {
      const title = 'custom_lotcoo_dd';
      const component = app_custom_lotcoo_dd_singleComponent;
      const inParams:{ material_id?: number } = { material_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_order_class_dd_single') {
      const title = 'custom_order_class_dd';
      const component = app_custom_order_class_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = app_custom_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_udf_allergen_dd_single') {
      const title = 'custom_udf_allergen_dd';
      const component = app_custom_udf_allergen_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_account_contact_selector_multi') {
      const title = 'custom_account_contact_selector';
      const component = app_custom_account_contact_selector_multiComponent;
      const inParams:{ owner_id?: number, account_id?: number, fullTextSearch?: string } = { owner_id: null, account_id: null, fullTextSearch: null };
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('account_id'))) {
        const paramValueString = params.get('account_id');
        inParams.account_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('fullTextSearch'))) {
        const paramValueString = params.get('fullTextSearch');
        // TODO: date
        inParams.fullTextSearch = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_carriers_dd_multi') {
      const title = 'custom_carriers_dd';
      const component = app_custom_carriers_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_lotcoo_dd_multi') {
      const title = 'custom_lotcoo_dd';
      const component = app_custom_lotcoo_dd_multiComponent;
      const inParams:{ material_id?: number } = { material_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_order_class_dd_multi') {
      const title = 'custom_order_class_dd';
      const component = app_custom_order_class_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = app_custom_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_udf_allergen_dd_multi') {
      const title = 'custom_udf_allergen_dd';
      const component = app_custom_udf_allergen_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Carriers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PalletTransactions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Surveys.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Lots.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Discussions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelOrderImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Waves.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelInventoryImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PrintNode.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Ultraship.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Reports.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Dynamics365BusinessCentral.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Addresses.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.TransloadOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.DockAppointments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}

import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface Iapp_custom_transload_orders_gridComponentEntity {
Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, Name?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { BillOfLading?: string, ConveyanceId?: number, ConveyenceReference?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ExpectedWarehouse?: { Name?: string }, Conveyance?: { LookupCode?: string } } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[], TransLoadsForInboundOrder?: { Id?: number, OutboundOrderId?: number, OutboundOrder?: { Id?: number, OrderStatusId?: number, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ConveyanceId?: number, ConveyenceReference?: string, Conveyance?: { LookupCode?: string } } }[] } }[], total_received?: { TotalCount?: number, ExpectedCount?: number, OrderId?: number }, orderline_count?: { ExpectedCount?: number, OrderId?: number }, calculated_total_items?: string}

interface Iapp_custom_transload_orders_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], materialIds?: number[]}


class app_custom_transload_orders_gridComponentRowModel extends GridRowModel {
  grid: app_custom_transload_orders_gridComponent;
  entity: Iapp_custom_transload_orders_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    orderclass: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_items: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendor_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    ship_to: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    inbound_conveyance: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    inbound_conveyance_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_transload_orders_gridComponent, entity: Iapp_custom_transload_orders_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_transload_orders_gridComponent, entity?: Iapp_custom_transload_orders_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
      materialIds:  $grid.inParams.materialIds ,
    };
    const data = await this.datasources.app.custom_ds_transload_orders_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.orderclass.displayControl as TextModel).text = $row.entity.OrderClass?.LookupCode;
    (this.cells.owner.displayControl as TextModel).text = $row.entity.Project?.Owner?.LookupCode;
    (this.cells.warehouse.displayControl as TextModel).text = $utils.isDefined($row.entity.PreferredWarehouse?.Name) ? $row.entity.PreferredWarehouse?.Name : $row.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouse?.Name;
    (this.cells.total_items.displayControl as TextModel).text = $row.entity.calculated_total_items;
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity.VendorReference;
    (this.cells.owner_reference.displayControl as TextModel).text = $row.entity.OwnerReference;
    (this.cells.ship_to.displayControl as TextModel).text = $row.entity.Account.Name;
    (this.cells.inbound_conveyance.displayControl as TextModel).text = $row.entity.ShipmentOrderLookups[0]?.Shipment?.Conveyance?.LookupCode;
    (this.cells.inbound_conveyance_reference.displayControl as TextModel).text = $row.entity.ShipmentOrderLookups[0]?.Shipment?.ConveyenceReference;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: app_custom_transload_orders_gridComponentRowModel,
  $grid: app_custom_transload_orders_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_transload_orders_grid.on_lookupcode_clicked');
  
  
  
  
  $shell.FootPrintManager.opentransload_order_editor({ orderId: $row.entity.Id });
  
  
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: app_custom_transload_orders_gridComponentRowModel,
  $grid: app_custom_transload_orders_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_transload_orders_grid.on_row_data_loaded');
  
  // Set Date/Time Formats
  const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Apply date format
  if ($utils.isDefined($row.entity.CreatedSysDateTime)) {
      $row.cells.created_date.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
  };
  
  
  // Apply project column formating
  if ($row.entity.Project.LookupCode === $row.entity.Project.Name) {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode
  } else {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode + ' ' + $row.entity.Project.Name
  }
  
  // Set custom status when the inbound or outbound order are in a processing status
  const inboundOrderStatus = $row.entity.OrderStatusId;
  const outboundOrderStatus = $row.entity.TransLoadsForInboundOrder[0]?.OutboundOrder?.OrderStatusId;
  
  if (inboundOrderStatus === 2 || outboundOrderStatus === 2) {
  
  
      if ($utils.isDefined($row.entity.TransLoadsForInboundOrder[0]?.Id)) {
          // Set custom status
          let transload_state = await $flows.TransloadOrders.get_transload_order_state({ transloadId: $row.entity.TransLoadsForInboundOrder[0]?.Id });
  
          switch (transload_state.state) {
  
              case 2: {
                  $row.cells.status.displayControl.text = 'Ready to receive'
                  break;
              }
              case 3: {
                  $row.cells.status.displayControl.text = 'Receiving in progress'
                  break;
              }
              case 4: {
                  $row.cells.status.displayControl.text = 'Ready to pick'
                  break;
              }
              case 5: {
                  $row.cells.status.displayControl.text = 'Ready to load'
                  break;
              }
              case 6: {
                  $row.cells.status.displayControl.text = 'Ready to complete'
                  break;
              }
          }
      }
  
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_transload_orders_grid',
  templateUrl: './app.custom_transload_orders_grid.component.html'
})
export class app_custom_transload_orders_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_transload_orders_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_transload_orders_gridComponentInParams = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], materialIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       orderclass: new GridHeaderModel(new HeaderStyles(null, null), 'Order class', false, false, null),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       total_items: new GridHeaderModel(new HeaderStyles(null, null), 'Total items', false, false, null),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'Created', false, false, null),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor reference', false, false, null),       owner_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Owner reference', false, false, null),       ship_to: new GridHeaderModel(new HeaderStyles(null, null), 'Ship to', false, false, null),       inbound_conveyance: new GridHeaderModel(new HeaderStyles(null, null), 'Conveyance', false, false, null),       inbound_conveyance_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Conveyance reference', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_transload_orders_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    created_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    created_from: new FieldModel(new DateBoxModel(this.formGroup.controls['created_from'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Created from', false, false),
    created_to: new FieldModel(new DateBoxModel(this.formGroup.controls['created_to'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Created to', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Transload orders';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
      materialIds:  $grid.inParams.materialIds ,
    };
    try {
    const data = await this.datasources.app.custom_ds_transload_orders_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_transload_orders_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_transload_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_transload_orders_grid.on_init');
  
  // Initialize date format
  var dateFormat = $settings.FootPrintManager.DateFormat;
  
  $grid.filters.created_from.control.format = dateFormat;
  $grid.filters.created_to.control.format = dateFormat;
  
  $grid.topToolbar.process.control.readOnly = true;
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: app_custom_transload_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_transload_orders_grid.on_export_flow');
  /**
   * Author: Ahmed Elsawy
   * Date: 2024-12-13
   * Description: Export functionality provided in a more reusable code
   * Version: 2.0
   */
  
  const settingsExcelExportLimit = $settings.FootPrintManager.ExcelExportLimit;
  const serverMaxAllowedRecordsLimit: number = 5000;// could be changed base on what each request to the server can handle.
  const concurrentLimit = 5;//max concurrent requests
  const timeout = 500;//use it only if you want to set a delay between requests...
  // Initialize
  var workBook = $event;
  var recordsToExport = [];
  var fullDataSet: any[] = [];
  
  //parameters to be passed to dataset api call
  const baseParams = {
      ownerId: $grid.inParams.ownerId,
      projectId: $grid.inParams.projectId,
      warehouseId: $grid.inParams.warehouseId,
      statusIds: $grid.inParams.statusIds,
      materialIds: $grid.inParams.materialIds,
      fullTextSearch: $grid.fullTextSearch,
      createdFrom: $grid.filters.created_from.control.value,
      createdTo: $grid.filters.created_to.control.value
  };
  
  //api call
  const dataApiCall = async (baseParams: any) => {
      return await $datasources.custom_ds_transload_orders_grid.get(baseParams);
  };
  
  //records count api call
  const countApiCall = async (baseParams: any) => {
      const params = { ...baseParams, $top: 0 };
      return await $datasources.custom_ds_transload_orders_grid.get(params);
  };
  
  
  //api call execution
  async function processApiCall(apiCall: (params: any) => Promise<any>, baseParams: any = {}, offset: number = 0, limit: number = 0): Promise<any> {
      const defaultParams = { $skip: offset, $top: limit };
      // Merge base, default, and additional params
      const params = { ...baseParams, ...defaultParams };
      return await apiCall(params);
  };
  
  
  //main funcion to handle concurrency in reagard of server and request limits
  async function processControlledAnounymusApiRequests(apiCall: (params: any) => Promise<any>, params: any = {}, totalRecords: number, requestLimit: number, serverLimit: number): Promise<any[]> {
      const totalBatches = Math.ceil(Math.min(totalRecords, serverLimit) / requestLimit);
  
      const result: any[] = [];
      const fetchPromiseQueue: Promise<any[]>[] = [];
  
      for (let i = 0; i < totalBatches; i++) {
          const offset = i * requestLimit;
          const limit = Math.min(requestLimit, serverLimit - (i * requestLimit));
  
          fetchPromiseQueue.push(processApiCall(apiCall, params, offset, limit));
  
          //process promiseQueue once reached the limit then clear queue for the next loop round...
          if (fetchPromiseQueue.length === concurrentLimit) {
              result.push(...await Promise.all(fetchPromiseQueue));
              fetchPromiseQueue.length = 0;
          }
      }
  
      // Process any remaining requests after the loop
      if (fetchPromiseQueue.length) result.push(...await Promise.all(fetchPromiseQueue));
  
      return result;
  }
  
  var lot_availability: any[] = [];
  var inboundOrders: any[] = [];
  
  
  try {
      // Set Date/Time Formats
      const dateFormat = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
      const { totalCount: requestedRecordsCount } = await countApiCall(baseParams);
  
      if (requestedRecordsCount > settingsExcelExportLimit) {
          await $shell.FootPrintManager.openInfoDialog(`Export limitation of ${settingsExcelExportLimit} exceeded`, `Excel will only export ${settingsExcelExportLimit} records`);
      }
  
      const responses = await processControlledAnounymusApiRequests(dataApiCall, baseParams, requestedRecordsCount, serverMaxAllowedRecordsLimit, settingsExcelExportLimit);
      responses.forEach(apiResponse => {
          fullDataSet.push(...apiResponse.result);
      });
  
      const chunkSize = 500; // Adjust chunk size as needed
  
      // Compile all data into JSON
      for (let record of fullDataSet) {
          const _project = (record.Project.LookupCode === record.Project.Name) ? record.Project.LookupCode : record.Project.LookupCode + ' ' + record.Project.Name;
  
          // Set custom status when the inbound or outbound order are in a processing status
          const inboundOrderStatus = record.OrderStatusId;
          const outboundOrderStatus = record.TransLoadsForInboundOrder[0]?.OutboundOrder?.OrderStatusId;
          let _status = record.Status?.Name;
          
          if (inboundOrderStatus === 2 || outboundOrderStatus === 2) {
  
  
              if ($utils.isDefined(record.TransLoadsForInboundOrder[0]?.Id)) {
                  // Set custom status
                  let transload_state = await $flows.TransloadOrders.get_transload_order_state({ transloadId: record.TransLoadsForInboundOrder[0]?.Id });
  
                  switch (transload_state.state) {
  
                      case 2: {
                          _status = 'Ready to receive'
                          break;
                      }
                      case 3: {
                          _status = 'Receiving in progress'
                          break;
                      }
                      case 4: {
                          _status = 'Ready to pick'
                          break;
                      }
                      case 5: {
                          _status = 'Ready to load'
                          break;
                      }
                      case 6: {
                          _status = 'Ready to complete'
                          break;
                      }
                  }
              }
  
          }
                
  
          recordsToExport.push({
              "Order": record.LookupCode,
              "Status": _status,
              "Order class": record.OrderClass?.LookupCode,
              "Owner": record.Project?.Owner?.LookupCode,
              "Project": _project,
              "Warehouse": $utils.isDefined(record.PreferredWarehouse?.Name) ? record.PreferredWarehouse?.Name : record.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouse?.Name,
              "Total items": record.calculated_total_items,
              "Created": $utils.date.format(record.CreatedSysDateTime, dateFormat),
              "Vendor reference": record.VendorReference,
              "Owner reference": record.OwnerReference,
              "Ship to": record.Account?.Name,
              "Conveyance": record.ShipmentOrderLookups[0]?.Shipment?.Conveyance?.LookupCode,
              "Conveyance reference": record.ShipmentOrderLookups[0]?.Shipment?.ConveyenceReference,
              "Notes": record.Notes
          });
  
      }
      var workSheet = $utils.excel.json_to_sheet(recordsToExport);
      $utils.excel.book_append_sheet(workBook, workSheet, "TransloadOrders");
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  
  
  // Function to chunk an array into smaller arrays of a specified size
  function chunkArray<T>(array: T[], chunkSize: number): T[][] {
      const chunks: T[][] = [];
      for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
  }
  
  // Main function to get the aggregated results
  async function getOrderStatus(allData: any[], chunkSize: number) {
      const uniqueOrders = allData.reduce((acc, order) => {
          if (!acc.some(o => o.Id === order.Id)) acc.push(order);
          return acc;
      }, []);
      const ordersinfo = [...new Set(uniqueOrders.filter(o => $utils.isDefined(o.Id)).map(o => {
          return {
              orderId: o.Id,
              orderStatusId: o.OrderStatusId,
              orderStatus: o.Status?.Name,
              requestedDeliveryDate: o.Order?.RequestedDeliveryDate,
              shipmentId: o.ShipmentOrderLookups[0]?.ShipmentId,
              expectedDate: o.ShipmentOrderLookups[0]?.Shipment?.ExpectedDate,
              containerSize: o.ShipmentOrderLookups[0]?.Shipment?.ContainerSize,
              shipmentStatusId: o.ShipmentOrderLookups[0]?.Shipment?.StatusId,
              waveId: o.ShipmentOrderLookups[0]?.Shipment?.WaveId,//ShipmentOrderLookups[0]?.Shipment?.WaveId
              waveStatus: o.ShipmentOrderLookups[0]?.Shipment?.Wave?.Status?.Name,
              waveStatusId: o.ShipmentOrderLookups[0]?.Shipment?.Wave?.StatusId
          }
      }))];
  
      let ordersResults: any[] = [];
      const ordersChunks = chunkArray(ordersinfo, chunkSize);
      async function fetchOrderStatusChunk(orders: any[]) {
          const response = (await $flows.SalesOrders.get_sales_order_state({ orders: orders })).states;
          return response;
      };
  
      for (let i = 0; i < ordersChunks.length; i++) {
          const r = await fetchOrderStatusChunk(ordersChunks[i]);
          ordersResults.push(...r);
      }
      return ordersResults;
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: app_custom_transload_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_transload_orders_grid.on_row_selected');
  $grid.topToolbar.process.control.readOnly = true;
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
      $grid.topToolbar.process.control.readOnly = false;
  }
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $grid: app_custom_transload_orders_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_transload_orders_grid.on_process_clicked');
  
  
  // Get the selected rows
  const selectRows = $grid.selectedRows;
  // Get the transload and order ids
  const transloadIds: number[] = [];
  const orderIds: number[] = [];
  
  for (const row of selectRows) {
    const transLoad = row.entity.TransLoadsForInboundOrder?.[0];
    if (transLoad) {
      transloadIds.push(transLoad.Id);
    }
    orderIds.push(row.entity.Id);
  }
  
  
  // Check if all the orders has at least one order line
  const orders = (await $datasources.custom_ds_get_orderLines_by_orderIds.get({ orderIds })).result;
  const noLineOrderIds: number[] = [];
  
  orders.forEach(order => {
      if (order.OrderLines.length === 0) {
          noLineOrderIds.push(order.Id);
  
          const index = orderIds.indexOf(order.Id);
          if (index !== -1) {
              orderIds.splice(index, 1);
              transloadIds.splice(index, 1);
          }
      }
  });
  
  if(noLineOrderIds.length > 0 ){
      const confirmed = await $shell.FootPrintManager.openConfirmationDialog('Process Order', `The following orders cannot be processed because they don't have any order lines: ${noLineOrderIds.join(', ')} Do you want to continue without these orders?`, 'Yes', 'No');
      if (!confirmed) {
          return;
      }
  } 
  
  try {
  
      $grid.topToolbar.process.control.readOnly = true;
      $grid.topToolbar.process.control.icon = "icon datex-default-spinner";
      $grid.topToolbar.process.control.label = 'Processing';
  
  
      const result = await $flows.custom_process_transload_orders_flow({ transloadIds });
      const failedOrders = result.failedOrders;
  
      if ($utils.isDefined(failedOrders) && failedOrders.length > 0) {
          const failedOrderIds = failedOrders.map(failedOrder => failedOrder.orderId)
          await $shell.FootPrintManager.openErrorDialog('These orders we unable be processed.', failedOrderIds.join(", ") );
      }
  
      $grid.topToolbar.process.control.styles.resetStyle();
      $grid.topToolbar.process.control.icon = "icon icon-ic_fluent_arrow_clockwise_dashes_20_regular";
      $grid.topToolbar.process.control.label = 'Process';
      $grid.topToolbar.process.control.readOnly = true; 
      $grid.refresh();
  
  } catch (error) {
      $grid.topToolbar.process.control.styles.resetStyle();
      $grid.topToolbar.process.control.icon = "icon icon-ic_fluent_arrow_clockwise_dashes_20_regular";
      $grid.topToolbar.process.control.label = 'Process';
      $grid.topToolbar.process.control.readOnly = false;
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      await $shell.FootPrintManager.openErrorDialog('Order processing error', 'An error occurred during processing of the transload order', [errorMessage], null, [{ message: errorMessage, detail: errorDetail }]);
  }
  
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'app_custom_transload_orders_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}

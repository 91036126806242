import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './InventoryTransfers.frontend.types'

@Injectable({ providedIn: 'root' })
export class InventoryTransfers_update_inventory_transfer_intermediaryService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { intermediary_id: number, project_id: number, account_id: number }): Promise<{ is_success: boolean, error?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.intermediary_id)) {
      missingRequiredInParams.push('\'intermediary_id\'');
    }
    if (isNil(inParams.project_id)) {
      missingRequiredInParams.push('\'project_id\'');
    }
    if (isNil(inParams.account_id)) {
      missingRequiredInParams.push('\'account_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransfers/functions/update_inventory_transfer_intermediary`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}


import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Manifesting_manifesting_library_homeComponent } from './Manifesting.manifesting_library_home.component';
import { Manifesting_easypost_manifesting_shipping_container_editorComponent } from './Manifesting.easypost_manifesting_shipping_container_editor.component';
import { Manifesting_easypost_manifesting_shipments_gridComponent } from './Manifesting.easypost_manifesting_shipments_grid.component';
import { Manifesting_shiptheory_manifesting_shipments_gridComponent } from './Manifesting.shiptheory_manifesting_shipments_grid.component';
import { Manifesting_manifesting_hubComponent } from './Manifesting.manifesting_hub.component';
import { Manifesting_commercial_invoice_contentComponent } from './Manifesting.commercial_invoice_content.component';
import { Manifesting_shipping_label_contentComponent } from './Manifesting.shipping_label_content.component';
import { Manifesting_shipping_label_content_base64_pdfComponent } from './Manifesting.shipping_label_content_base64_pdf.component';
import { Manifesting_containers_by_type_dd_singleComponent } from './Manifesting.containers_by_type_dd_single.component';
import { Manifesting_incoterms_dd_singleComponent } from './Manifesting.incoterms_dd_single.component';
import { Manifesting_label_size_dd_singleComponent } from './Manifesting.label_size_dd_single.component';
import { Manifesting_containers_by_type_dd_multiComponent } from './Manifesting.containers_by_type_dd_multi.component';
import { Manifesting_incoterms_dd_multiComponent } from './Manifesting.incoterms_dd_multi.component';
import { Manifesting_label_size_dd_multiComponent } from './Manifesting.label_size_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { ShipTheory_ShellService } from './ShipTheory.shell.service';
import { PrintNode_ShellService } from './PrintNode.shell.service';
import { EasyPost_ShellService } from './EasyPost.shell.service';

@Injectable({ providedIn: 'root' })
export class Manifesting_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public ShipTheory: ShipTheory_ShellService,
    public PrintNode: PrintNode_ShellService,
    public EasyPost: EasyPost_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Manifesting: Manifesting_ShellService = this;

  public openmanifesting_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Manifesting', 'manifesting_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Manifesting_manifesting_library_home',
        component: Manifesting_manifesting_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanifesting_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Manifesting', 'manifesting_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_manifesting_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openeasypost_manifesting_shipping_container_editor(inParams:{ shipping_container_id: number, shipping_container_type_name?: string, shipping_container_weight?: number, printer_id?: number, printerIdPackingList?: number, target_location_id: number }, replaceCurrentView?: boolean) {
    this.logger.log('Manifesting', 'easypost_manifesting_shipping_container_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Easypost shipping container',
        referenceName: 'Manifesting_easypost_manifesting_shipping_container_editor',
        component: Manifesting_easypost_manifesting_shipping_container_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openeasypost_manifesting_shipping_container_editorDialog(
    inParams:{ shipping_container_id: number, shipping_container_type_name?: string, shipping_container_weight?: number, printer_id?: number, printerIdPackingList?: number, target_location_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ out_shippingLabel?: string, manifested_flag: boolean }> {
    this.logger.log('Manifesting', 'easypost_manifesting_shipping_container_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_easypost_manifesting_shipping_container_editorComponent,
      'Easypost shipping container',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openeasypost_manifesting_shipments_grid(inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'EasyPost shipments manifesting grid',
        referenceName: 'Manifesting_easypost_manifesting_shipments_grid',
        component: Manifesting_easypost_manifesting_shipments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openeasypost_manifesting_shipments_gridDialog(
    inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_easypost_manifesting_shipments_gridComponent,
      'EasyPost shipments manifesting grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshiptheory_manifesting_shipments_grid(inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ShipTheory shipments manifesting grid',
        referenceName: 'Manifesting_shiptheory_manifesting_shipments_grid',
        component: Manifesting_shiptheory_manifesting_shipments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshiptheory_manifesting_shipments_gridDialog(
    inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_shiptheory_manifesting_shipments_gridComponent,
      'ShipTheory shipments manifesting grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanifesting_hub(inParams:{ shipmentIds: number[] }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Manifesting Hub',
        referenceName: 'Manifesting_manifesting_hub',
        component: Manifesting_manifesting_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanifesting_hubDialog(
    inParams:{ shipmentIds: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_manifesting_hubComponent,
      'Manifesting Hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencommercial_invoice_content(inParams:{ url: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Commercial invoice',
        referenceName: 'Manifesting_commercial_invoice_content',
        component: Manifesting_commercial_invoice_contentComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencommercial_invoice_contentDialog(
    inParams:{ url: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_commercial_invoice_contentComponent,
      'Commercial invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_label_content(inParams:{ url: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Shipping label',
        referenceName: 'Manifesting_shipping_label_content',
        component: Manifesting_shipping_label_contentComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_label_contentDialog(
    inParams:{ url: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_shipping_label_contentComponent,
      'Shipping label',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openshipping_label_content_base64_pdf(inParams:{ label_input: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Shipping label content by base64 pdf',
        referenceName: 'Manifesting_shipping_label_content_base64_pdf',
        component: Manifesting_shipping_label_content_base64_pdfComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openshipping_label_content_base64_pdfDialog(
    inParams:{ label_input: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manifesting_shipping_label_content_base64_pdfComponent,
      'Shipping label content by base64 pdf',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Manifesting_manifesting_library_home') {
      this.logger.log('Manifesting', 'manifesting_library_home');
      const title = 'Home';
      const component = Manifesting_manifesting_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_easypost_manifesting_shipping_container_editor') {
      this.logger.log('Manifesting', 'easypost_manifesting_shipping_container_editor');
      const title = 'Easypost shipping container';
      const component = Manifesting_easypost_manifesting_shipping_container_editorComponent;
      const inParams:{ shipping_container_id: number, shipping_container_type_name?: string, shipping_container_weight?: number, printer_id?: number, printerIdPackingList?: number, target_location_id: number } = { shipping_container_id: null, shipping_container_type_name: null, shipping_container_weight: null, printer_id: null, printerIdPackingList: null, target_location_id: null };
      if (!isNil(params.get('shipping_container_id'))) {
        const paramValueString = params.get('shipping_container_id');
        inParams.shipping_container_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipping_container_type_name'))) {
        const paramValueString = params.get('shipping_container_type_name');
        // TODO: date
        inParams.shipping_container_type_name = paramValueString;
              }
      if (!isNil(params.get('shipping_container_weight'))) {
        const paramValueString = params.get('shipping_container_weight');
        inParams.shipping_container_weight = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('printer_id'))) {
        const paramValueString = params.get('printer_id');
        inParams.printer_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('printerIdPackingList'))) {
        const paramValueString = params.get('printerIdPackingList');
        inParams.printerIdPackingList = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('target_location_id'))) {
        const paramValueString = params.get('target_location_id');
        inParams.target_location_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_easypost_manifesting_shipments_grid') {
      const title = 'EasyPost shipments manifesting grid';
      const component = Manifesting_easypost_manifesting_shipments_gridComponent;
      const inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number } = { shipmentIds: [], printerId: null, length: null, width: null, height: null, weight: null, utilizeShipmentContainer: null, labelSize: null, contentsExplanation: null, containerCount: null, printerIdPackinglist: null };
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('printerId'))) {
        const paramValueString = params.get('printerId');
        inParams.printerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('length'))) {
        const paramValueString = params.get('length');
        inParams.length = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('width'))) {
        const paramValueString = params.get('width');
        inParams.width = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('height'))) {
        const paramValueString = params.get('height');
        inParams.height = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('weight'))) {
        const paramValueString = params.get('weight');
        inParams.weight = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('utilizeShipmentContainer'))) {
        const paramValueString = params.get('utilizeShipmentContainer');
        inParams.utilizeShipmentContainer = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('labelSize'))) {
        const paramValueString = params.get('labelSize');
        inParams.labelSize = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('contentsExplanation'))) {
        const paramValueString = params.get('contentsExplanation');
        // TODO: date
        inParams.contentsExplanation = paramValueString;
              }
      if (!isNil(params.get('containerCount'))) {
        const paramValueString = params.get('containerCount');
        inParams.containerCount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('printerIdPackinglist'))) {
        const paramValueString = params.get('printerIdPackinglist');
        inParams.printerIdPackinglist = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_shiptheory_manifesting_shipments_grid') {
      const title = 'ShipTheory shipments manifesting grid';
      const component = Manifesting_shiptheory_manifesting_shipments_gridComponent;
      const inParams:{ shipmentIds: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number, printerIdPackinglist?: number } = { shipmentIds: [], printerId: null, length: null, width: null, height: null, weight: null, utilizeShipmentContainer: null, labelSize: null, contentsExplanation: null, containerCount: null, printerIdPackinglist: null };
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('printerId'))) {
        const paramValueString = params.get('printerId');
        inParams.printerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('length'))) {
        const paramValueString = params.get('length');
        inParams.length = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('width'))) {
        const paramValueString = params.get('width');
        inParams.width = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('height'))) {
        const paramValueString = params.get('height');
        inParams.height = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('weight'))) {
        const paramValueString = params.get('weight');
        inParams.weight = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('utilizeShipmentContainer'))) {
        const paramValueString = params.get('utilizeShipmentContainer');
        inParams.utilizeShipmentContainer = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('labelSize'))) {
        const paramValueString = params.get('labelSize');
        inParams.labelSize = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('contentsExplanation'))) {
        const paramValueString = params.get('contentsExplanation');
        // TODO: date
        inParams.contentsExplanation = paramValueString;
              }
      if (!isNil(params.get('containerCount'))) {
        const paramValueString = params.get('containerCount');
        inParams.containerCount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('printerIdPackinglist'))) {
        const paramValueString = params.get('printerIdPackinglist');
        inParams.printerIdPackinglist = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_manifesting_hub') {
      const title = 'Manifesting Hub';
      const component = Manifesting_manifesting_hubComponent;
      const inParams:{ shipmentIds: number[] } = { shipmentIds: [] };
      if (!isNil(params.get('shipmentIds'))) {
        const paramValueString = params.get('shipmentIds');
        inParams.shipmentIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_commercial_invoice_content') {
      const title = 'Commercial invoice';
      const component = Manifesting_commercial_invoice_contentComponent;
      const inParams:{ url: string } = { url: null };
      if (!isNil(params.get('url'))) {
        const paramValueString = params.get('url');
        // TODO: date
        inParams.url = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_shipping_label_content') {
      const title = 'Shipping label';
      const component = Manifesting_shipping_label_contentComponent;
      const inParams:{ url: string } = { url: null };
      if (!isNil(params.get('url'))) {
        const paramValueString = params.get('url');
        // TODO: date
        inParams.url = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_shipping_label_content_base64_pdf') {
      const title = 'Shipping label content by base64 pdf';
      const component = Manifesting_shipping_label_content_base64_pdfComponent;
      const inParams:{ label_input: string } = { label_input: null };
      if (!isNil(params.get('label_input'))) {
        const paramValueString = params.get('label_input');
        // TODO: date
        inParams.label_input = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_containers_by_type_dd_single') {
      const title = 'Container Types dropdown';
      const component = Manifesting_containers_by_type_dd_singleComponent;
      const inParams:{ containerTypeCategoriesId?: number } = { containerTypeCategoriesId: null };
      if (!isNil(params.get('containerTypeCategoriesId'))) {
        const paramValueString = params.get('containerTypeCategoriesId');
        inParams.containerTypeCategoriesId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_incoterms_dd_single') {
      this.logger.log('Manifesting', 'incoterms_dd_single');
      const title = 'Incoterms';
      const component = Manifesting_incoterms_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_label_size_dd_single') {
      const title = 'Label Size dropdown';
      const component = Manifesting_label_size_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_containers_by_type_dd_multi') {
      const title = 'Container Types dropdown';
      const component = Manifesting_containers_by_type_dd_multiComponent;
      const inParams:{ containerTypeCategoriesId?: number } = { containerTypeCategoriesId: null };
      if (!isNil(params.get('containerTypeCategoriesId'))) {
        const paramValueString = params.get('containerTypeCategoriesId');
        inParams.containerTypeCategoriesId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_incoterms_dd_multi') {
      this.logger.log('Manifesting', 'incoterms_dd_multi');
      const title = 'Incoterms';
      const component = Manifesting_incoterms_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manifesting_label_size_dd_multi') {
      const title = 'Label Size dropdown';
      const component = Manifesting_label_size_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ShipTheory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PrintNode.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.EasyPost.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}

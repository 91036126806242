import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';


@Injectable({ providedIn: 'root' })
export class PrimaryPicks_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
  }

    public Utilities: Utilities_OperationService;
  public PrimaryPicks: PrimaryPicks_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/PrimaryPicks/operations/${operationName}/isauthorized`, null);
  }
}

import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { PrimaryPicks_materials_dd_singleComponent } from './PrimaryPicks.materials_dd_single.component';
import { PrimaryPicks_materials_dd_multiComponent } from './PrimaryPicks.materials_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class PrimaryPicks_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public PrimaryPicks: PrimaryPicks_ShellService = this;


  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'PrimaryPicks_materials_dd_single') {
      const title = 'Project Materials dropdown';
      const component = PrimaryPicks_materials_dd_singleComponent;
      const inParams:{ projectId?: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrimaryPicks_materials_dd_multi') {
      const title = 'Project Materials dropdown';
      const component = PrimaryPicks_materials_dd_multiComponent;
      const inParams:{ projectId?: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}

import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Dynamics365BusinessCentral_ds_contact_id_by_contact_nameService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_flow_order_linesService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_flow_order_lines_updateService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_flow_orders_detailService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_billingService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_contact_and_address_id_by_ownerService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_contact_and_address_id_by_projectService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_currency_idService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_customer_nameService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_errorsService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_inventory_measure_id_by_nameService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_countService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_detailService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_detail_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_detail_grid_sumService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_line_detailService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_line_detail_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_linesService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_lines_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_lines_grouppedService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_new_detailService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoicesService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_linenumber_by_line_flowService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_linenumber_flowService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_material_by_lookupcode_and_projectIdService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_material_check_if_updateService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_material_check_operation_typeService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_material_id_by_lookupService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_material_list_new_queue_materialsService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_material_list_queue_materials_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_material_list_update_queue_materialsService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_material_list_update_queue_materials_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_addresses_by_orderIdService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_id_by_ownerService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_line_numberService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_lines_by_orderId_without_childrenService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_list_queue_orders_poService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_list_queue_orders_soService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_materials_by_lookupcodesService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_shipments_by_orderIdService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_shipments_by_shipmentIdService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_order_warehouse_id_by_codeService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_owner_id_by_lookupService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_po_order_check_operation_typeService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_project_and_owner_id_by_lookupService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_shipment_lines_by_orderId_orderLineNumberService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_so_order_check_operation_typeService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_tagsService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_tasks_by_orderId_orderLineNumberService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_tasks_by_orderId_orderLineNumber_soService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_invoice_lines_grid_newService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_invoices_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_material_materials_todayService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_complete_lines_by_orderidService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_get_list_order_lines_by_order_idService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_get_list_order_lines_by_so_order_idService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_get_list_po_order_lines_by_order_idService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_get_list_so_order_lines_by_order_idService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_list_queue_complete_ordersService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_list_queue_orders_new_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_list_queue_orders_update_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_list_queue_update_ordersService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_outbound_order_todayService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_order_so_received_widgetService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_orders_check_order_statusService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_orders_count_errors_todayService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_orders_inbound_todayService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_orders_receive_po_widgetService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_storage_integrationService } from './Dynamics365BusinessCentral.datasource.index';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Dynamics365BusinessCentral: Dynamics365BusinessCentral_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_contact_id_by_contact_name: Dynamics365BusinessCentral_ds_contact_id_by_contact_nameService;
  public get ds_contact_id_by_contact_name(): Dynamics365BusinessCentral_ds_contact_id_by_contact_nameService {
    if(!this._ds_contact_id_by_contact_name) {
      this._ds_contact_id_by_contact_name = this.injector.get(Dynamics365BusinessCentral_ds_contact_id_by_contact_nameService);
    }
    return this._ds_contact_id_by_contact_name;
  }
  private _ds_flow_order_lines: Dynamics365BusinessCentral_ds_flow_order_linesService;
  public get ds_flow_order_lines(): Dynamics365BusinessCentral_ds_flow_order_linesService {
    if(!this._ds_flow_order_lines) {
      this._ds_flow_order_lines = this.injector.get(Dynamics365BusinessCentral_ds_flow_order_linesService);
    }
    return this._ds_flow_order_lines;
  }
  private _ds_flow_order_lines_update: Dynamics365BusinessCentral_ds_flow_order_lines_updateService;
  public get ds_flow_order_lines_update(): Dynamics365BusinessCentral_ds_flow_order_lines_updateService {
    if(!this._ds_flow_order_lines_update) {
      this._ds_flow_order_lines_update = this.injector.get(Dynamics365BusinessCentral_ds_flow_order_lines_updateService);
    }
    return this._ds_flow_order_lines_update;
  }
  private _ds_flow_orders_detail: Dynamics365BusinessCentral_ds_flow_orders_detailService;
  public get ds_flow_orders_detail(): Dynamics365BusinessCentral_ds_flow_orders_detailService {
    if(!this._ds_flow_orders_detail) {
      this._ds_flow_orders_detail = this.injector.get(Dynamics365BusinessCentral_ds_flow_orders_detailService);
    }
    return this._ds_flow_orders_detail;
  }
  private _ds_get_billing: Dynamics365BusinessCentral_ds_get_billingService;
  public get ds_get_billing(): Dynamics365BusinessCentral_ds_get_billingService {
    if(!this._ds_get_billing) {
      this._ds_get_billing = this.injector.get(Dynamics365BusinessCentral_ds_get_billingService);
    }
    return this._ds_get_billing;
  }
  private _ds_get_contact_and_address_id_by_owner: Dynamics365BusinessCentral_ds_get_contact_and_address_id_by_ownerService;
  public get ds_get_contact_and_address_id_by_owner(): Dynamics365BusinessCentral_ds_get_contact_and_address_id_by_ownerService {
    if(!this._ds_get_contact_and_address_id_by_owner) {
      this._ds_get_contact_and_address_id_by_owner = this.injector.get(Dynamics365BusinessCentral_ds_get_contact_and_address_id_by_ownerService);
    }
    return this._ds_get_contact_and_address_id_by_owner;
  }
  private _ds_get_contact_and_address_id_by_project: Dynamics365BusinessCentral_ds_get_contact_and_address_id_by_projectService;
  public get ds_get_contact_and_address_id_by_project(): Dynamics365BusinessCentral_ds_get_contact_and_address_id_by_projectService {
    if(!this._ds_get_contact_and_address_id_by_project) {
      this._ds_get_contact_and_address_id_by_project = this.injector.get(Dynamics365BusinessCentral_ds_get_contact_and_address_id_by_projectService);
    }
    return this._ds_get_contact_and_address_id_by_project;
  }
  private _ds_get_currency_id: Dynamics365BusinessCentral_ds_get_currency_idService;
  public get ds_get_currency_id(): Dynamics365BusinessCentral_ds_get_currency_idService {
    if(!this._ds_get_currency_id) {
      this._ds_get_currency_id = this.injector.get(Dynamics365BusinessCentral_ds_get_currency_idService);
    }
    return this._ds_get_currency_id;
  }
  private _ds_get_customer_name: Dynamics365BusinessCentral_ds_get_customer_nameService;
  public get ds_get_customer_name(): Dynamics365BusinessCentral_ds_get_customer_nameService {
    if(!this._ds_get_customer_name) {
      this._ds_get_customer_name = this.injector.get(Dynamics365BusinessCentral_ds_get_customer_nameService);
    }
    return this._ds_get_customer_name;
  }
  private _ds_get_errors: Dynamics365BusinessCentral_ds_get_errorsService;
  public get ds_get_errors(): Dynamics365BusinessCentral_ds_get_errorsService {
    if(!this._ds_get_errors) {
      this._ds_get_errors = this.injector.get(Dynamics365BusinessCentral_ds_get_errorsService);
    }
    return this._ds_get_errors;
  }
  private _ds_get_inventory_measure_id_by_name: Dynamics365BusinessCentral_ds_get_inventory_measure_id_by_nameService;
  public get ds_get_inventory_measure_id_by_name(): Dynamics365BusinessCentral_ds_get_inventory_measure_id_by_nameService {
    if(!this._ds_get_inventory_measure_id_by_name) {
      this._ds_get_inventory_measure_id_by_name = this.injector.get(Dynamics365BusinessCentral_ds_get_inventory_measure_id_by_nameService);
    }
    return this._ds_get_inventory_measure_id_by_name;
  }
  private _ds_get_invoice_count: Dynamics365BusinessCentral_ds_get_invoice_countService;
  public get ds_get_invoice_count(): Dynamics365BusinessCentral_ds_get_invoice_countService {
    if(!this._ds_get_invoice_count) {
      this._ds_get_invoice_count = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_countService);
    }
    return this._ds_get_invoice_count;
  }
  private _ds_get_invoice_detail: Dynamics365BusinessCentral_ds_get_invoice_detailService;
  public get ds_get_invoice_detail(): Dynamics365BusinessCentral_ds_get_invoice_detailService {
    if(!this._ds_get_invoice_detail) {
      this._ds_get_invoice_detail = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_detailService);
    }
    return this._ds_get_invoice_detail;
  }
  private _ds_get_invoice_detail_grid: Dynamics365BusinessCentral_ds_get_invoice_detail_gridService;
  public get ds_get_invoice_detail_grid(): Dynamics365BusinessCentral_ds_get_invoice_detail_gridService {
    if(!this._ds_get_invoice_detail_grid) {
      this._ds_get_invoice_detail_grid = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_detail_gridService);
    }
    return this._ds_get_invoice_detail_grid;
  }
  private _ds_get_invoice_detail_grid_sum: Dynamics365BusinessCentral_ds_get_invoice_detail_grid_sumService;
  public get ds_get_invoice_detail_grid_sum(): Dynamics365BusinessCentral_ds_get_invoice_detail_grid_sumService {
    if(!this._ds_get_invoice_detail_grid_sum) {
      this._ds_get_invoice_detail_grid_sum = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_detail_grid_sumService);
    }
    return this._ds_get_invoice_detail_grid_sum;
  }
  private _ds_get_invoice_line_detail: Dynamics365BusinessCentral_ds_get_invoice_line_detailService;
  public get ds_get_invoice_line_detail(): Dynamics365BusinessCentral_ds_get_invoice_line_detailService {
    if(!this._ds_get_invoice_line_detail) {
      this._ds_get_invoice_line_detail = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_line_detailService);
    }
    return this._ds_get_invoice_line_detail;
  }
  private _ds_get_invoice_line_detail_grid: Dynamics365BusinessCentral_ds_get_invoice_line_detail_gridService;
  public get ds_get_invoice_line_detail_grid(): Dynamics365BusinessCentral_ds_get_invoice_line_detail_gridService {
    if(!this._ds_get_invoice_line_detail_grid) {
      this._ds_get_invoice_line_detail_grid = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_line_detail_gridService);
    }
    return this._ds_get_invoice_line_detail_grid;
  }
  private _ds_get_invoice_lines: Dynamics365BusinessCentral_ds_get_invoice_linesService;
  public get ds_get_invoice_lines(): Dynamics365BusinessCentral_ds_get_invoice_linesService {
    if(!this._ds_get_invoice_lines) {
      this._ds_get_invoice_lines = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_linesService);
    }
    return this._ds_get_invoice_lines;
  }
  private _ds_get_invoice_lines_grid: Dynamics365BusinessCentral_ds_get_invoice_lines_gridService;
  public get ds_get_invoice_lines_grid(): Dynamics365BusinessCentral_ds_get_invoice_lines_gridService {
    if(!this._ds_get_invoice_lines_grid) {
      this._ds_get_invoice_lines_grid = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_lines_gridService);
    }
    return this._ds_get_invoice_lines_grid;
  }
  private _ds_get_invoice_lines_groupped: Dynamics365BusinessCentral_ds_get_invoice_lines_grouppedService;
  public get ds_get_invoice_lines_groupped(): Dynamics365BusinessCentral_ds_get_invoice_lines_grouppedService {
    if(!this._ds_get_invoice_lines_groupped) {
      this._ds_get_invoice_lines_groupped = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_lines_grouppedService);
    }
    return this._ds_get_invoice_lines_groupped;
  }
  private _ds_get_invoice_new_detail: Dynamics365BusinessCentral_ds_get_invoice_new_detailService;
  public get ds_get_invoice_new_detail(): Dynamics365BusinessCentral_ds_get_invoice_new_detailService {
    if(!this._ds_get_invoice_new_detail) {
      this._ds_get_invoice_new_detail = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_new_detailService);
    }
    return this._ds_get_invoice_new_detail;
  }
  private _ds_get_invoices: Dynamics365BusinessCentral_ds_get_invoicesService;
  public get ds_get_invoices(): Dynamics365BusinessCentral_ds_get_invoicesService {
    if(!this._ds_get_invoices) {
      this._ds_get_invoices = this.injector.get(Dynamics365BusinessCentral_ds_get_invoicesService);
    }
    return this._ds_get_invoices;
  }
  private _ds_get_linenumber_by_line_flow: Dynamics365BusinessCentral_ds_get_linenumber_by_line_flowService;
  public get ds_get_linenumber_by_line_flow(): Dynamics365BusinessCentral_ds_get_linenumber_by_line_flowService {
    if(!this._ds_get_linenumber_by_line_flow) {
      this._ds_get_linenumber_by_line_flow = this.injector.get(Dynamics365BusinessCentral_ds_get_linenumber_by_line_flowService);
    }
    return this._ds_get_linenumber_by_line_flow;
  }
  private _ds_get_linenumber_flow: Dynamics365BusinessCentral_ds_get_linenumber_flowService;
  public get ds_get_linenumber_flow(): Dynamics365BusinessCentral_ds_get_linenumber_flowService {
    if(!this._ds_get_linenumber_flow) {
      this._ds_get_linenumber_flow = this.injector.get(Dynamics365BusinessCentral_ds_get_linenumber_flowService);
    }
    return this._ds_get_linenumber_flow;
  }
  private _ds_get_material_by_lookupcode_and_projectId: Dynamics365BusinessCentral_ds_get_material_by_lookupcode_and_projectIdService;
  public get ds_get_material_by_lookupcode_and_projectId(): Dynamics365BusinessCentral_ds_get_material_by_lookupcode_and_projectIdService {
    if(!this._ds_get_material_by_lookupcode_and_projectId) {
      this._ds_get_material_by_lookupcode_and_projectId = this.injector.get(Dynamics365BusinessCentral_ds_get_material_by_lookupcode_and_projectIdService);
    }
    return this._ds_get_material_by_lookupcode_and_projectId;
  }
  private _ds_get_material_check_if_update: Dynamics365BusinessCentral_ds_get_material_check_if_updateService;
  public get ds_get_material_check_if_update(): Dynamics365BusinessCentral_ds_get_material_check_if_updateService {
    if(!this._ds_get_material_check_if_update) {
      this._ds_get_material_check_if_update = this.injector.get(Dynamics365BusinessCentral_ds_get_material_check_if_updateService);
    }
    return this._ds_get_material_check_if_update;
  }
  private _ds_get_material_check_operation_type: Dynamics365BusinessCentral_ds_get_material_check_operation_typeService;
  public get ds_get_material_check_operation_type(): Dynamics365BusinessCentral_ds_get_material_check_operation_typeService {
    if(!this._ds_get_material_check_operation_type) {
      this._ds_get_material_check_operation_type = this.injector.get(Dynamics365BusinessCentral_ds_get_material_check_operation_typeService);
    }
    return this._ds_get_material_check_operation_type;
  }
  private _ds_get_material_id_by_lookup: Dynamics365BusinessCentral_ds_get_material_id_by_lookupService;
  public get ds_get_material_id_by_lookup(): Dynamics365BusinessCentral_ds_get_material_id_by_lookupService {
    if(!this._ds_get_material_id_by_lookup) {
      this._ds_get_material_id_by_lookup = this.injector.get(Dynamics365BusinessCentral_ds_get_material_id_by_lookupService);
    }
    return this._ds_get_material_id_by_lookup;
  }
  private _ds_get_material_list_new_queue_materials: Dynamics365BusinessCentral_ds_get_material_list_new_queue_materialsService;
  public get ds_get_material_list_new_queue_materials(): Dynamics365BusinessCentral_ds_get_material_list_new_queue_materialsService {
    if(!this._ds_get_material_list_new_queue_materials) {
      this._ds_get_material_list_new_queue_materials = this.injector.get(Dynamics365BusinessCentral_ds_get_material_list_new_queue_materialsService);
    }
    return this._ds_get_material_list_new_queue_materials;
  }
  private _ds_get_material_list_queue_materials_grid: Dynamics365BusinessCentral_ds_get_material_list_queue_materials_gridService;
  public get ds_get_material_list_queue_materials_grid(): Dynamics365BusinessCentral_ds_get_material_list_queue_materials_gridService {
    if(!this._ds_get_material_list_queue_materials_grid) {
      this._ds_get_material_list_queue_materials_grid = this.injector.get(Dynamics365BusinessCentral_ds_get_material_list_queue_materials_gridService);
    }
    return this._ds_get_material_list_queue_materials_grid;
  }
  private _ds_get_material_list_update_queue_materials: Dynamics365BusinessCentral_ds_get_material_list_update_queue_materialsService;
  public get ds_get_material_list_update_queue_materials(): Dynamics365BusinessCentral_ds_get_material_list_update_queue_materialsService {
    if(!this._ds_get_material_list_update_queue_materials) {
      this._ds_get_material_list_update_queue_materials = this.injector.get(Dynamics365BusinessCentral_ds_get_material_list_update_queue_materialsService);
    }
    return this._ds_get_material_list_update_queue_materials;
  }
  private _ds_get_material_list_update_queue_materials_grid: Dynamics365BusinessCentral_ds_get_material_list_update_queue_materials_gridService;
  public get ds_get_material_list_update_queue_materials_grid(): Dynamics365BusinessCentral_ds_get_material_list_update_queue_materials_gridService {
    if(!this._ds_get_material_list_update_queue_materials_grid) {
      this._ds_get_material_list_update_queue_materials_grid = this.injector.get(Dynamics365BusinessCentral_ds_get_material_list_update_queue_materials_gridService);
    }
    return this._ds_get_material_list_update_queue_materials_grid;
  }
  private _ds_get_order_addresses_by_orderId: Dynamics365BusinessCentral_ds_get_order_addresses_by_orderIdService;
  public get ds_get_order_addresses_by_orderId(): Dynamics365BusinessCentral_ds_get_order_addresses_by_orderIdService {
    if(!this._ds_get_order_addresses_by_orderId) {
      this._ds_get_order_addresses_by_orderId = this.injector.get(Dynamics365BusinessCentral_ds_get_order_addresses_by_orderIdService);
    }
    return this._ds_get_order_addresses_by_orderId;
  }
  private _ds_get_order_id_by_owner: Dynamics365BusinessCentral_ds_get_order_id_by_ownerService;
  public get ds_get_order_id_by_owner(): Dynamics365BusinessCentral_ds_get_order_id_by_ownerService {
    if(!this._ds_get_order_id_by_owner) {
      this._ds_get_order_id_by_owner = this.injector.get(Dynamics365BusinessCentral_ds_get_order_id_by_ownerService);
    }
    return this._ds_get_order_id_by_owner;
  }
  private _ds_get_order_line_number: Dynamics365BusinessCentral_ds_get_order_line_numberService;
  public get ds_get_order_line_number(): Dynamics365BusinessCentral_ds_get_order_line_numberService {
    if(!this._ds_get_order_line_number) {
      this._ds_get_order_line_number = this.injector.get(Dynamics365BusinessCentral_ds_get_order_line_numberService);
    }
    return this._ds_get_order_line_number;
  }
  private _ds_get_order_lines_by_orderId_without_children: Dynamics365BusinessCentral_ds_get_order_lines_by_orderId_without_childrenService;
  public get ds_get_order_lines_by_orderId_without_children(): Dynamics365BusinessCentral_ds_get_order_lines_by_orderId_without_childrenService {
    if(!this._ds_get_order_lines_by_orderId_without_children) {
      this._ds_get_order_lines_by_orderId_without_children = this.injector.get(Dynamics365BusinessCentral_ds_get_order_lines_by_orderId_without_childrenService);
    }
    return this._ds_get_order_lines_by_orderId_without_children;
  }
  private _ds_get_order_list_queue_orders_po: Dynamics365BusinessCentral_ds_get_order_list_queue_orders_poService;
  public get ds_get_order_list_queue_orders_po(): Dynamics365BusinessCentral_ds_get_order_list_queue_orders_poService {
    if(!this._ds_get_order_list_queue_orders_po) {
      this._ds_get_order_list_queue_orders_po = this.injector.get(Dynamics365BusinessCentral_ds_get_order_list_queue_orders_poService);
    }
    return this._ds_get_order_list_queue_orders_po;
  }
  private _ds_get_order_list_queue_orders_so: Dynamics365BusinessCentral_ds_get_order_list_queue_orders_soService;
  public get ds_get_order_list_queue_orders_so(): Dynamics365BusinessCentral_ds_get_order_list_queue_orders_soService {
    if(!this._ds_get_order_list_queue_orders_so) {
      this._ds_get_order_list_queue_orders_so = this.injector.get(Dynamics365BusinessCentral_ds_get_order_list_queue_orders_soService);
    }
    return this._ds_get_order_list_queue_orders_so;
  }
  private _ds_get_order_materials_by_lookupcodes: Dynamics365BusinessCentral_ds_get_order_materials_by_lookupcodesService;
  public get ds_get_order_materials_by_lookupcodes(): Dynamics365BusinessCentral_ds_get_order_materials_by_lookupcodesService {
    if(!this._ds_get_order_materials_by_lookupcodes) {
      this._ds_get_order_materials_by_lookupcodes = this.injector.get(Dynamics365BusinessCentral_ds_get_order_materials_by_lookupcodesService);
    }
    return this._ds_get_order_materials_by_lookupcodes;
  }
  private _ds_get_order_shipments_by_orderId: Dynamics365BusinessCentral_ds_get_order_shipments_by_orderIdService;
  public get ds_get_order_shipments_by_orderId(): Dynamics365BusinessCentral_ds_get_order_shipments_by_orderIdService {
    if(!this._ds_get_order_shipments_by_orderId) {
      this._ds_get_order_shipments_by_orderId = this.injector.get(Dynamics365BusinessCentral_ds_get_order_shipments_by_orderIdService);
    }
    return this._ds_get_order_shipments_by_orderId;
  }
  private _ds_get_order_shipments_by_shipmentId: Dynamics365BusinessCentral_ds_get_order_shipments_by_shipmentIdService;
  public get ds_get_order_shipments_by_shipmentId(): Dynamics365BusinessCentral_ds_get_order_shipments_by_shipmentIdService {
    if(!this._ds_get_order_shipments_by_shipmentId) {
      this._ds_get_order_shipments_by_shipmentId = this.injector.get(Dynamics365BusinessCentral_ds_get_order_shipments_by_shipmentIdService);
    }
    return this._ds_get_order_shipments_by_shipmentId;
  }
  private _ds_get_order_warehouse_id_by_code: Dynamics365BusinessCentral_ds_get_order_warehouse_id_by_codeService;
  public get ds_get_order_warehouse_id_by_code(): Dynamics365BusinessCentral_ds_get_order_warehouse_id_by_codeService {
    if(!this._ds_get_order_warehouse_id_by_code) {
      this._ds_get_order_warehouse_id_by_code = this.injector.get(Dynamics365BusinessCentral_ds_get_order_warehouse_id_by_codeService);
    }
    return this._ds_get_order_warehouse_id_by_code;
  }
  private _ds_get_owner_id_by_lookup: Dynamics365BusinessCentral_ds_get_owner_id_by_lookupService;
  public get ds_get_owner_id_by_lookup(): Dynamics365BusinessCentral_ds_get_owner_id_by_lookupService {
    if(!this._ds_get_owner_id_by_lookup) {
      this._ds_get_owner_id_by_lookup = this.injector.get(Dynamics365BusinessCentral_ds_get_owner_id_by_lookupService);
    }
    return this._ds_get_owner_id_by_lookup;
  }
  private _ds_get_po_order_check_operation_type: Dynamics365BusinessCentral_ds_get_po_order_check_operation_typeService;
  public get ds_get_po_order_check_operation_type(): Dynamics365BusinessCentral_ds_get_po_order_check_operation_typeService {
    if(!this._ds_get_po_order_check_operation_type) {
      this._ds_get_po_order_check_operation_type = this.injector.get(Dynamics365BusinessCentral_ds_get_po_order_check_operation_typeService);
    }
    return this._ds_get_po_order_check_operation_type;
  }
  private _ds_get_project_and_owner_id_by_lookup: Dynamics365BusinessCentral_ds_get_project_and_owner_id_by_lookupService;
  public get ds_get_project_and_owner_id_by_lookup(): Dynamics365BusinessCentral_ds_get_project_and_owner_id_by_lookupService {
    if(!this._ds_get_project_and_owner_id_by_lookup) {
      this._ds_get_project_and_owner_id_by_lookup = this.injector.get(Dynamics365BusinessCentral_ds_get_project_and_owner_id_by_lookupService);
    }
    return this._ds_get_project_and_owner_id_by_lookup;
  }
  private _ds_get_shipment_lines_by_orderId_orderLineNumber: Dynamics365BusinessCentral_ds_get_shipment_lines_by_orderId_orderLineNumberService;
  public get ds_get_shipment_lines_by_orderId_orderLineNumber(): Dynamics365BusinessCentral_ds_get_shipment_lines_by_orderId_orderLineNumberService {
    if(!this._ds_get_shipment_lines_by_orderId_orderLineNumber) {
      this._ds_get_shipment_lines_by_orderId_orderLineNumber = this.injector.get(Dynamics365BusinessCentral_ds_get_shipment_lines_by_orderId_orderLineNumberService);
    }
    return this._ds_get_shipment_lines_by_orderId_orderLineNumber;
  }
  private _ds_get_so_order_check_operation_type: Dynamics365BusinessCentral_ds_get_so_order_check_operation_typeService;
  public get ds_get_so_order_check_operation_type(): Dynamics365BusinessCentral_ds_get_so_order_check_operation_typeService {
    if(!this._ds_get_so_order_check_operation_type) {
      this._ds_get_so_order_check_operation_type = this.injector.get(Dynamics365BusinessCentral_ds_get_so_order_check_operation_typeService);
    }
    return this._ds_get_so_order_check_operation_type;
  }
  private _ds_get_tags: Dynamics365BusinessCentral_ds_get_tagsService;
  public get ds_get_tags(): Dynamics365BusinessCentral_ds_get_tagsService {
    if(!this._ds_get_tags) {
      this._ds_get_tags = this.injector.get(Dynamics365BusinessCentral_ds_get_tagsService);
    }
    return this._ds_get_tags;
  }
  private _ds_get_tasks_by_orderId_orderLineNumber: Dynamics365BusinessCentral_ds_get_tasks_by_orderId_orderLineNumberService;
  public get ds_get_tasks_by_orderId_orderLineNumber(): Dynamics365BusinessCentral_ds_get_tasks_by_orderId_orderLineNumberService {
    if(!this._ds_get_tasks_by_orderId_orderLineNumber) {
      this._ds_get_tasks_by_orderId_orderLineNumber = this.injector.get(Dynamics365BusinessCentral_ds_get_tasks_by_orderId_orderLineNumberService);
    }
    return this._ds_get_tasks_by_orderId_orderLineNumber;
  }
  private _ds_get_tasks_by_orderId_orderLineNumber_so: Dynamics365BusinessCentral_ds_get_tasks_by_orderId_orderLineNumber_soService;
  public get ds_get_tasks_by_orderId_orderLineNumber_so(): Dynamics365BusinessCentral_ds_get_tasks_by_orderId_orderLineNumber_soService {
    if(!this._ds_get_tasks_by_orderId_orderLineNumber_so) {
      this._ds_get_tasks_by_orderId_orderLineNumber_so = this.injector.get(Dynamics365BusinessCentral_ds_get_tasks_by_orderId_orderLineNumber_soService);
    }
    return this._ds_get_tasks_by_orderId_orderLineNumber_so;
  }
  private _ds_invoice_lines_grid_new: Dynamics365BusinessCentral_ds_invoice_lines_grid_newService;
  public get ds_invoice_lines_grid_new(): Dynamics365BusinessCentral_ds_invoice_lines_grid_newService {
    if(!this._ds_invoice_lines_grid_new) {
      this._ds_invoice_lines_grid_new = this.injector.get(Dynamics365BusinessCentral_ds_invoice_lines_grid_newService);
    }
    return this._ds_invoice_lines_grid_new;
  }
  private _ds_invoices_grid: Dynamics365BusinessCentral_ds_invoices_gridService;
  public get ds_invoices_grid(): Dynamics365BusinessCentral_ds_invoices_gridService {
    if(!this._ds_invoices_grid) {
      this._ds_invoices_grid = this.injector.get(Dynamics365BusinessCentral_ds_invoices_gridService);
    }
    return this._ds_invoices_grid;
  }
  private _ds_material_materials_today: Dynamics365BusinessCentral_ds_material_materials_todayService;
  public get ds_material_materials_today(): Dynamics365BusinessCentral_ds_material_materials_todayService {
    if(!this._ds_material_materials_today) {
      this._ds_material_materials_today = this.injector.get(Dynamics365BusinessCentral_ds_material_materials_todayService);
    }
    return this._ds_material_materials_today;
  }
  private _ds_order_complete_lines_by_orderid: Dynamics365BusinessCentral_ds_order_complete_lines_by_orderidService;
  public get ds_order_complete_lines_by_orderid(): Dynamics365BusinessCentral_ds_order_complete_lines_by_orderidService {
    if(!this._ds_order_complete_lines_by_orderid) {
      this._ds_order_complete_lines_by_orderid = this.injector.get(Dynamics365BusinessCentral_ds_order_complete_lines_by_orderidService);
    }
    return this._ds_order_complete_lines_by_orderid;
  }
  private _ds_order_get_list_order_lines_by_order_id: Dynamics365BusinessCentral_ds_order_get_list_order_lines_by_order_idService;
  public get ds_order_get_list_order_lines_by_order_id(): Dynamics365BusinessCentral_ds_order_get_list_order_lines_by_order_idService {
    if(!this._ds_order_get_list_order_lines_by_order_id) {
      this._ds_order_get_list_order_lines_by_order_id = this.injector.get(Dynamics365BusinessCentral_ds_order_get_list_order_lines_by_order_idService);
    }
    return this._ds_order_get_list_order_lines_by_order_id;
  }
  private _ds_order_get_list_order_lines_by_so_order_id: Dynamics365BusinessCentral_ds_order_get_list_order_lines_by_so_order_idService;
  public get ds_order_get_list_order_lines_by_so_order_id(): Dynamics365BusinessCentral_ds_order_get_list_order_lines_by_so_order_idService {
    if(!this._ds_order_get_list_order_lines_by_so_order_id) {
      this._ds_order_get_list_order_lines_by_so_order_id = this.injector.get(Dynamics365BusinessCentral_ds_order_get_list_order_lines_by_so_order_idService);
    }
    return this._ds_order_get_list_order_lines_by_so_order_id;
  }
  private _ds_order_get_list_po_order_lines_by_order_id: Dynamics365BusinessCentral_ds_order_get_list_po_order_lines_by_order_idService;
  public get ds_order_get_list_po_order_lines_by_order_id(): Dynamics365BusinessCentral_ds_order_get_list_po_order_lines_by_order_idService {
    if(!this._ds_order_get_list_po_order_lines_by_order_id) {
      this._ds_order_get_list_po_order_lines_by_order_id = this.injector.get(Dynamics365BusinessCentral_ds_order_get_list_po_order_lines_by_order_idService);
    }
    return this._ds_order_get_list_po_order_lines_by_order_id;
  }
  private _ds_order_get_list_so_order_lines_by_order_id: Dynamics365BusinessCentral_ds_order_get_list_so_order_lines_by_order_idService;
  public get ds_order_get_list_so_order_lines_by_order_id(): Dynamics365BusinessCentral_ds_order_get_list_so_order_lines_by_order_idService {
    if(!this._ds_order_get_list_so_order_lines_by_order_id) {
      this._ds_order_get_list_so_order_lines_by_order_id = this.injector.get(Dynamics365BusinessCentral_ds_order_get_list_so_order_lines_by_order_idService);
    }
    return this._ds_order_get_list_so_order_lines_by_order_id;
  }
  private _ds_order_list_queue_complete_orders: Dynamics365BusinessCentral_ds_order_list_queue_complete_ordersService;
  public get ds_order_list_queue_complete_orders(): Dynamics365BusinessCentral_ds_order_list_queue_complete_ordersService {
    if(!this._ds_order_list_queue_complete_orders) {
      this._ds_order_list_queue_complete_orders = this.injector.get(Dynamics365BusinessCentral_ds_order_list_queue_complete_ordersService);
    }
    return this._ds_order_list_queue_complete_orders;
  }
  private _ds_order_list_queue_orders_new_grid: Dynamics365BusinessCentral_ds_order_list_queue_orders_new_gridService;
  public get ds_order_list_queue_orders_new_grid(): Dynamics365BusinessCentral_ds_order_list_queue_orders_new_gridService {
    if(!this._ds_order_list_queue_orders_new_grid) {
      this._ds_order_list_queue_orders_new_grid = this.injector.get(Dynamics365BusinessCentral_ds_order_list_queue_orders_new_gridService);
    }
    return this._ds_order_list_queue_orders_new_grid;
  }
  private _ds_order_list_queue_orders_update_grid: Dynamics365BusinessCentral_ds_order_list_queue_orders_update_gridService;
  public get ds_order_list_queue_orders_update_grid(): Dynamics365BusinessCentral_ds_order_list_queue_orders_update_gridService {
    if(!this._ds_order_list_queue_orders_update_grid) {
      this._ds_order_list_queue_orders_update_grid = this.injector.get(Dynamics365BusinessCentral_ds_order_list_queue_orders_update_gridService);
    }
    return this._ds_order_list_queue_orders_update_grid;
  }
  private _ds_order_list_queue_update_orders: Dynamics365BusinessCentral_ds_order_list_queue_update_ordersService;
  public get ds_order_list_queue_update_orders(): Dynamics365BusinessCentral_ds_order_list_queue_update_ordersService {
    if(!this._ds_order_list_queue_update_orders) {
      this._ds_order_list_queue_update_orders = this.injector.get(Dynamics365BusinessCentral_ds_order_list_queue_update_ordersService);
    }
    return this._ds_order_list_queue_update_orders;
  }
  private _ds_order_outbound_order_today: Dynamics365BusinessCentral_ds_order_outbound_order_todayService;
  public get ds_order_outbound_order_today(): Dynamics365BusinessCentral_ds_order_outbound_order_todayService {
    if(!this._ds_order_outbound_order_today) {
      this._ds_order_outbound_order_today = this.injector.get(Dynamics365BusinessCentral_ds_order_outbound_order_todayService);
    }
    return this._ds_order_outbound_order_today;
  }
  private _ds_order_so_received_widget: Dynamics365BusinessCentral_ds_order_so_received_widgetService;
  public get ds_order_so_received_widget(): Dynamics365BusinessCentral_ds_order_so_received_widgetService {
    if(!this._ds_order_so_received_widget) {
      this._ds_order_so_received_widget = this.injector.get(Dynamics365BusinessCentral_ds_order_so_received_widgetService);
    }
    return this._ds_order_so_received_widget;
  }
  private _ds_orders_check_order_status: Dynamics365BusinessCentral_ds_orders_check_order_statusService;
  public get ds_orders_check_order_status(): Dynamics365BusinessCentral_ds_orders_check_order_statusService {
    if(!this._ds_orders_check_order_status) {
      this._ds_orders_check_order_status = this.injector.get(Dynamics365BusinessCentral_ds_orders_check_order_statusService);
    }
    return this._ds_orders_check_order_status;
  }
  private _ds_orders_count_errors_today: Dynamics365BusinessCentral_ds_orders_count_errors_todayService;
  public get ds_orders_count_errors_today(): Dynamics365BusinessCentral_ds_orders_count_errors_todayService {
    if(!this._ds_orders_count_errors_today) {
      this._ds_orders_count_errors_today = this.injector.get(Dynamics365BusinessCentral_ds_orders_count_errors_todayService);
    }
    return this._ds_orders_count_errors_today;
  }
  private _ds_orders_inbound_today: Dynamics365BusinessCentral_ds_orders_inbound_todayService;
  public get ds_orders_inbound_today(): Dynamics365BusinessCentral_ds_orders_inbound_todayService {
    if(!this._ds_orders_inbound_today) {
      this._ds_orders_inbound_today = this.injector.get(Dynamics365BusinessCentral_ds_orders_inbound_todayService);
    }
    return this._ds_orders_inbound_today;
  }
  private _ds_orders_receive_po_widget: Dynamics365BusinessCentral_ds_orders_receive_po_widgetService;
  public get ds_orders_receive_po_widget(): Dynamics365BusinessCentral_ds_orders_receive_po_widgetService {
    if(!this._ds_orders_receive_po_widget) {
      this._ds_orders_receive_po_widget = this.injector.get(Dynamics365BusinessCentral_ds_orders_receive_po_widgetService);
    }
    return this._ds_orders_receive_po_widget;
  }
  private _ds_storage_integration: Dynamics365BusinessCentral_ds_storage_integrationService;
  public get ds_storage_integration(): Dynamics365BusinessCentral_ds_storage_integrationService {
    if(!this._ds_storage_integration) {
      this._ds_storage_integration = this.injector.get(Dynamics365BusinessCentral_ds_storage_integrationService);
    }
    return this._ds_storage_integration;
  }
}


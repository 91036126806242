import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



import { Dynamics365BusinessCentral_order_po_queue_update_gridComponent } from './Dynamics365BusinessCentral.order_po_queue_update_grid.component';

interface IDynamics365BusinessCentral_order_po_queue_gridComponentEntity {
o_id?: string, bc_lookup?: string, state?: string, operation?: string, insert_date?: string, bc_update_date?: string, application_name?: string, customer?: string, wl_lookup?: string, order_type?: string, pld?: string, q_id?: string, queue_id?: string, receipt?: string, error_details?: string}



class Dynamics365BusinessCentral_order_po_queue_gridComponentRowModel extends GridRowModel {
  grid: Dynamics365BusinessCentral_order_po_queue_gridComponent;
  entity: IDynamics365BusinessCentral_order_po_queue_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    order_type: new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    operation: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    state: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    insert_date: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      ),
    bc_lookup: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    wl_lookup: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    error: new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    pld: new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }


  //#region rowExpand inParams


  get $rowExpand_Dynamics365BusinessCentral_order_po_queue_update_grid_inParams_orderid(): string {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.o_id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Dynamics365BusinessCentral_ShellService,
private datasources: Dynamics365BusinessCentral_DatasourceService,
private flows: Dynamics365BusinessCentral_FlowService,
private reports: Dynamics365BusinessCentral_ReportService,
private localization: Dynamics365BusinessCentral_LocalizationService,
private operations: Dynamics365BusinessCentral_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Dynamics365BusinessCentral_order_po_queue_gridComponent, entity: IDynamics365BusinessCentral_order_po_queue_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.o_id,this.entity.bc_lookup].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Dynamics365BusinessCentral_order_po_queue_gridComponent, entity?: IDynamics365BusinessCentral_order_po_queue_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.o_id,this.entity.bc_lookup].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'o_id',this.entity.o_id);
    set($resultKey, 'bc_lookup',this.entity.bc_lookup);
    const inParams = {
      $keys:[$resultKey],
      from:  $grid.filters.from.control.value ,
      to:  $grid.filters.to.control.value ,
    };
    const data = await this.datasources.Dynamics365BusinessCentral.ds_order_list_queue_orders_new_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.order_type.displayControl as TextModel).text = $row.entity.order_type;
    (this.cells.operation.displayControl as TextModel).text = $row.entity.operation;
    (this.cells.state.displayControl as TextModel).text = $row.entity.state;
    (this.cells.insert_date.displayControl as TextModel).text = $row.entity.insert_date;
    (this.cells.bc_lookup.displayControl as TextModel).text = $row.entity.bc_lookup;
    (this.cells.wl_lookup.displayControl as TextModel).text = $row.entity.wl_lookup;
    (this.cells.error.displayControl as TextModel).text = $row.entity.error_details;
    (this.cells.pld.displayControl as TextModel).text = $row.entity.pld;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'order_type' : {
        this.on_lookup_click();
        break;
      }
      case 'error' : {
        this.on_error_click();
        break;
      }
      case 'pld' : {
        this.on_payload_click();
        break;
      }
    }
  }

  //#region private flows
  on_payload_click(event = null) {
    return this.on_payload_clickInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_payload_clickInternal(
    $row: Dynamics365BusinessCentral_order_po_queue_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.on_payload_click');
  
  let payload_json: any;
  let payload_xml: any;
  let payload_string: string = $row.entity.pld;
  
  try {
      payload_json = JSON.parse(payload_string);
  } catch (e) {
  };
  
  if (!$utils.isDefined(payload_json)) {
      try {
          payload_string = payload_string.trim();
          if (payload_string.startsWith("<") && payload_string.endsWith(">")) {
              payload_json = $utils.parseXml(payload_string)
              if ($utils.isDefined(payload_json)) {
                  payload_xml = payload_string;
                  payload_json = null;
              };
          };
      } catch (e) {
      };
  };
  
  if (!$utils.isDefined(payload_json) && !$utils.isDefined(payload_xml)) {
      $shell.Utilities.openInfoDialog("payload", payload_string);
  };
  try {
      if ($utils.isDefined(payload_json)) {
          await $shell.Utilities.openview_json_payloadDialog({ payload: payload_json, title: "payload" }, 'flyout', EModalSize.Large);
      };
      if ($utils.isDefined(payload_xml)) {
          await $shell.Utilities.openview_xml_payloadDialog({ payload: payload_xml, title: "payload" }, 'flyout', EModalSize.Large);
      };
  } catch {
      $shell.Utilities.openInfoDialog("payload", payload_string);
  };
  }
  on_error_click(event = null) {
    return this.on_error_clickInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_error_clickInternal(
    $row: Dynamics365BusinessCentral_order_po_queue_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.on_error_click');
  
  let payload_json: any;
  let payload_xml: any;
  let payload_string: string = $row.entity.error_details
  
  try {
      payload_json = JSON.parse(payload_string);
  } catch (e) {
  };
  
  if (!$utils.isDefined(payload_json)) {
      try {
          payload_string = payload_string.trim();
          if (payload_string.startsWith("<") && payload_string.endsWith(">")) {
              payload_json = $utils.parseXml(payload_string)
              if ($utils.isDefined(payload_json)) {
                  payload_xml = payload_string;
                  payload_json = null;
              };
          };
      } catch (e) {
      };
  };
  
  if (!$utils.isDefined(payload_json) && !$utils.isDefined(payload_xml)) {
      $shell.Utilities.openInfoDialog("error", payload_string);
  };
  try {
      if ($utils.isDefined(payload_json)) {
          await $shell.Utilities.openview_json_payloadDialog({ payload: payload_json, title: "error" }, 'flyout', EModalSize.Large);
      };
      if ($utils.isDefined(payload_xml)) {
          await $shell.Utilities.openview_xml_payloadDialog({ payload: payload_xml, title: "error" }, 'flyout', EModalSize.Large);
      };
  } catch {
      $shell.Utilities.openInfoDialog("error", payload_string);
  };
  }
  on_lookup_click(event = null) {
    return this.on_lookup_clickInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookup_clickInternal(
    $row: Dynamics365BusinessCentral_order_po_queue_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.on_lookup_click');
  let application_name = $settings.Dynamics365BusinessCentral.application
  let customer = $settings.Dynamics365BusinessCentral.customer
  
  
  
  let oid=$row.entity.o_id
  {$shell.Dynamics365BusinessCentral.openorder_po_queue_update_grid({orderid:oid}), 'flyout', EModalSize.Xlarge}
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Dynamics365BusinessCentral_order_po_queue_update_gridComponent)
  ],
  selector: 'Dynamics365BusinessCentral-order_po_queue_grid',
  templateUrl: './Dynamics365BusinessCentral.order_po_queue_grid.component.html'
})
export class Dynamics365BusinessCentral_order_po_queue_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IDynamics365BusinessCentral_order_po_queue_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', ['relaxed']);

  fullTextSearch: string;



  //#region Variables
  //#endregion
  //#region Events
  @Output()
  filtersUpdated = new EventEmitter<{ from?: string, to?: string }>();
  
  events = {
    filtersUpdated: this.filtersUpdated,
  }
  //#endregion

  headers = {
     order_type: new GridHeaderModel(new HeaderStyles(['left'], null), 'Order type', false, false, null),       operation: new GridHeaderModel(new HeaderStyles(['left'], null), 'Operation', false, false, null),       state: new GridHeaderModel(new HeaderStyles(['left'], null), 'State', false, false, null),       insert_date: new GridHeaderModel(new HeaderStyles(['left'], null), 'Publish date', false, false, null),       bc_lookup: new GridHeaderModel(new HeaderStyles(['left'], null), 'BC ref', false, false, null),       wl_lookup: new GridHeaderModel(new HeaderStyles(['left'], null), 'WL ref', false, false, null),       error: new GridHeaderModel(new HeaderStyles(['left'], null), 'Error', false, false, null),       pld: new GridHeaderModel(new HeaderStyles(['left'], null), 'Payload', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Dynamics365BusinessCentral_order_po_queue_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;


  topToolbar = {
      bc: new ToolModel(new ButtonModel('bc', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_arrow_clockwise_dashes_20_filled', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      wl: new ToolModel(new ButtonModel('wl', new ButtonStyles(null, null), false, false, ' ', 'ms-Icon ms-Icon--DataFlow', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, false, ' ', 'icon-ic_fluent_dual_screen_update_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      del: new ToolModel(new ButtonModel('del', new ButtonStyles(['destructive'], null), false, false, ' ', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from: new FieldModel(new DateBoxModel(this.formGroup.controls['from'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'From', false, false),
    to: new FieldModel(new DateBoxModel(this.formGroup.controls['to'] as DatexFormControl, null, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'To', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Dynamics365BusinessCentral_ShellService,
    private datasources: Dynamics365BusinessCentral_DatasourceService,
    private flows: Dynamics365BusinessCentral_FlowService,
    private reports: Dynamics365BusinessCentral_ReportService,
    private localization: Dynamics365BusinessCentral_LocalizationService,
    private operations: Dynamics365BusinessCentral_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'order_po_queue_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 20;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['from']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.off_filter_changes();
      });
    this.formGroup
      .controls['to']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.off_filter_changes();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      from:  $grid.filters.from.control.value ,
      to:  $grid.filters.to.control.value ,
    };
    try {
    const data = await this.datasources.Dynamics365BusinessCentral.ds_order_list_queue_orders_new_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Dynamics365BusinessCentral_order_po_queue_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_click_wl(event = null) {
    return this.on_click_wlInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_click_wlInternal(
    $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.on_click_wl');
  $flows.Dynamics365BusinessCentral.flow_order_po_update_wl_grid({})
  
  }
  on_click_bc(event = null) {
    return this.on_click_bcInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_click_bcInternal(
    $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.on_click_bc');
  $flows.Dynamics365BusinessCentral.flow_order_po_update_bc_grid({})
  
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.on_delete');
  let application_name = $settings.Dynamics365BusinessCentral.application
   const selected_rows = $grid.selectedRows;
  
  let errors: { reference: string, id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  
  
  if (selected_rows.length === 0) {
      $shell.Utilities.openErrorDialog('Revert error!', 'No records were selected!');
      return;
  } else {
  
        const confirm_post = (await $shell.Utilities.openConfirmationDialog(`Confirm Delete!`, `Do you want to delete ?`, `Post`, `Cancel`));
  
      if (confirm_post) {
    
          for (const row of selected_rows) {
                  let entity = row.entity
                  let q_id = entity.q_id
                  let o_id = entity.o_id
              try {
                         
  
              await $flows.Dynamics365BusinessCentral.flow_order_queue_delete({
                      input: [{ q_id: q_id }],
                      criteria: { application_name: $settings.Dynamics365BusinessCentral.application}
                  });
             
              let i=   (await $datasources.Dynamics365BusinessCentral.ds_order_list_queue_orders_update_grid.getList({orderid:o_id})).result
              let b = i?.map(I => I.q_id)
              b.forEach(async (q_id)=> { 
  
  
                  await $flows.Dynamics365BusinessCentral.flow_order_queue_delete({
                      input: [{ q_id: q_id }],
                      criteria: { application_name: $settings.Dynamics365BusinessCentral.application}
                  });
              })}
  
                  catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
               let   current_error = target_error.message;
               $shell.Utilities.openErrorDialog('Revert error!', current_error);
              return;
              };
              };
  
              };
  
  };
  
   $grid.refresh();
  }
  off_filter_changes(event = null) {
    return this.off_filter_changesInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async off_filter_changesInternal(
    $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.off_filter_changes');
  await $grid.events.filtersUpdated.emit({
      from: $grid.filters.from.control.value,
      to: $grid.filters.to.control.value
  });
  }
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.on_refresh');
  
  
  }
  on_click_updt(event = null) {
    return this.on_click_updtInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_click_updtInternal(
    $grid: Dynamics365BusinessCentral_order_po_queue_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid.on_click_updt');
  $flows.Dynamics365BusinessCentral.flow_order_update_grid_update({})
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}

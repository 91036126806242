import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ds_get_order_transmissions_by_order_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { order_id: number }): 
  Promise<{ result: { Id?: number, DirectionId?: number, IntegrationId?: string, Notes?: string, OrderId?: number, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_get_order_transmissions_by_order_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { order_id: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, DirectionId?: number, IntegrationId?: string, Notes?: string, OrderId?: number, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_get_order_transmissions_by_order_id/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { order_id: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, DirectionId?: number, IntegrationId?: string, Notes?: string, OrderId?: number, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_get_order_transmissions_by_order_id/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      order_id: number    }) {
        if(isNil(inParams.order_id)) {
          return true; 
        }
      return false;
    }
}

import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Locations_ds_empty_locations_count_by_warehouseId_widgetService } from './Locations.datasource.index';
import { Locations_ds_get_default_warehouse_by_userService } from './Locations.datasource.index';
import { Locations_ds_get_empty_locations_by_warehouseIdService } from './Locations.datasource.index';
import { Locations_ds_get_empty_loose_locations_by_warehouseIdService } from './Locations.datasource.index';
import { Locations_ds_get_inventory_locations_by_parentIdService } from './Locations.datasource.index';
import { Locations_ds_get_licenseplate_by_licenseplateIdService } from './Locations.datasource.index';
import { Locations_ds_get_licenseplate_by_loose_locationIdService } from './Locations.datasource.index';
import { Locations_ds_get_licenseplates_by_locationIdService } from './Locations.datasource.index';
import { Locations_ds_get_licenseplates_by_lookupcode_and_warehouseIdService } from './Locations.datasource.index';
import { Locations_ds_get_licenseplates_count_by_locationIdService } from './Locations.datasource.index';
import { Locations_ds_get_location_by_locationIdService } from './Locations.datasource.index';
import { Locations_ds_get_location_project_by_locationId_projectIdService } from './Locations.datasource.index';
import { Locations_ds_get_locations_by_locationIdsService } from './Locations.datasource.index';
import { Locations_ds_get_locations_by_name_and_warehouseIdService } from './Locations.datasource.index';
import { Locations_ds_get_mixing_rulesService } from './Locations.datasource.index';
import { Locations_ds_get_project_by_projectIdService } from './Locations.datasource.index';
import { Locations_ds_get_shipment_count_by_warehouse_by_userService } from './Locations.datasource.index';
import { Locations_ds_get_status_by_statusIdService } from './Locations.datasource.index';
import { Locations_ds_get_task_count_by_warehouse_by_userService } from './Locations.datasource.index';
import { Locations_ds_get_warehouse_by_nameService } from './Locations.datasource.index';
import { Locations_ds_get_warehouse_by_warehouseIdService } from './Locations.datasource.index';
import { Locations_ds_get_warehouse_top1Service } from './Locations.datasource.index';
import { Locations_ds_get_warehouses_by_warehouseIdsService } from './Locations.datasource.index';
import { Locations_ds_get_warehouses_timezone_detailsService } from './Locations.datasource.index';
import { Locations_ds_licenseplate_editorService } from './Locations.datasource.index';
import { Locations_ds_licenseplate_statuses_ddService } from './Locations.datasource.index';
import { Locations_ds_location_editorService } from './Locations.datasource.index';
import { Locations_ds_location_editor_standardService } from './Locations.datasource.index';
import { Locations_ds_location_editor_upgradedService } from './Locations.datasource.index';
import { Locations_ds_location_projects_gridService } from './Locations.datasource.index';
import { Locations_ds_location_statuses_ddService } from './Locations.datasource.index';
import { Locations_ds_locations_by_count_widgetService } from './Locations.datasource.index';
import { Locations_ds_locations_ddService } from './Locations.datasource.index';
import { Locations_ds_locations_gridService } from './Locations.datasource.index';
import { Locations_ds_locations_navigational_gridService } from './Locations.datasource.index';
import { Locations_ds_measurement_units_ddService } from './Locations.datasource.index';
import { Locations_ds_mixing_rule_ddService } from './Locations.datasource.index';
import { Locations_ds_primary_picks_gridService } from './Locations.datasource.index';
import { Locations_ds_projects_ddService } from './Locations.datasource.index';
import { Locations_ds_storage_categories_ddService } from './Locations.datasource.index';
import { Locations_ds_warehouse_editorService } from './Locations.datasource.index';
import { Locations_ds_warehouse_types_ddService } from './Locations.datasource.index';
import { Locations_ds_warehouses_ddService } from './Locations.datasource.index';
import { Locations_ds_zones_ddService } from './Locations.datasource.index';

@Injectable({ providedIn: 'root' })
export class Locations_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Locations: Locations_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_empty_locations_count_by_warehouseId_widget: Locations_ds_empty_locations_count_by_warehouseId_widgetService;
  public get ds_empty_locations_count_by_warehouseId_widget(): Locations_ds_empty_locations_count_by_warehouseId_widgetService {
    if(!this._ds_empty_locations_count_by_warehouseId_widget) {
      this._ds_empty_locations_count_by_warehouseId_widget = this.injector.get(Locations_ds_empty_locations_count_by_warehouseId_widgetService);
    }
    return this._ds_empty_locations_count_by_warehouseId_widget;
  }
  private _ds_get_default_warehouse_by_user: Locations_ds_get_default_warehouse_by_userService;
  public get ds_get_default_warehouse_by_user(): Locations_ds_get_default_warehouse_by_userService {
    if(!this._ds_get_default_warehouse_by_user) {
      this._ds_get_default_warehouse_by_user = this.injector.get(Locations_ds_get_default_warehouse_by_userService);
    }
    return this._ds_get_default_warehouse_by_user;
  }
  private _ds_get_empty_locations_by_warehouseId: Locations_ds_get_empty_locations_by_warehouseIdService;
  public get ds_get_empty_locations_by_warehouseId(): Locations_ds_get_empty_locations_by_warehouseIdService {
    if(!this._ds_get_empty_locations_by_warehouseId) {
      this._ds_get_empty_locations_by_warehouseId = this.injector.get(Locations_ds_get_empty_locations_by_warehouseIdService);
    }
    return this._ds_get_empty_locations_by_warehouseId;
  }
  private _ds_get_empty_loose_locations_by_warehouseId: Locations_ds_get_empty_loose_locations_by_warehouseIdService;
  public get ds_get_empty_loose_locations_by_warehouseId(): Locations_ds_get_empty_loose_locations_by_warehouseIdService {
    if(!this._ds_get_empty_loose_locations_by_warehouseId) {
      this._ds_get_empty_loose_locations_by_warehouseId = this.injector.get(Locations_ds_get_empty_loose_locations_by_warehouseIdService);
    }
    return this._ds_get_empty_loose_locations_by_warehouseId;
  }
  private _ds_get_inventory_locations_by_parentId: Locations_ds_get_inventory_locations_by_parentIdService;
  public get ds_get_inventory_locations_by_parentId(): Locations_ds_get_inventory_locations_by_parentIdService {
    if(!this._ds_get_inventory_locations_by_parentId) {
      this._ds_get_inventory_locations_by_parentId = this.injector.get(Locations_ds_get_inventory_locations_by_parentIdService);
    }
    return this._ds_get_inventory_locations_by_parentId;
  }
  private _ds_get_licenseplate_by_licenseplateId: Locations_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): Locations_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(Locations_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_licenseplate_by_loose_locationId: Locations_ds_get_licenseplate_by_loose_locationIdService;
  public get ds_get_licenseplate_by_loose_locationId(): Locations_ds_get_licenseplate_by_loose_locationIdService {
    if(!this._ds_get_licenseplate_by_loose_locationId) {
      this._ds_get_licenseplate_by_loose_locationId = this.injector.get(Locations_ds_get_licenseplate_by_loose_locationIdService);
    }
    return this._ds_get_licenseplate_by_loose_locationId;
  }
  private _ds_get_licenseplates_by_locationId: Locations_ds_get_licenseplates_by_locationIdService;
  public get ds_get_licenseplates_by_locationId(): Locations_ds_get_licenseplates_by_locationIdService {
    if(!this._ds_get_licenseplates_by_locationId) {
      this._ds_get_licenseplates_by_locationId = this.injector.get(Locations_ds_get_licenseplates_by_locationIdService);
    }
    return this._ds_get_licenseplates_by_locationId;
  }
  private _ds_get_licenseplates_by_lookupcode_and_warehouseId: Locations_ds_get_licenseplates_by_lookupcode_and_warehouseIdService;
  public get ds_get_licenseplates_by_lookupcode_and_warehouseId(): Locations_ds_get_licenseplates_by_lookupcode_and_warehouseIdService {
    if(!this._ds_get_licenseplates_by_lookupcode_and_warehouseId) {
      this._ds_get_licenseplates_by_lookupcode_and_warehouseId = this.injector.get(Locations_ds_get_licenseplates_by_lookupcode_and_warehouseIdService);
    }
    return this._ds_get_licenseplates_by_lookupcode_and_warehouseId;
  }
  private _ds_get_licenseplates_count_by_locationId: Locations_ds_get_licenseplates_count_by_locationIdService;
  public get ds_get_licenseplates_count_by_locationId(): Locations_ds_get_licenseplates_count_by_locationIdService {
    if(!this._ds_get_licenseplates_count_by_locationId) {
      this._ds_get_licenseplates_count_by_locationId = this.injector.get(Locations_ds_get_licenseplates_count_by_locationIdService);
    }
    return this._ds_get_licenseplates_count_by_locationId;
  }
  private _ds_get_location_by_locationId: Locations_ds_get_location_by_locationIdService;
  public get ds_get_location_by_locationId(): Locations_ds_get_location_by_locationIdService {
    if(!this._ds_get_location_by_locationId) {
      this._ds_get_location_by_locationId = this.injector.get(Locations_ds_get_location_by_locationIdService);
    }
    return this._ds_get_location_by_locationId;
  }
  private _ds_get_location_project_by_locationId_projectId: Locations_ds_get_location_project_by_locationId_projectIdService;
  public get ds_get_location_project_by_locationId_projectId(): Locations_ds_get_location_project_by_locationId_projectIdService {
    if(!this._ds_get_location_project_by_locationId_projectId) {
      this._ds_get_location_project_by_locationId_projectId = this.injector.get(Locations_ds_get_location_project_by_locationId_projectIdService);
    }
    return this._ds_get_location_project_by_locationId_projectId;
  }
  private _ds_get_locations_by_locationIds: Locations_ds_get_locations_by_locationIdsService;
  public get ds_get_locations_by_locationIds(): Locations_ds_get_locations_by_locationIdsService {
    if(!this._ds_get_locations_by_locationIds) {
      this._ds_get_locations_by_locationIds = this.injector.get(Locations_ds_get_locations_by_locationIdsService);
    }
    return this._ds_get_locations_by_locationIds;
  }
  private _ds_get_locations_by_name_and_warehouseId: Locations_ds_get_locations_by_name_and_warehouseIdService;
  public get ds_get_locations_by_name_and_warehouseId(): Locations_ds_get_locations_by_name_and_warehouseIdService {
    if(!this._ds_get_locations_by_name_and_warehouseId) {
      this._ds_get_locations_by_name_and_warehouseId = this.injector.get(Locations_ds_get_locations_by_name_and_warehouseIdService);
    }
    return this._ds_get_locations_by_name_and_warehouseId;
  }
  private _ds_get_mixing_rules: Locations_ds_get_mixing_rulesService;
  public get ds_get_mixing_rules(): Locations_ds_get_mixing_rulesService {
    if(!this._ds_get_mixing_rules) {
      this._ds_get_mixing_rules = this.injector.get(Locations_ds_get_mixing_rulesService);
    }
    return this._ds_get_mixing_rules;
  }
  private _ds_get_project_by_projectId: Locations_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): Locations_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(Locations_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_shipment_count_by_warehouse_by_user: Locations_ds_get_shipment_count_by_warehouse_by_userService;
  public get ds_get_shipment_count_by_warehouse_by_user(): Locations_ds_get_shipment_count_by_warehouse_by_userService {
    if(!this._ds_get_shipment_count_by_warehouse_by_user) {
      this._ds_get_shipment_count_by_warehouse_by_user = this.injector.get(Locations_ds_get_shipment_count_by_warehouse_by_userService);
    }
    return this._ds_get_shipment_count_by_warehouse_by_user;
  }
  private _ds_get_status_by_statusId: Locations_ds_get_status_by_statusIdService;
  public get ds_get_status_by_statusId(): Locations_ds_get_status_by_statusIdService {
    if(!this._ds_get_status_by_statusId) {
      this._ds_get_status_by_statusId = this.injector.get(Locations_ds_get_status_by_statusIdService);
    }
    return this._ds_get_status_by_statusId;
  }
  private _ds_get_task_count_by_warehouse_by_user: Locations_ds_get_task_count_by_warehouse_by_userService;
  public get ds_get_task_count_by_warehouse_by_user(): Locations_ds_get_task_count_by_warehouse_by_userService {
    if(!this._ds_get_task_count_by_warehouse_by_user) {
      this._ds_get_task_count_by_warehouse_by_user = this.injector.get(Locations_ds_get_task_count_by_warehouse_by_userService);
    }
    return this._ds_get_task_count_by_warehouse_by_user;
  }
  private _ds_get_warehouse_by_name: Locations_ds_get_warehouse_by_nameService;
  public get ds_get_warehouse_by_name(): Locations_ds_get_warehouse_by_nameService {
    if(!this._ds_get_warehouse_by_name) {
      this._ds_get_warehouse_by_name = this.injector.get(Locations_ds_get_warehouse_by_nameService);
    }
    return this._ds_get_warehouse_by_name;
  }
  private _ds_get_warehouse_by_warehouseId: Locations_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): Locations_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(Locations_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_get_warehouse_top1: Locations_ds_get_warehouse_top1Service;
  public get ds_get_warehouse_top1(): Locations_ds_get_warehouse_top1Service {
    if(!this._ds_get_warehouse_top1) {
      this._ds_get_warehouse_top1 = this.injector.get(Locations_ds_get_warehouse_top1Service);
    }
    return this._ds_get_warehouse_top1;
  }
  private _ds_get_warehouses_by_warehouseIds: Locations_ds_get_warehouses_by_warehouseIdsService;
  public get ds_get_warehouses_by_warehouseIds(): Locations_ds_get_warehouses_by_warehouseIdsService {
    if(!this._ds_get_warehouses_by_warehouseIds) {
      this._ds_get_warehouses_by_warehouseIds = this.injector.get(Locations_ds_get_warehouses_by_warehouseIdsService);
    }
    return this._ds_get_warehouses_by_warehouseIds;
  }
  private _ds_get_warehouses_timezone_details: Locations_ds_get_warehouses_timezone_detailsService;
  public get ds_get_warehouses_timezone_details(): Locations_ds_get_warehouses_timezone_detailsService {
    if(!this._ds_get_warehouses_timezone_details) {
      this._ds_get_warehouses_timezone_details = this.injector.get(Locations_ds_get_warehouses_timezone_detailsService);
    }
    return this._ds_get_warehouses_timezone_details;
  }
  private _ds_licenseplate_editor: Locations_ds_licenseplate_editorService;
  public get ds_licenseplate_editor(): Locations_ds_licenseplate_editorService {
    if(!this._ds_licenseplate_editor) {
      this._ds_licenseplate_editor = this.injector.get(Locations_ds_licenseplate_editorService);
    }
    return this._ds_licenseplate_editor;
  }
  private _ds_licenseplate_statuses_dd: Locations_ds_licenseplate_statuses_ddService;
  public get ds_licenseplate_statuses_dd(): Locations_ds_licenseplate_statuses_ddService {
    if(!this._ds_licenseplate_statuses_dd) {
      this._ds_licenseplate_statuses_dd = this.injector.get(Locations_ds_licenseplate_statuses_ddService);
    }
    return this._ds_licenseplate_statuses_dd;
  }
  private _ds_location_editor: Locations_ds_location_editorService;
  public get ds_location_editor(): Locations_ds_location_editorService {
    if(!this._ds_location_editor) {
      this._ds_location_editor = this.injector.get(Locations_ds_location_editorService);
    }
    return this._ds_location_editor;
  }
  private _ds_location_editor_standard: Locations_ds_location_editor_standardService;
  public get ds_location_editor_standard(): Locations_ds_location_editor_standardService {
    if(!this._ds_location_editor_standard) {
      this._ds_location_editor_standard = this.injector.get(Locations_ds_location_editor_standardService);
    }
    return this._ds_location_editor_standard;
  }
  private _ds_location_editor_upgraded: Locations_ds_location_editor_upgradedService;
  public get ds_location_editor_upgraded(): Locations_ds_location_editor_upgradedService {
    if(!this._ds_location_editor_upgraded) {
      this._ds_location_editor_upgraded = this.injector.get(Locations_ds_location_editor_upgradedService);
    }
    return this._ds_location_editor_upgraded;
  }
  private _ds_location_projects_grid: Locations_ds_location_projects_gridService;
  public get ds_location_projects_grid(): Locations_ds_location_projects_gridService {
    if(!this._ds_location_projects_grid) {
      this._ds_location_projects_grid = this.injector.get(Locations_ds_location_projects_gridService);
    }
    return this._ds_location_projects_grid;
  }
  private _ds_location_statuses_dd: Locations_ds_location_statuses_ddService;
  public get ds_location_statuses_dd(): Locations_ds_location_statuses_ddService {
    if(!this._ds_location_statuses_dd) {
      this._ds_location_statuses_dd = this.injector.get(Locations_ds_location_statuses_ddService);
    }
    return this._ds_location_statuses_dd;
  }
  private _ds_locations_by_count_widget: Locations_ds_locations_by_count_widgetService;
  public get ds_locations_by_count_widget(): Locations_ds_locations_by_count_widgetService {
    if(!this._ds_locations_by_count_widget) {
      this._ds_locations_by_count_widget = this.injector.get(Locations_ds_locations_by_count_widgetService);
    }
    return this._ds_locations_by_count_widget;
  }
  private _ds_locations_dd: Locations_ds_locations_ddService;
  public get ds_locations_dd(): Locations_ds_locations_ddService {
    if(!this._ds_locations_dd) {
      this._ds_locations_dd = this.injector.get(Locations_ds_locations_ddService);
    }
    return this._ds_locations_dd;
  }
  private _ds_locations_grid: Locations_ds_locations_gridService;
  public get ds_locations_grid(): Locations_ds_locations_gridService {
    if(!this._ds_locations_grid) {
      this._ds_locations_grid = this.injector.get(Locations_ds_locations_gridService);
    }
    return this._ds_locations_grid;
  }
  private _ds_locations_navigational_grid: Locations_ds_locations_navigational_gridService;
  public get ds_locations_navigational_grid(): Locations_ds_locations_navigational_gridService {
    if(!this._ds_locations_navigational_grid) {
      this._ds_locations_navigational_grid = this.injector.get(Locations_ds_locations_navigational_gridService);
    }
    return this._ds_locations_navigational_grid;
  }
  private _ds_measurement_units_dd: Locations_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): Locations_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(Locations_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_mixing_rule_dd: Locations_ds_mixing_rule_ddService;
  public get ds_mixing_rule_dd(): Locations_ds_mixing_rule_ddService {
    if(!this._ds_mixing_rule_dd) {
      this._ds_mixing_rule_dd = this.injector.get(Locations_ds_mixing_rule_ddService);
    }
    return this._ds_mixing_rule_dd;
  }
  private _ds_primary_picks_grid: Locations_ds_primary_picks_gridService;
  public get ds_primary_picks_grid(): Locations_ds_primary_picks_gridService {
    if(!this._ds_primary_picks_grid) {
      this._ds_primary_picks_grid = this.injector.get(Locations_ds_primary_picks_gridService);
    }
    return this._ds_primary_picks_grid;
  }
  private _ds_projects_dd: Locations_ds_projects_ddService;
  public get ds_projects_dd(): Locations_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Locations_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_storage_categories_dd: Locations_ds_storage_categories_ddService;
  public get ds_storage_categories_dd(): Locations_ds_storage_categories_ddService {
    if(!this._ds_storage_categories_dd) {
      this._ds_storage_categories_dd = this.injector.get(Locations_ds_storage_categories_ddService);
    }
    return this._ds_storage_categories_dd;
  }
  private _ds_warehouse_editor: Locations_ds_warehouse_editorService;
  public get ds_warehouse_editor(): Locations_ds_warehouse_editorService {
    if(!this._ds_warehouse_editor) {
      this._ds_warehouse_editor = this.injector.get(Locations_ds_warehouse_editorService);
    }
    return this._ds_warehouse_editor;
  }
  private _ds_warehouse_types_dd: Locations_ds_warehouse_types_ddService;
  public get ds_warehouse_types_dd(): Locations_ds_warehouse_types_ddService {
    if(!this._ds_warehouse_types_dd) {
      this._ds_warehouse_types_dd = this.injector.get(Locations_ds_warehouse_types_ddService);
    }
    return this._ds_warehouse_types_dd;
  }
  private _ds_warehouses_dd: Locations_ds_warehouses_ddService;
  public get ds_warehouses_dd(): Locations_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(Locations_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
  private _ds_zones_dd: Locations_ds_zones_ddService;
  public get ds_zones_dd(): Locations_ds_zones_ddService {
    if(!this._ds_zones_dd) {
      this._ds_zones_dd = this.injector.get(Locations_ds_zones_ddService);
    }
    return this._ds_zones_dd;
  }
}


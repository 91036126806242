import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_master_bol_report_bol_details_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { load_container_id: number }): Promise<{ result?: { results?: { bol_header?: { Id?: number, CreatedSysUser?: string, LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { BillOfLading?: string, GrossWeight?: number, ItemCount?: number, PalletCount?: number, SealId?: string, ShippedDate?: string, TrackingIdentifier?: string, TrailerId?: string } }[], PreferredCarrier?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] }, Project?: { Name?: string, ProjectsContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ShipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }, bol_details?: { ShipmentId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, LotLookupCode?: string, MaterialLookupCode?: string, MaterialDescription?: string, NetWeight?: number, GrossWeight?: number, WeightUomId?: number, OrderId?: number, LicensePlateLookupCode?: string, WeightUom?: string, Uom?: string, SerialCount?: number, IsFixedWeight?: boolean }[], bol_Signature?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number, FileContent?: string } }[] } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.load_container_id)) {
      missingRequiredInParams.push('\'load_container_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/master_bol_report_bol_details_flow/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      load_container_id: number    }) {
        if(isNil(inParams.load_container_id)) {
          return true; 
        }
      return false;
    }
}

import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';

interface ISalesOrders_master_bol_reportServiceInParams {
  load_container_id: number}

interface ISalesOrders_master_bol_reportServiceData {
  Header_master?: { result?: { Id?: number, LookupCode?: string, TrailerNumber?: string, Shipments?: { Id?: number, LoadContainerSequence?: number, SealId?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string } }[], ExpectedWarehouse?: { Name?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ActualWarehouse?: { Name?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, WarehousesContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }[], Carrier?: { Name?: string }, warehouse_name?: string, warehouse_line1?: string, warehouse_city?: string, load_container_lookup?: string, carrier?: string, trailer_number?: string, warehouse_state?: string, warehouse_postal_code?: string }[] }
  Details_master?: { result?: { results?: { pallet_count?: number, total_quantity?: number, load_container_sequence?: number, order_lookupcode?: string, order_owner_reference?: string, seal_number?: string, order_vendor_reference?: string, ship_to_acocunt_name?: string, ship_to_address_line1?: string, ship_to_city_state_postal_code?: string, total_gross_weight?: number, row_count?: number }[] } }
  Individual_bol?: { result?: { results?: { bol_header?: { Id?: number, CreatedSysUser?: string, LookupCode?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { BillOfLading?: string, GrossWeight?: number, ItemCount?: number, PalletCount?: number, SealId?: string, ShippedDate?: string, TrackingIdentifier?: string, TrailerId?: string } }[], PreferredCarrier?: { Name?: string }, PreferredWarehouse?: { Name?: string, WarehousesContactsLookup?: { ContactId?: number, Contact?: { PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] }, Project?: { Name?: string, ProjectsContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, ShipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }, bol_details?: { ShipmentId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, LotLookupCode?: string, MaterialLookupCode?: string, MaterialDescription?: string, NetWeight?: number, GrossWeight?: number, WeightUomId?: number, OrderId?: number, LicensePlateLookupCode?: string, WeightUom?: string, Uom?: string, SerialCount?: number, IsFixedWeight?: boolean }[], bol_Signature?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number, FileContent?: string } }[] } }
}

@Injectable({ providedIn: 'root' })
export class SalesOrders_master_bol_reportService extends ReportBaseService<ISalesOrders_master_bol_reportServiceInParams, ISalesOrders_master_bol_reportServiceData> {

  protected reportReferenceName = 'master_bol_report';
  protected appReferenceName = 'SalesOrders';

  constructor(
    utils: UtilsService,
    private datasources: SalesOrders_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('SalesOrders', 'master_bol_report');
  }

  override throwIfMissingRequiredInParams (inParams: ISalesOrders_master_bol_reportServiceInParams) {
  }

  protected async getData(inParams: ISalesOrders_master_bol_reportServiceInParams): Promise<ISalesOrders_master_bol_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: ISalesOrders_master_bol_reportServiceInParams } = {
      inParams: inParams
    };

    const data: ISalesOrders_master_bol_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        load_container_id:  $report.inParams.load_container_id 
      };
      
      const dsData = await this.datasources.SalesOrders.master_bol_report_header.get(dsParams);
      
      data.Header_master = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        load_container_id:  $report.inParams.load_container_id 
      };
      
      const dsData = await this.datasources.SalesOrders.master_bol_report_details_flow.get(dsParams);
      
      data.Details_master = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        load_container_id:  $report.inParams.load_container_id 
      };
      
      const dsData = await this.datasources.SalesOrders.master_bol_report_bol_details_flow.get(dsParams);
      
      data.Individual_bol = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}

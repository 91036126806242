import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, NgZone } from '@angular/core';
import { Subject, firstValueFrom, tap } from 'rxjs';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { FontLoader, Font } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

export type GLTFModel = { [key: string]: any };
export type Object3D = THREE.Object3D;
export type Vector3 = THREE.Vector3;
export type Mesh = THREE.Mesh;
export type MeshBasicMaterial = THREE.MeshBasicMaterial;
export type Material = THREE.Material;
export type BoxGeometry = THREE.BoxGeometry;
export type MeshBasicMaterialParameters = THREE.MeshBasicMaterialParameters;
export type Box3 = THREE.Box3;
export type Color = THREE.Color;

export interface IVisVector3 {
  x: number;
  y: number;
  z: number;
}

export interface IVisColor {
  r: number;
  g: number;
  b: number;
}

export interface IVisMaterial {
  color: IVisColor;
  opacity: number;
}

export interface IVisObject {
  Id: number;
  Name: string;
}

export interface IVisDockdoor extends IVisObject {
}

export interface IVisZone extends IVisObject {
  Length: number;
  Width: number;
}

export interface IVisLocation extends IVisObject {
  parent?: IVisZone;
  Length: number;
  Width: number;
  Height: number;
  ChildFootprint: number;
  ChildStackHeight: number;
}

export interface IObjectChange {
  sceneObject: Object3D;
  dataItem: any;
}

export interface IWarehouseSceneSettings {
  uomId?: number;
  fontId?: number;
  precision?: number;
  zones: IZoneSettings;
  locations: ILocationSettings;
  dockdoors: IDockDoorSettings;

  cmToM(): void;
  ftToM(): void;
  inToM(): void;
  mToCm(): void;
  mToFt(): void;
  mToIn(): void;

  update(settings: IWarehouseSceneSettings): void;
}

export interface IZoneSettings {
  cmToM(): void;
  ftToM(): void;
  inToM(): void;
  mToCm(precision: number): void;
  mToFt(precision: number): void;
  mToIn(precision: number): void;

  update(settings: IZoneSettings): void;
}

export interface ILocationSettings {
  cmToM(): void;
  ftToM(): void;
  inToM(): void;
  mToCm(precision: number): void;
  mToFt(precision: number): void;
  mToIn(precision: number): void;

  update(settings: ILocationSettings): void;
}

export interface IDockDoorSettings {
  width?: number;
  height?: number;
  groundClearance?: number;
  distanceBetween?: number;
  depth?: number;
  creationColor?: number;

  cmToM(): void;
  ftToM(): void;
  inToM(): void;
  mToCm(precision: number): void;
  mToFt(precision: number): void;
  mToIn(precision: number): void;

  update(settings: IDockDoorSettings): void;
}

export class WarehouseSceneSettings implements IWarehouseSceneSettings {

  public static DEFAULT_FONT_ID = 1;
  public static DEFAULT_PRECISION = 1;

  uomId: number;
  fontId: number;
  precision: number;
  zones: IZoneSettings;
  locations: ILocationSettings;
  dockdoors: IDockDoorSettings;

  constructor(settings?: IWarehouseSceneSettings) {
    this.fontId = settings?.fontId ?? WarehouseSceneSettings.DEFAULT_FONT_ID;
    this.precision = settings?.precision ?? WarehouseSceneSettings.DEFAULT_PRECISION;
    this.zones = new ZoneSettings(settings?.zones);
    this.locations = new LocationSettings(settings?.locations);
    this.dockdoors = new DockDoorSettings(settings?.dockdoors);
  }

  update(settings?: IWarehouseSceneSettings) {
    this.fontId = settings?.fontId ?? WarehouseSceneSettings.DEFAULT_FONT_ID;
    this.precision = settings?.precision ?? WarehouseSceneSettings.DEFAULT_FONT_ID;
    this.zones.update(settings?.zones);
    this.locations.update(settings?.locations);
    this.dockdoors.update(settings?.dockdoors);
  }

  cmToM(): void {
    this.zones.cmToM();
    this.locations.cmToM();
    this.dockdoors.cmToM();
  }

  ftToM(): void {
    this.zones.ftToM();
    this.locations.ftToM();
    this.dockdoors.ftToM();
  }

  inToM(): void {
    this.zones.inToM();
    this.locations.inToM();
    this.dockdoors.inToM();
  }

  mToCm(): void {
    this.zones.mToCm(this.precision);
    this.locations.mToCm(this.precision);
    this.dockdoors.mToCm(this.precision);
  }

  mToFt(): void {
    this.zones.mToFt(this.precision);
    this.locations.mToFt(this.precision);
    this.dockdoors.mToFt(this.precision);
  }

  mToIn(): void {
    this.zones.mToIn(this.precision);
    this.locations.mToIn(this.precision);
    this.dockdoors.mToIn(this.precision);
  }
}

export class ZoneSettings implements IZoneSettings {
  constructor(settings?: IZoneSettings) {
    this.update(settings);
  }

  update(settings?: IZoneSettings): void {
  }

  cmToM(): void { }
  ftToM(): void { }
  inToM(): void { }

  mToCm(): void { }
  mToFt(): void { }
  mToIn(): void { }
}

export class LocationSettings implements ILocationSettings {
  constructor(settings?: ILocationSettings) {
    this.update(settings);
  }

  update(settings?: ILocationSettings): void {
  }

  cmToM(): void { }
  ftToM(): void { }
  inToM(): void { }

  mToCm(): void { }
  mToFt(): void { }
  mToIn(): void { }
}

export class DockDoorSettings implements IDockDoorSettings {

  // all values are stored internally in METERS within the model under scene.extras
  private static WIDTH = 2.4390243902439024;
  private static HEIGHT = 2.7439024390243905;
  private static GROUND_CLEARANCE = 0.15240030480060962;
  private static DISTANCE_BETWEEN = 3.048780487804878;
  private static DEPTH = 0.3048780487804878;
  private static CREATED_COLOR = 0x404040;

  width: number;
  height: number;
  groundClearance: number;
  distanceBetween: number;
  depth: number;
  creationColor: number;

  constructor(settings?: IDockDoorSettings) {
    this.update(settings);
  }

  update(settings: IDockDoorSettings): void {
    this.width = settings?.width ?? DockDoorSettings.WIDTH;
    this.height = settings?.height ?? DockDoorSettings.HEIGHT;
    this.groundClearance = settings?.groundClearance ?? DockDoorSettings.GROUND_CLEARANCE;
    this.distanceBetween = settings?.distanceBetween ?? DockDoorSettings.DISTANCE_BETWEEN;
    this.depth = settings?.depth ?? DockDoorSettings.DEPTH;
    this.creationColor = settings?.creationColor ?? DockDoorSettings.CREATED_COLOR;
  }

  cmToM() {
    this.width = WarehouseVisualizationService.cmToM(this.width);
    this.height = WarehouseVisualizationService.cmToM(this.height);
    this.groundClearance = WarehouseVisualizationService.cmToM(this.groundClearance);
    this.distanceBetween = WarehouseVisualizationService.cmToM(this.distanceBetween);
    this.depth = WarehouseVisualizationService.cmToM(this.depth);
  }

  ftToM() {
    this.width = WarehouseVisualizationService.ftToM(this.width);
    this.height = WarehouseVisualizationService.ftToM(this.height);
    this.groundClearance = WarehouseVisualizationService.ftToM(this.groundClearance);
    this.distanceBetween = WarehouseVisualizationService.ftToM(this.distanceBetween);
    this.depth = WarehouseVisualizationService.ftToM(this.depth);
  }

  inToM() {
    this.width = WarehouseVisualizationService.inToM(this.width);
    this.height = WarehouseVisualizationService.inToM(this.height);
    this.groundClearance = WarehouseVisualizationService.inToM(this.groundClearance);
    this.distanceBetween = WarehouseVisualizationService.inToM(this.distanceBetween);
    this.depth = WarehouseVisualizationService.inToM(this.depth);
  }

  mToCm(precision: number) {
    this.width = WarehouseVisualizationService.mToCm(this.width, precision);
    this.height = WarehouseVisualizationService.mToCm(this.height, precision);
    this.groundClearance = WarehouseVisualizationService.mToCm(this.groundClearance, precision);
    this.distanceBetween = WarehouseVisualizationService.mToCm(this.distanceBetween, precision);
    this.depth = WarehouseVisualizationService.mToCm(this.depth, precision);
  }

  mToFt(precision: number) {
    this.width = WarehouseVisualizationService.mToFt(this.width, precision);
    this.height = WarehouseVisualizationService.mToFt(this.height, precision);
    this.groundClearance = WarehouseVisualizationService.mToFt(this.groundClearance, precision);
    this.distanceBetween = WarehouseVisualizationService.mToFt(this.distanceBetween, precision);
    this.depth = WarehouseVisualizationService.mToFt(this.depth, precision);
  }

  mToIn(precision: number) {
    this.width = WarehouseVisualizationService.mToIn(this.width, precision);
    this.height = WarehouseVisualizationService.mToIn(this.height, precision);
    this.groundClearance = WarehouseVisualizationService.mToIn(this.groundClearance, precision);
    this.distanceBetween = WarehouseVisualizationService.mToIn(this.distanceBetween, precision);
    this.depth = WarehouseVisualizationService.mToIn(this.depth, precision);
  }
}

export abstract class Scene3D {

  protected scene: THREE.Scene;
  protected canvas: HTMLCanvasElement;
  protected renderer: THREE.WebGLRenderer;

  protected lastSelected: Object3D = undefined;
  protected pickableObjects: Object3D[] = [];
  protected camera: THREE.PerspectiveCamera;
  protected drag: DragControls;
  protected orbit: OrbitControls;
  protected frameId: number = null;

  protected changeSubject = new Subject<any>();

  onChange = this.changeSubject.asObservable();

  private prevTime = performance.now();
  private frames = 0;
  private resizeRegistered = false;

  private fonts: Font3D[] = [];

  protected static async init(scene: Scene3D, model: string, canvas: ElementRef<HTMLCanvasElement>, ngZone: NgZone): Promise<Scene3D> {
    scene.canvas = canvas.nativeElement;

    const height = scene.canvas.clientHeight;
    const width = scene.canvas.clientWidth;

    // Initialize 3D renderer
    scene.renderer = new THREE.WebGLRenderer({
      canvas: scene.canvas,
      alpha: true,    // transparent background
      antialias: true // smooth edges
    });

    scene.renderer.setSize(width, height);

    // create the scene
    scene.scene = new THREE.Scene();
    scene.scene.background = new THREE.Color(0xF0F0F0);

    scene.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    scene.camera.position.y = 20;
    scene.camera.position.z = 65;
    scene.scene.add(scene.camera);

    scene.orbit = new OrbitControls(scene.camera, scene.renderer.domElement);
    scene.drag = new DragControls(scene.pickableObjects, scene.camera, scene.renderer.domElement);

    scene.drag.recursive = false;

    scene.drag.addEventListener('hoveron', () => scene.orbit.enabled = false);
    scene.drag.addEventListener('hoveroff', () => scene.orbit.enabled = true);

    scene.drag.addEventListener('dragstart', (event) => {
      scene.orbit.enabled = false;
      scene.onDragStart(event);
    });

    scene.drag.addEventListener('dragend', (event) => {
      scene.onDragEnd(event);
      scene.orbit.enabled = true;
    });

    scene.drag.enabled = true;

    if (model) {
      await scene.loadModel(model);
      scene.loadSettings();

      // build font list
      scene.fonts.push(...VisualizationService.fonts);
    }

    scene.initialize();

    return scene;
  }

  abstract onDragStart(event: any);
  abstract onDragEnd(event: any);

  protected constructor(private ngZone: NgZone) {
  }

  dispose() {
    if (this.frameId != null) { cancelAnimationFrame(this.frameId); }
    if (this.resizeRegistered) { window.removeEventListener('resize', this.onResize); }

    this.renderer.clear();
    this.renderer.dispose();
    this.renderer = null;
    this.canvas = null;
  }

  protected loadSettings() {
  }

  private registerOnResizeEvent() {
    window.addEventListener('resize', this.onResize.bind(this));
    this.resizeRegistered = true;
  }

  private initialize() {
    this.onResize();
    this.animate3D();
  }

  private onResize() {
    if (this.canvas) {
      const width = this.canvas.parentElement.clientWidth;
      const height = this.canvas.parentElement.clientHeight;

      if (this.camera) {
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
      }

      if (this.renderer) { this.renderer.setSize(width, height); }
    }
  }

  protected getAllObjects(): Object3D[] {
    const sceneObjects: Object3D[] = [];
    this.scene.traverse(o => sceneObjects.push(o));
    return sceneObjects;
  }

  getAllObjectsByTypes(types: string[]): Object3D[] {
    const sceneObjects: THREE.Object3D[] = [];

    this.scene.traverse(o => {
      if (types.includes(o?.userData?.type)) { sceneObjects.push(o); }
    });

    return sceneObjects;
  }

  getObjectsByType(allObjects: Object3D[], type: any): Object3D[] {
    const sceneObjects = allObjects || this.getAllObjects();
    const results: Object3D[] = [];

    sceneObjects.forEach(o => {
      if (o?.userData?.type === type) { results.push(o); }
    });

    return results;
  }

  getObjectByIdAndType(allObjects: Object3D[], objectId: number, type: any): Object3D {
    const sceneObjects = allObjects || this.getAllObjects();
    let result: THREE.Object3D;

    sceneObjects.forEach(o => {
      if (!result && o?.userData?.type === type && o?.userData?.properties?.Id === objectId) { result = o; }
    });

    return result;
  }

  setTextVisibilityByType(type: any, visible: boolean = true) {
    this.scene.traverse(obj => {
      const textGeometryChildren = obj.children.filter(child =>
        child instanceof THREE.Mesh && child.geometry instanceof TextGeometry && child.userData.type === type);

      // Remove each TextGeometry child from the scene
      textGeometryChildren.forEach(child => child.visible = visible);
    });
  }

  private removeTextByType(type: any) {
    this.scene.traverse(obj => {
      const textGeometryChildren = obj.children.filter(child =>
        child instanceof THREE.Mesh && child.geometry instanceof TextGeometry && child.userData.type === type);

      // Remove each TextGeometry child from the scene
      textGeometryChildren.forEach(child => {
        obj.remove(child);  // Remove from the parent
        this.scene.remove(child);   // Ensure it's removed from the scene
      });
    });
  }

  private animate3D() {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }

      this.registerOnResizeEvent();
    });
  }

  private render() {
    this.frameId = requestAnimationFrame(() => this.render());
    if (this.renderer) { this.renderer.render(this.scene, this.camera); }
  }

  detectChanges(objects: Object3D[], data: any[], changes: any[], fields: string[], type: string) {
    data.forEach(r => {
      if (!r.Id) { return; }

      const sceneObject = this.getObjectByIdAndType(objects, r.Id, type);
      if (!sceneObject || this.userDataDiff(fields, sceneObject.userData.properties, r)) {
        changes.push({
          sceneObject,
          dataItem: r
        } as IObjectChange);
      }
    });
  }

  private userDataDiff(fields: string[], obj1: any, obj2: any): boolean {
    let result = false;

    fields.forEach(f => {
      if (result) { return; }

      if (obj1[f] !== obj2[f]) {
        result = true;
      }
    });

    return result;
  }

  protected async createTextForObject(fontId: number, obj: THREE.Object3D, name: string, options: any | undefined): Promise<Object3D> {
    const textMesh = new THREE.Mesh(
      new TextGeometry(name, {
        font: (await this.getFontById(fontId)).font,
        size: 0.1,
        depth: 0.0001,
        bevelEnabled: false,
        curveSegments: 1,
        steps: 1
      }),
      new THREE.MeshBasicMaterial({ color: 0x000000 }));

    if (options?.transform) {
      textMesh.position.copy(options.transform);
    }

    textMesh.userData = { type: `${obj.userData.type}-label` };
    obj.add(textMesh);

    return textMesh;
  }

  getFontById = async (id: number): Promise<Font3D> => {
    const result = this.fonts.find(f => f.id === id);
    if (!result.font) { result.font = await this.loadFont(result); }
    return result;
  }

  dropEphemeralObjects() {
    this.removeTextByType('zone-label');
    this.removeTextByType('location-label');
    this.removeTextByType('dockdoor-label');
  }

  setEphemeralObjectsVisibility(visible: boolean) {
    this.setTextVisibilityByType('zone-label', visible);
    this.setTextVisibilityByType('location-label', visible);
    this.setTextVisibilityByType('dockdoor-label', visible);
  }

  addToScene = (...objects: THREE.Object3D[]) => this.scene.add(...objects);

  addToPickableObjects = (...objs: THREE.Object3D[]) => this.pickableObjects.push(...objs);

  exportModel = async (): Promise<GLTFModel> => {
    this.setEphemeralObjectsVisibility(false);
    const model = await new GLTFExporter().parseAsync(this.scene, { binary: false, embedImages: false, onlyVisible: true });
    this.setEphemeralObjectsVisibility(true);
    return model;
  }

  getMeshFromObject3D = (obj: Object3D): Mesh => {
    let mesh = null;

    // Traverse the object to find the first Mesh
    obj.traverse((child) => {
      if (child instanceof THREE.Mesh && mesh === null) {
        mesh = child;  // Assign the first found mesh to foundMesh
      }
    });

    return mesh; // Return the found mesh, or null if none is found
  }

  createMaterial = (color: Color, opacity: number, transparent: boolean = true): Material => {
    return new THREE.MeshBasicMaterial({ color, opacity, transparent });
  }

  createColor = (r: number, g: number, b: number): Color => {
    const color: THREE.Color = new THREE.Color();
    color.setRGB(r, g, b);
    return color;
  }

  setMeshMaterial = (mesh: Mesh, material: Material) => {
    mesh.material = material;
  }

  isMesh = (obj: Object3D): boolean => obj instanceof THREE.Mesh;

  private loadModel = async (model: string) => this.scene.add((await new GLTFLoader().parseAsync(model, '')).scene);

  private loadFont = (font: Font3D): Promise<Font> =>
    new Promise((resolve, reject) =>
      new FontLoader().load(`./assets/visualization/fonts/${font.filename}.typeface.json`, resolve, undefined, reject))
}

export interface DockdoorDragEvent {
  dragFloor: Object3D;
  dragWalls: Object3D[];
  dragWall?: Object3D;
  dockdoor: Object3D;
  previousPosition: Vector3;
}

export class WarehouseScene3D extends Scene3D {
  protected dockdoorDragStartSubject = new Subject<DockdoorDragEvent>();
  protected dockdoorDragSubject = new Subject<DockdoorDragEvent>();
  protected dockdoorDragEndSubject = new Subject<DockdoorDragEvent>();
  protected previousPosition: Vector3;

  private dockdoorDragEvent: DockdoorDragEvent = undefined;

  sceneSettings: WarehouseSceneSettings;
  onDockdoorDragStart = this.dockdoorDragStartSubject.asObservable();
  onDockdoorDrag = this.dockdoorDragSubject.asObservable();
  onDockdoorDragEnd = this.dockdoorDragEndSubject.asObservable();

  protected constructor(ngZone: NgZone) {
    super(ngZone);
  }

  public static async create(model: string, canvas: ElementRef<HTMLCanvasElement>, ngZone: NgZone): Promise<WarehouseScene3D> {
    const scene = new WarehouseScene3D(ngZone);
    await Scene3D.init(scene, model, canvas, ngZone);
    scene.drag.addEventListener('drag', scene.onDrag.bind(scene));
    return scene;
  }

  onDragStart(event: any) {
    if (event.object && event.object instanceof THREE.Object3D && event.object.position) {
      switch (event.object.userData.type) {
        case 'zone':
          break;

        case 'location':
          break;

        case 'dockdoor':
          this.dockdoorDragEvent = {
            dragFloor: this.floor,
            dragWalls: this.walls,
            dockdoor: event.object,
            previousPosition: event.object.position.clone()
          };

          this.previousPosition = event.object.position.clone();
          this.dockdoorDragStartSubject.next(this.dockdoorDragEvent);
          break;
      }
    }
  }

  onDrag(event: any) {
    if (event.object && event.object instanceof THREE.Object3D && event.object.position) {
      switch (event.object.userData.type) {
        case 'zone':
          break;

        case 'location':
          break;

        case 'dockdoor':
          this.dockdoorDragEvent.dockdoor = event.object;
          this.dockdoorDragEvent.previousPosition = this.previousPosition.clone();
          this.previousPosition = event.object.position.clone();
          this.dockdoorDragSubject.next(this.dockdoorDragEvent);
          break;
      }
    }
  }

  onDragEnd() {
    if (this.dockdoorDragEvent) {
      this.dockdoorDragEndSubject.next(this.dockdoorDragEvent);
      this.changeSubject.next(true);
      this.dockdoorDragEvent = undefined;
      this.previousPosition = undefined;
    }
  }

  get zoneSettings(): IZoneSettings { return this.sceneSettings.zones; }
  get locationSettings(): ILocationSettings { return this.sceneSettings.locations; }
  get dockdoorSettings(): IDockDoorSettings { return this.sceneSettings.dockdoors; }

  protected override loadSettings = () => this.sceneSettings = new WarehouseSceneSettings(this.getAllObjectsByTypes(['scene'])[0].userData.settings);

  get floor(): Object3D {
    const floors = this.getObjectsByType(undefined, 'floor');

    if (floors.length !== 1) {
      throw new Error(`Invalid model: Model contains ${floors.length > 1 ? 'more than one' : 'no'} floor object.`);
    }

    return floors[0];
  }

  get walls(): Object3D[] {
    const walls = this.getObjectsByType(undefined, 'wall');
    walls.sort((a, b) => a.userData.index - b.userData.index);
    return walls;
  }

  async labelObject(obj: THREE.Object3D, transform: THREE.Vector3) {
    await this.createTextForObject(this.sceneSettings.fontId, obj, obj.userData.properties.Name, { transform });
  }

  private calcSlideAxisBounds(wall: Object3D): number {
    return (wall.userData.facing === 'z' ?
      wall.position.x + (wall.scale.x / 2) :
      wall.position.z + (wall.scale.z / 2)) * wall.userData.external;
  }

  calcDoorGap(wall: Object3D): number {
    return (this.dockdoorSettings.width +
      this.dockdoorSettings.distanceBetween) * wall.userData.external;
  }

  clampToWall(floor: Object3D, wall: Object3D, previousTransform?: Vector3): Vector3 {

    if (wall?.userData?.type !== 'wall' || !wall?.userData?.facing || isNaN(wall?.userData?.external)) {
      throw new Error(`Invalid wall object ${wall.name}`);
    }

    const slideAxisBounds = this.calcSlideAxisBounds(wall);
    const doorGap = this.calcDoorGap(wall);

    const adjustedSlide =
      previousTransform ?
        wall.userData.facing === 'z' ?
          previousTransform.x + doorGap :
          previousTransform.z + doorGap :
        undefined;

    const slideAxis = (adjustedSlide !== undefined ? adjustedSlide :
      this.calcSlideAxis(
        wall.userData.facing === 'z' ? wall.position.x : wall.position.z,
        wall.userData.facing === 'z' ? wall.scale.x : wall.scale.z,
        wall, floor));

    const result: Vector3 = new THREE.Vector3(
      wall.userData.facing === 'z' ? slideAxis : wall.position.x,
      ((wall.position.y + (
        this.dockdoorSettings.groundClearance +
        (this.dockdoorSettings.height / 2))) - (wall.scale.y / 2)) * floor.userData.external,
      wall.userData.facing === 'x' ? slideAxis : wall.position.z
    );

    return (slideAxisBounds < 0 && slideAxis < slideAxisBounds) ||
      (slideAxisBounds >= 0 && slideAxis > slideAxisBounds) ? undefined : result;
  }

  calcSlideAxis(position: number, scale: number, floor: Object3D, wall: Object3D): number {
    return (position + (this.dockdoorSettings.distanceBetween *
      floor.userData.external)) - (scale / 2 * floor.userData.external);
  }

  prefabDockdoor(vector: Vector3, wallProperties: any, dataItem: any): Object3D {
    const prefab = new THREE.Mesh(
      new THREE.BoxGeometry(
        this.dockdoorSettings.width,
        this.dockdoorSettings.height,
        this.dockdoorSettings.depth
      ),
      new THREE.MeshBasicMaterial({
        color: this.dockdoorSettings.creationColor,
        opacity: 0.35,
        transparent: true
      })
    );

    prefab.userData.type = 'dockdoor';
    prefab.userData.properties = dataItem;

    if (vector) { prefab.position.set(vector.x, vector.y, vector.z); }

    if (wallProperties.external === -1) {
      prefab.rotateY(VisualizationService.toRadians(wallProperties.facing === 'x' ? 90 : 180));
    } else {
      prefab.rotateY(VisualizationService.toRadians(wallProperties.facing === 'x' ? 270 : 0));

    }

    return prefab;
  }

  public calcDockdoorTextPosition(obj: Object3D): Vector3 {
    return new THREE.Vector3(-(this.dockdoorSettings.width / 4), 0, this.dockdoorSettings.depth / 2);
  }
}

export interface Font3D {
  id: number;
  name: string;
  filename: string;
  font?: Font;
}

export abstract class VisualizationService {
  public static fonts: Font3D[] = [
    { id: 1, name: 'Helvetiker Regular', filename: 'helvetiker_regular' },
    { id: 2, name: 'Helvetiker Bold', filename: 'helvetiker_bold' },
    { id: 3, name: 'Optimer Regular', filename: 'optimer_regular' },
    { id: 4, name: 'Optimer Bold', filename: 'optimer_bold' },
    { id: 5, name: 'Gentilis Regular', filename: 'gentilis_regular' },
    { id: 6, name: 'Gentilis Bold', filename: 'gentilis_bold' },
    { id: 7, name: 'Droid Sans Regular', filename: 'droid_sans_regular' },
    { id: 8, name: 'Droid Sans Bold', filename: 'droid_sans_bold' },
    { id: 9, name: 'Droid Serif Regular', filename: 'droid_serif_regular' },
    { id: 10, name: 'Droid Serif Bold', filename: 'droid_serif_bold' },
    { id: 11, name: 'Roboto Regular', filename: 'roboto_regular' },
    { id: 12, name: 'Roboto Bold', filename: 'roboto_bold' },
    { id: 13, name: 'Oswald Regular', filename: 'oswald_regular' },
    { id: 14, name: 'Oswald Bold', filename: 'oswald_bold' },
    { id: 15, name: 'Yatra One Regular', filename: 'yatra_one_regular' },
    { id: 16, name: 'Reggae One Regular', filename: 'reggae_one_regular' }
  ];

  constructor(protected http: HttpClient, protected ngZone: NgZone) { }

  static round = (v: number, p: number = 1): number =>
    isNaN(v) ? 0 : p === -1 ? v : Math.round((v + Number.EPSILON) * Math.pow(10, p)) / Math.pow(10, p)

  static ftToM = (v: number): number => isNaN(v) ? 0 : v / 3.281;
  static cmToM = (v: number): number => isNaN(v) ? 0 : v / 100.0;
  static inToM = (v: number): number => isNaN(v) ? 0 : v / 39.37;

  static mToFt = (v: number, p: number = 1): number => isNaN(v) ? 0 : VisualizationService.round(v * 3.281, p);
  static mToCm = (v: number, p: number = 1): number => isNaN(v) ? 0 : VisualizationService.round(v * 100.0, p);
  static mToIn = (v: number, p: number = 1): number => isNaN(v) ? 0 : VisualizationService.round(v * 39.37, p);

  static toRadians = (d: number): number => isNaN(d) ? 0 : d * Math.PI / 180.0;

  static createVector3 = (x?: number, y?: number, z?: number) => new THREE.Vector3(x, y, z);

  static createPlaneFromObject = (width: number, height: number, object: Object3D) => {
    const mesh = new THREE.Mesh(new THREE.BoxGeometry(width, height, 0));
    mesh.position.set(object.position.x, object.position.y, object.position.z);
    mesh.rotation.set(object.rotation.x, object.rotation.y, object.rotation.z);
    mesh.scale.set(object.scale.x, object.scale.y, object.scale.z);
    return mesh;
  }

  public abstract createScene3D(model: string, canvas: ElementRef<HTMLCanvasElement>): Promise<Scene3D>;

  importModel = (model: string) =>
    firstValueFrom(this.http.get<any>(`./assets/visualization/models/${model}.gltf`).pipe(t => t))

  transformIntersectsObjects(intersectVector: Vector3, objects: Object3D[]): boolean {
    const boxCollider = new THREE.Box3();

    for (const object of objects) {
      if (boxCollider.setFromObject(object, true).containsPoint(intersectVector)) { return true; }
    }

    return false;
  }

  getIntersectObject(source: Object3D, targets: Object3D[], skipTarget?: Object3D): Object3D {
    const sourceCollider = new THREE.Box3();
    const targetCollider = new THREE.Box3();

    sourceCollider.setFromObject(source, true);

    for (const target of targets) {
      if (skipTarget && target.id === skipTarget.id) { continue; }
      if (targetCollider.setFromObject(target, true).intersectsBox(sourceCollider)) { return target; }
    }

    return undefined;
  }

  parentContainsChild(parent: Object3D, child: Object3D) {
    const parentCollider = new THREE.Box3();
    const childCollider = new THREE.Box3();
    return parentCollider.setFromObject(parent, true).containsBox(childCollider.setFromObject(child, true));
  }

  updateModelSettings(model: GLTFModel, settings: IWarehouseSceneSettings): GLTFModel {
    const sceneItem = this.findSceneExtras(model);
    const settingsNode = new WarehouseSceneSettings(sceneItem.extras.settings);
    settingsNode.update(settings);
    sceneItem.extras.settings = settingsNode;
    return model;
  }

  findSceneExtras(model: GLTFModel): any {
    let scene = model.scenes.find((s: any) => s.extras && s.extras.type === 'scene');
    if (scene) { return scene; }

    scene = model.nodes.find((s: any) => s.extras && s.extras.type === 'scene');
    if (scene) { return scene; }

    throw new Error('Could not find scene within model.');
  }
}

@Injectable({
  providedIn: 'root'
})
export class WarehouseVisualizationService extends VisualizationService {

  constructor(
    protected override http: HttpClient,
    protected override ngZone: NgZone
  ) {
    super(http, ngZone);
  }

  public async createScene3D(model: string, canvas: ElementRef<HTMLCanvasElement>): Promise<WarehouseScene3D> {
    const scene = await WarehouseScene3D.create(model, canvas, this.ngZone);
    await this.labelDockdoors(scene);
    return scene;
  }

  private async labelDockdoors(scene: WarehouseScene3D) {
    const allObjects = scene.getAllObjectsByTypes(['dockdoor']);

    for (const dockdoor of allObjects) {
      await scene.labelObject(dockdoor, scene.calcDockdoorTextPosition(dockdoor));
    }
  }

  public setObjectMaterialByIdAndType(
    scene: WarehouseScene3D,
    id: number, type: string,
    r: number, g: number, b: number, opacity: number) {

    const obj = scene.getObjectByIdAndType(undefined, id, type);
    if (!obj) {
      console.log(`Cannot set material for invalid 3D Object: Id={id}, Type={type}`);
      return;
    }

    const mesh = scene.getMeshFromObject3D(obj);
    if (!mesh || !scene.isMesh(mesh)) {
      console.log(`Material can not be applied. Object does not contain a mesh: Id={id}, Type={type}`);
    }

    scene.setMeshMaterial(mesh, scene.createMaterial(scene.createColor(r, g, b), opacity));
  }
}
